import { useLazyQuery, useQuery } from "@apollo/client";
import * as QUERIES from "../queries.gql";

export const useGetTextbooks = (options) => {
  return useQuery(QUERIES.getTextbooksGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetTextBookById = (options) => {
  return useQuery(QUERIES.getTextBookByIdGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetSelectingsList = (options) => {
  return useQuery(QUERIES.getSelectingsListGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetNews = (options) => {
  return useQuery(QUERIES.getNewsGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetPopularNews = (options) => {
  return useQuery(QUERIES.getPopularNewsGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useCurrentUser = (options) => {
  return useQuery(QUERIES.currentUserGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetAllUniversity = (options) => {
  return useQuery(QUERIES.getAllUniversityGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetViewedUserBook = (options) => {
  return useQuery(QUERIES.getViewedUserBookGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetViewedUserBookLazy = (options) => {
  return useLazyQuery(QUERIES.getViewedUserBookGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetUserBooks = (options) => {
  return useQuery(QUERIES.getUserBooksGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetUserFavoritesLazy = (options) => {
  return useLazyQuery(QUERIES.getUserFavoritesGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetUserFavorites = (options) => {
  return useQuery(QUERIES.getUserFavoritesGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetSelection = (options) => {
  return useQuery(QUERIES.getSelectionGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetTextBookSimilar = (options) => {
  return useQuery(QUERIES.getTextBookSimilarGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetNewsById = (options) => {
  return useQuery(QUERIES.getNewsByIdGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetLastPublicateBook = (options) => {
  return useQuery(QUERIES.getLastPublicateBookGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetAllStaticVisiblePages = (options) => {
  return useQuery(QUERIES.getAllStaticVisiblePagesGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetAllStaticPages = (options) => {
  return useQuery(QUERIES.getAllStaticPagesGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};

export const useGetStaticPageByAlias = (options) => {
  return useQuery(QUERIES.getStaticPageByAliasGQL, {
    fetchPolicy: "network-only",
    ...options,
  });
};
