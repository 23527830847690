import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import AntDesignHomeFilled from "../../assets/icons/AntDesignHomeFilled";
import useStyles from "./styles";

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const NavigationMenu = ({ breadcrumbNameMap = {} }) => {
  const location = useLocation();
  const classes = useStyles();

  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" className={classes.navMenu}>
        <LinkRouter underline="hover" color="inherit" to="/">
          <AntDesignHomeFilled />
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;

          if (!breadcrumbNameMap[to]) return null;

          return last ? (
            <Typography color="text.primary" key={to}>
              {breadcrumbNameMap[to]}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};
export default NavigationMenu;
