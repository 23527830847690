import React from "react";

const Instagram = ({ color = "#C5D7E0", width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99987 6.87579C8.27955 6.87579 6.87565 8.2797 6.87565 10C6.87565 11.7203 8.27955 13.1242 9.99987 13.1242C11.7202 13.1242 13.1241 11.7203 13.1241 10C13.1241 8.2797 11.7202 6.87579 9.99987 6.87579ZM19.3702 10C19.3702 8.70626 19.3819 7.42423 19.3092 6.13283C19.2366 4.63283 18.8944 3.30158 17.7975 2.2047C16.6983 1.10548 15.3694 0.765638 13.8694 0.692982C12.5756 0.620326 11.2936 0.632045 10.0022 0.632045C8.70846 0.632045 7.42643 0.620326 6.13502 0.692982C4.63502 0.765638 3.30377 1.10783 2.2069 2.2047C1.10768 3.30392 0.767836 4.63283 0.69518 6.13283C0.622523 7.42658 0.634242 8.70861 0.634242 10C0.634242 11.2914 0.622523 12.5758 0.69518 13.8672C0.767836 15.3672 1.11002 16.6984 2.2069 17.7953C3.30612 18.8945 4.63502 19.2344 6.13502 19.307C7.42877 19.3797 8.7108 19.368 10.0022 19.368C11.296 19.368 12.578 19.3797 13.8694 19.307C15.3694 19.2344 16.7006 18.8922 17.7975 17.7953C18.8967 16.6961 19.2366 15.3672 19.3092 13.8672C19.3842 12.5758 19.3702 11.2938 19.3702 10ZM9.99987 14.807C7.33971 14.807 5.19284 12.6602 5.19284 10C5.19284 7.33986 7.33971 5.19298 9.99987 5.19298C12.66 5.19298 14.8069 7.33986 14.8069 10C14.8069 12.6602 12.66 14.807 9.99987 14.807ZM15.0038 6.11876C14.3827 6.11876 13.8811 5.6172 13.8811 4.99611C13.8811 4.37501 14.3827 3.87345 15.0038 3.87345C15.6249 3.87345 16.1264 4.37501 16.1264 4.99611C16.1266 5.14359 16.0977 5.28966 16.0413 5.42595C15.985 5.56224 15.9023 5.68607 15.798 5.79036C15.6937 5.89464 15.5699 5.97733 15.4336 6.03368C15.2973 6.09004 15.1513 6.11895 15.0038 6.11876Z"
        fill={color}
      />
    </svg>
  );
};

export default Instagram;
