import React from "react";

const Checkbox = ({ color = "#0B486B", width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="20"
        height="20"
        rx="2"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default Checkbox;
