import React from "react";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import ModalWindow from "../../common/modal-window";
import InputStandart from "../../common/input";
import PartaBtn from "../../common/button";
import { schemaRegistration } from "./schema";
import CheckboxStandart from "../../common/checkbox";
import * as style from "./style.module.css";
import { useSignUp } from "../../graphql";
import { UserLoginContext } from "../../contexts";

const RegistrationForm = ({ open, handleClose, handleClickOpenLogin }) => {
  const handleOpenSignIn = () => {
    handleClickOpenLogin();
    handleClose();
  };

  const [submitSignUp, { loading }] = useSignUp();

  return (
    <UserLoginContext.Consumer>
      {({ setLogIn }) => (
        <ModalWindow
          modalTitle="Регистрация"
          open={open}
          handleClose={handleClose}
          scrollbarHeight={452}
        >
          <Formik
            initialValues={{
              name: "",
              lastName: "",
              phone: "",
              email: "",
              password: "",
              confirmPassword: "",
              agree: false,
            }}
            onSubmit={(values, actions) => {
              submitSignUp({
                variables: {
                  input: {
                    fullName: `${values.name} ${values.lastName}`,
                    phone: values.phone,
                    email: values.email,
                    password: values.password,
                  },
                },
              })
                .then((response) => {
                  if (response && response.data && response.data.signUp) {
                    setLogIn(true);
                    sessionStorage.setItem(
                      "token_catalog",
                      response.data.signUp.token
                    );
                    sessionStorage.setItem(
                      "fullName",
                      response.data.signUp.user.fullName
                    );
                  }
                  handleClose();
                })
                .catch((e) => {
                  actions.setErrors({
                    email: e.message,
                  });
                });
            }}
            validationSchema={schemaRegistration}
          >
            {({ values }) => (
              <Form>
                <div className={style.fullNameWrapper}>
                  <Field name="name">
                    {({ field, meta }) => {
                      return (
                        <InputStandart
                          inputType="text"
                          placeholder="Имя"
                          touched={meta.touched}
                          error={meta.error}
                          {...field}
                        />
                      );
                    }}
                  </Field>
                  <Field name="lastName">
                    {({ field, meta }) => {
                      return (
                        <InputStandart
                          inputType="text"
                          placeholder="Фамилия"
                          touched={meta.touched}
                          error={meta.error}
                          {...field}
                        />
                      );
                    }}
                  </Field>
                </div>
                <Field name="phone">
                  {({ field, meta }) => {
                    return (
                      <InputStandart
                        inputType="tel"
                        placeholder="Номер телефона"
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <Field name="email">
                  {({ field, meta }) => {
                    return (
                      <InputStandart
                        inputType="email"
                        placeholder="Email адрес"
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <hr className={style.formHr} />
                <Field name="password">
                  {({ field, meta }) => {
                    return (
                      <InputStandart
                        inputType="password"
                        placeholder="Пароль"
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <Field name="confirmPassword">
                  {({ field, meta }) => {
                    return (
                      <InputStandart
                        inputType="password"
                        placeholder="Подтвердите пароль"
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <div className={style.checkboxContainer}>
                  <Field name="agree">
                    {({ field }) => {
                      return (
                        <CheckboxStandart
                          label={
                            <div className={style.agreeDescription}>
                              Я принимаю{" "}
                              <Link
                                to="/rules"
                                onClick={() => {
                                  handleClose();
                                }}
                              >
                                Правила и Условия
                              </Link>{" "}
                              использования сайта
                            </div>
                          }
                          {...field}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className={style.alignCenter}>
                  <PartaBtn
                    variant="contained"
                    buttonText="Зарегистрироваться"
                    type="submit"
                    fullWidth={false}
                    disabled={!values.agree || loading}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <div className={style.alignCenter}>
            <span className={style.tip}>Уже есть аккаунт?</span>
          </div>
          <div className={style.alignCenter}>
            <div
              className={style.link}
              onClick={handleOpenSignIn}
              role="button"
              tabIndex="0"
            >
              Войти
            </div>
          </div>
        </ModalWindow>
      )}
    </UserLoginContext.Consumer>
  );
};

export default RegistrationForm;
