import React from "react";

const SiGlyphCircleLoadRight = ({
  color = "#0B486B",
  width = 44,
  height = 44,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0564504 10.4174C0.162333 4.59812 4.78869 -0.0465818 10.368 0.0593006C15.8979 0.162359 20.0442 4.19436 20.0103 9.94589H22.1915C22.3387 10.0967 22.4211 10.2991 22.4211 10.5099C22.4211 10.7206 22.3387 10.9231 22.1915 11.0739L19.5854 13.92C19.5135 13.9943 19.4274 14.0534 19.3322 14.0938C19.237 14.1342 19.1347 14.155 19.0313 14.155C18.9279 14.155 18.8255 14.1342 18.7303 14.0938C18.6351 14.0534 18.549 13.9943 18.4772 13.92L16.0419 11.0739C15.894 10.9234 15.8111 10.7209 15.8111 10.5099C15.8111 10.2989 15.894 10.0964 16.0419 9.94589H18.0494C18.0819 5.23342 14.8602 2.06259 10.3313 1.97789C5.76704 1.89036 1.98492 5.69083 1.89598 10.4513C1.80845 15.1412 5.34774 19.0306 9.82022 19.2268V21.1482C4.31857 20.9534 -0.0494319 16.1788 0.0564504 10.4174Z"
        fill={color}
      />
    </svg>
  );
};

export default SiGlyphCircleLoadRight;
