import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import useBreakpoint from "use-breakpoint";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import BxBxsChevronDown from "../../assets/icons/BxBxsChevronDown";
import SiGlyphCircleLoadRight from "../../assets/icons/SiGlyphCircleLoadRight";
import { BREAKPOINTS } from "../../constants";

const CustomPagination = ({
  itemsPerPage,
  setOffset,
  offset = 0,
  setLimit,
  customLimitsList = null,
}) => {
  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isDesktop = ["tablet", "desktop"].includes(breakpoint);
  const defaultLimitsList = [15, 30, 60];
  const [limitVal, setLimitVal] = useState(
    customLimitsList
      ? Math.min(...customLimitsList)
      : Math.min(...defaultLimitsList)
  );

  useEffect(() => {
    setLimit(limitVal);
  }, [limitVal]);

  const handleChange = (event, value) => {
    setOffset((value - 1) * limitVal);
  };

  const handleChangeLimit = (event) => {
    setLimitVal(event.target.value);
  };

  const handleMobilePagination = () => {
    setOffset(0);
    setLimit(limitVal);
    setLimitVal(
      limitVal + customLimitsList
        ? Math.min(...customLimitsList)
        : Math.min(...defaultLimitsList)
    );
  };

  const findPage = (offsetList, limit) => {
    const page = Math.ceil(offsetList / limit);
    if (page === 1 && offsetList === 1) return page;
    return page + 1;
  };

  return (
    limitVal < itemsPerPage && (
      <Box className={classes.paginationBox}>
        {isDesktop ? (
          <Box className={classes.pagination}>
            <Stack>
              <Pagination
                count={Math.ceil(itemsPerPage / limitVal)}
                page={findPage(offset, limitVal)}
                onChange={handleChange}
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </Stack>
            <Box className={classes.paginationLimitBox}>
              <Box className={classes.paginationLimitTitle}>
                Книг на странице:
              </Box>
              <Box className={classes.paginationLimit}>
                <FormControl fullWidth>
                  <Select
                    value={limitVal}
                    IconComponent={BxBxsChevronDown}
                    onChange={handleChangeLimit}
                  >
                    {customLimitsList
                      ? customLimitsList.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })
                      : defaultLimitsList.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={classes.paginationMobileBox}>
            <Typography className={classes.paginationMobileText}>
              Загрузить ещё
            </Typography>
            <IconButton
              className={classes.paginationMobileBtn}
              aria-label="close"
              onClick={handleMobilePagination}
            >
              <SiGlyphCircleLoadRight />
            </IconButton>
          </Box>
        )}
      </Box>
    )
  );
};

export default CustomPagination;
