import React from "react";

const IcomoonFreeBooks = ({ color = "#ffffff", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_32:11024)">
        <path
          d="M6.01501 3.94H2.26501C1.92126 3.94 1.64001 4.24 1.64001 4.60667V19.2733C1.64001 19.64 1.92126 19.94 2.26501 19.94H6.01501C6.35876 19.94 6.64001 19.64 6.64001 19.2733V4.60667C6.64001 4.24 6.35876 3.94 6.01501 3.94ZM5.39001 7.94H2.89001V6.60667H5.39001V7.94Z"
          fill={color}
        />
        <path
          d="M12.015 3.94H8.26501C7.92126 3.94 7.64001 4.24 7.64001 4.60667V19.2733C7.64001 19.64 7.92126 19.94 8.26501 19.94H12.015C12.3588 19.94 12.64 19.64 12.64 19.2733V4.60667C12.64 4.24 12.3588 3.94 12.015 3.94ZM11.39 7.94H8.89001V6.60667H11.39V7.94Z"
          fill={color}
        />
        <path
          d="M17.2958 5.13568L13.7343 6.30972C13.4079 6.41734 13.2229 6.7547 13.3234 7.0594L17.3921 19.5054C17.4926 19.8101 17.8419 19.9713 18.1683 19.8637L21.7298 18.6897C22.0563 18.5821 22.2412 18.2447 22.1407 17.94L18.072 5.49401C17.9716 5.1893 17.6223 5.02805 17.2958 5.13568ZM17.798 8.65539L15.4237 9.43809L15.0584 8.33008L17.4327 7.54738L17.798 8.65539Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_32:11024">
          <rect
            width="20.7141"
            height="16.1194"
            fill={color}
            transform="translate(1.64001 3.94)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcomoonFreeBooks;
