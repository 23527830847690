import React, { useMemo, useState } from "react";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import useStyles from "./styles";

import FaSolidEye from "../../assets/icons/FaSolidEye";

const InputStandart = ({
  inputType,
  placeholder,
  handleIconClick,
  inputIcon,
  inputStyle,
  name,
  value,
  onChange,
  onBlur,
  touched,
  error,
}) => {
  const classes = useStyles();
  const [type, setType] = useState(inputType);
  const handleTogglePassword = () => {
    setType((prev) => {
      return prev === "text" ? "password" : "text";
    });
  };

  const inputEndAdornment = useMemo(() => {
    if (inputIcon) {
      return <IconButton onClick={handleIconClick}>{inputIcon}</IconButton>;
    }
    if (inputType === "password") {
      return (
        <IconButton onClick={handleTogglePassword}>
          <FaSolidEye />
        </IconButton>
      );
    }
    return null;
  }, [inputIcon, inputType, handleIconClick, handleTogglePassword]);

  return (
    <div className={clsx(classes.inputWrapper, "inputWrapperWidth")}>
      <Input
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={Boolean(touched && error)}
        endAdornment={inputEndAdornment}
        className={clsx(classes.inputBase, inputStyle)}
      />
      {touched && error && <div className={classes.error}>{error}</div>}
    </div>
  );
};

export default InputStandart;
