import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import ModalWindow from "../../common/modal-window";
import InputStandart from "../../common/input";
import PartaBtn from "../../common/button";
import { schemaRecoverPassword } from "./schema";
import * as style from "./style.module.css";
import CibMinutemailer from "../../assets/icons/CibMinutemailer";
import { useResetPassword } from "../../graphql";

const RecoverPassword = ({ open, handleClose, handleClickOpenLogin }) => {
  const [sendEmail, setSendEmail] = useState(false);

  const handleSendEmail = () => {
    setSendEmail(true);
  };

  const handleOpenSignIn = () => {
    handleClickOpenLogin();
    handleClose();
  };

  const [submitResetPassword, { loading }] = useResetPassword();

  return (
    <ModalWindow
      modalTitle="Восстановить пароль"
      open={open}
      handleClose={handleClose}
    >
      {!sendEmail ? (
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values, actions) => {
            submitResetPassword({
              variables: {
                email: values.email,
              },
            })
              .then(() => {
                handleSendEmail();
              })
              .catch((e) => {
                actions.setErrors({
                  email: e.message,
                });
              });
          }}
          validationSchema={schemaRecoverPassword}
        >
          <Form>
            <Field name="email">
              {({ field, meta }) => {
                return (
                  <InputStandart
                    inputType="email"
                    placeholder="Email адрес"
                    touched={meta.touched}
                    error={meta.error}
                    {...field}
                  />
                );
              }}
            </Field>
            <div className={style.tip}>
              Введите ваш email адрес и мы пришлём письмо с инструкциями по
              сбросу пароля
            </div>
            <div className={style.btnWrapper}>
              <PartaBtn
                variant="outlined"
                buttonText="Назад"
                type="button"
                fullWidth={false}
                onClick={handleOpenSignIn}
              />
              <PartaBtn
                variant="contained"
                buttonText="Восстановить пароль"
                type="submit"
                fullWidth={false}
                disabled={loading}
              />
            </div>
          </Form>
        </Formik>
      ) : (
        <>
          <div className={style.minutemailWrapper}>
            <CibMinutemailer />
            <div className={style.description}>
              Ссылка уже в письме на почте. Перейдите по ней и укажите новый
              пароль
            </div>
          </div>
          <div className={style.alignCenter}>
            <PartaBtn
              variant="outlined"
              buttonText="Закрыть"
              type="button"
              fullWidth={false}
              onClick={handleClose}
            />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

export default RecoverPassword;
