import React from "react";

const PaymentsSberIcon = ({ width = 25, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9639 4.66391C22.6921 4.29253 22.4234 3.92119 22.1864 3.54688L7.62274 11.8983L0.590586 7.87463C0.4294 8.3088 0.33141 8.76369 0.299805 9.22558L7.6891 13.4978L22.9639 4.66391Z"
        fill="#2F9A41"
      />
      <path
        d="M7.62322 15.0419L0.0695654 10.7142C0.0158365 10.9804 -0.00943528 11.2524 0.0032068 11.5242C0.0032068 14.833 1.32113 18.0063 3.66307 20.346C6.00818 22.6856 9.18769 24.0001 12.4999 24.0001C15.8153 24.0001 18.9948 22.6856 21.3367 20.346C23.6818 18.0063 24.9997 14.833 24.9997 11.5242C25.0124 9.55004 24.5478 7.60179 23.6439 5.84546L7.62322 15.0419Z"
        fill="#2F9A41"
      />
      <path
        d="M21.1696 2.50018C20.8157 2.16481 20.4396 1.84855 20.0508 1.55273L7.62365 8.75493L1.62816 5.33953C1.42588 5.71088 1.2236 6.11734 0.983398 6.55598L7.55413 10.3427L21.1696 2.50018Z"
        fill="#2F9A41"
      />
      <path
        d="M18.8338 0.742721C18.3628 0.461609 17.873 0.213436 17.3673 0L7.62341 5.60267L3.25559 3.14346C2.96166 3.43587 2.66772 3.81895 2.37695 4.19029L7.58865 7.16708L18.8338 0.742721Z"
        fill="#2F9A41"
      />
    </svg>
  );
};

export default PaymentsSberIcon;
