import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import useBreakpoint from "use-breakpoint";
import MetaTags from "react-meta-tags";
import * as style from "./style.module.css";
import CustomPagination from "../../UI/Pagination/Pagination";
import CiFilter from "../../assets/icons/CiFilter";
import Filters from "../../components/filters";
import AllBooks from "../../UI/AllBooks/AllBooks";
import PartaBtn from "../../common/button";
import { BREAKPOINTS } from "../../constants";
import useStyles from "./styles";
import NavigationMenu from "../../components/nav-menu";
import ModalFullscreen from "../../common/modal-fullscreen";
import BxBxListUl from "../../assets/icons/BxBxListUl";
import { useGetTextbooks } from "../../graphql";
import SortSelect from "../../UI/SortSelect/SortSelect";
import CatalogSidebar from "../../UI/CatalogSidebar/CatalogSidebar";

const CatalogPage = () => {
  const [offset, setOffset] = useState(null);
  const [limit, setLimit] = useState(null);
  const [countProducs, setCountProducts] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const { data, refetch } = useGetTextbooks({
    variables: {
      options: {
        limit,
        offset,
        sortBy,
        sortType,
      },
    },
  });

  useEffect(() => {
    if (data && data.getTextbooks) {
      setCountProducts(data.getTextbooks.count);
      setProductsList(data.getTextbooks.textbooks);
    }
  }, [data && data.getTextbooks]);

  const [showFilters, setShowFilters] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isDesktop = ["tablet", "desktop"].includes(breakpoint);

  const breadcrumbNameMap = {
    "/all-books": "Все книги",
  };

  return (
    <Box className={style.catalog}>
      <MetaTags>
        <title>Все книги</title>
        <meta property="og:title" content="Все книги" />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <Box className={style.catalogHead}>
        <Box className="page-title">Все книги</Box>
        {!!productsList.length && (
          <SortSelect setSortBy={setSortBy} setSortType={setSortType} />
        )}
      </Box>
      <Box className={style.catalogWrap}>
        {isDesktop && <CatalogSidebar />}
        <Box className={style.catalogBooks}>
          {!isDesktop ? (
            <Box className={classes.btnWrapper}>
              <PartaBtn
                variant="outlined"
                buttonText="Фильтры"
                type="button"
                fullWidth
                startIcon={<CiFilter width="16" height="16" color="#0B486B" />}
                onClick={handleShowFilters}
              />
              <PartaBtn
                variant="contained"
                buttonText="Предметы"
                type="button"
                fullWidth
                startIcon={
                  <BxBxListUl width="16" height="16" color="#ffffff" />
                }
                onClick={handleShowSidebar}
              />
              <ModalFullscreen
                open={showFilters}
                handleClose={handleShowFilters}
              >
                <Filters refetch={refetch} />
              </ModalFullscreen>
              <ModalFullscreen
                open={showSidebar}
                handleClose={handleShowSidebar}
              >
                <CatalogSidebar />
              </ModalFullscreen>
            </Box>
          ) : (
            <Filters refetch={refetch} />
          )}
          <AllBooks className={classes.allBooksGap} allBooks={productsList} />
        </Box>
      </Box>
      <CustomPagination
        itemsPerPage={countProducs}
        setOffset={setOffset}
        setLimit={setLimit}
        offset={offset}
        customLimitsList={[12, 24, 48]}
      />
    </Box>
  );
};

export default CatalogPage;
