import { gql } from "@apollo/client";

export const signUpGQL = gql`
  mutation ($input: userInput) {
    signUp(input: $input) {
      token
      user {
        id
        email
        fullName
      }
    }
  }
`;

export const resetPasswordGQL = gql`
  mutation ($email: String!) {
    resetPassword(email: $email) {
      status
    }
  }
`;

export const newPasswordGQL = gql`
  mutation ($passwordResetToken: String!, $password: String!) {
    setNewPassword(
      passwordResetToken: $passwordResetToken
      password: $password
    ) {
      status
    }
  }
`;

export const userBuyBookGQL = gql`
  mutation ($userInfo: userInputAutoregister, $textBookId: Int!) {
    userBuyBook(userInfo: $userInfo, textBookId: $textBookId) {
      link
    }
  }
`;

export const editInfoUserGQL = gql`
  mutation ($input: userEditInfo) {
    editInfoUser(input: $input) {
      status
    }
  }
`;

export const changePasswordGQL = gql`
  mutation ($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      status
    }
  }
`;

export const addToFavoritedGQL = gql`
  mutation ($textBookId: Int!) {
    addToFavorite(textBookId: $textBookId) {
      status
    }
  }
`;

export const deleteFavoriteGQL = gql`
  mutation ($textBookId: Int!) {
    deleteFavorite(textBookId: $textBookId) {
      status
    }
  }
`;
