import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import * as style from "./style.module.css";
import NavigationMenu from "../../components/nav-menu";
import AllBooks from "../../UI/AllBooks/AllBooks";
import useStyles from "./styles";
import PreLoader from "../../UI/PreLoader/PreLoader";
import { useGetTextBookSimilar } from "../../graphql";

const SimilarPage = () => {
  const classes = useStyles();

  const params = useParams();

  const { data: dataSimilar, loading: loadingSimilar } = useGetTextBookSimilar({
    variables: {
      textbookId: params.book,
    },
  });

  const breadcrumbNameMap = {
    [`/similar/${params.book}`]: "Похожие книги",
  };

  if (loadingSimilar) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <Box className={style.page}>
      <MetaTags>
        <title>Похожие книги</title>
        <meta property="og:title" content="Похожие книги" />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <Box className={style.pageHead}>
        <Box className="page-title">Похожие книги</Box>
      </Box>
      <Box className={style.pageWrap}>
        <AllBooks
          className={classes.allBooksGap}
          allBooks={dataSimilar?.getTextBookSimilar}
        />
      </Box>
    </Box>
  );
};

export default SimilarPage;
