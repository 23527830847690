import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  listContainer: {
    "&.MuiList-root": {
      display: "flex",
      alignItems: "center",
      padding: 0,
      "@media (max-width: 1500px)": {
        flexDirection: "column",
      },
    },
    "& .MuiListItem-root": {
      width: "unset",
      padding: 0,
      marginRight: 40,
      maxHeight: 24,
      "@media (max-width: 1500px)": {
        marginRight: 0,
        marginBottom: 24,
      },
    },
    "& .MuiListItem-root:last-child": {
      marginRight: 0,
      "@media (max-width: 1500px)": {
        marginBottom: 0,
      },
    },
  },
  listItemButton: {
    "&.MuiButtonBase-root.MuiListItemButton-root": {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
}));

export default useStyles;
