import React from "react";

const BxBxsChevronRight = ({
  color = "#0B486B",
  width = 24,
  height = 24,
  arrowRightStyle,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={arrowRightStyle}
    >
      <path
        d="M10.061 19.061L17.121 12L10.061 4.939L7.93903 7.061L12.879 12L7.93903 16.939L10.061 19.061Z"
        fill={color}
      />
    </svg>
  );
};

export default BxBxsChevronRight;
