import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MetaTags from "react-meta-tags";
import * as style from "./style.module.css";
import NavigationMenu from "../../components/nav-menu";
import AllBooks from "../../UI/AllBooks/AllBooks";
import useStyles from "./styles";
import PreLoader from "../../UI/PreLoader/PreLoader";
import { useGetViewedUserBook } from "../../graphql";

const ViewedPage = () => {
  const classes = useStyles();
  const [productsList, setProductsList] = useState([]);
  const { data, loading } = useGetViewedUserBook();

  const breadcrumbNameMap = {
    "/viewed": "Просмотренные книги",
  };

  useEffect(() => {
    if (data && data.getViewedUserBook) {
      setProductsList(data.getViewedUserBook);
    }
  }, [data && data.getViewedUserBook]);

  if (loading) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <Box className={style.page}>
      <MetaTags>
        <title>Просмотренные книги</title>
        <meta property="og:title" content="Просмотренные книги" />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <Box className={style.pageHead}>
        <Box className="page-title">Просмотренные книги</Box>
      </Box>
      <Box className={style.pageWrap}>
        <AllBooks className={classes.allBooksGap} allBooks={productsList} />
      </Box>
    </Box>
  );
};

export default ViewedPage;
