import React, { useEffect, useState } from "react";
import useBreakpoint from "use-breakpoint";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import NavigationMenu from "../../components/nav-menu";
import { BREAKPOINTS } from "../../constants";
import useStyles from "./styles";
import PartaBtn from "../../common/button";
import FluentNews28Filled from "../../assets/icons/FluentNews28Filled";
import ModalFullscreen from "../../common/modal-fullscreen";
import BxBxsChevronLeft from "../../assets/icons/BxBxsChevronLeft";
import AllArticles from "./AllArticles";
import Share from "../../UI/Share/Share";
import BxBxsShare from "../../assets/icons/BxBxsShare";
import ScrollSection from "../../UI/ScrollSection/ScrollSection";
import PreLoader from "../../UI/PreLoader/PreLoader";
import { useGetNewsById, useGetSelection } from "../../graphql";
import { dateBuildFormatter } from "../../utils/dateBuildFormatter";

const InformationPage = () => {
  const params = useParams();
  const [showArticles, setArticles] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const handleShare = () => {
    setOpenShare(!openShare);
  };

  const handleArticles = () => {
    setArticles(!showArticles);
  };

  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isMobile = ["smallMobile", "mobile"].includes(breakpoint);

  const [newsData, setNewsData] = useState(null);
  const { data, loading } = useGetNewsById({
    variables: {
      id: Number(params.informationPage),
    },
  });

  useEffect(() => {
    if (data && data.getNewsById) {
      setNewsData(data.getNewsById);
    }
  }, [data && data.getNewsById]);

  const breadcrumbNameMap = {
    "/news": "Новости",
    [`/news/${params.informationPage}`]: "Информационная страничка",
  };

  const { data: dataSelections, loading: loadingSelections } = useGetSelection({
    variables: {
      page: "NEWS_MATERIAL_PAGE",
    },
  });

  if (loadingSelections || loading) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <div>
      <MetaTags>
        <title>{newsData?.title}</title>

        <meta property="og:title" content={newsData?.title} />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_URL}${newsData?.image}`}
        />
        <meta name="description" content={newsData?.metaDescription} />
        <meta name="keywords" content={newsData?.metaKeywords} />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      {newsData?.title && <div className="page-title">{newsData.title}</div>}
      <div className={classes.detailInfo}>
        {newsData?.createdAt && (
          <div className={classes.infoDate}>
            {dateBuildFormatter(newsData.createdAt)}
          </div>
        )}
        {isMobile ? (
          <div className={classes.shareContainer}>
            <PartaBtn
              variant="outlined"
              buttonText="Поделиться"
              type="button"
              fullWidth
              startIcon={<BxBxsShare width="16" height="16" color="#0B486B" />}
              onClick={handleShare}
            />
            {openShare && <Share title="Поделиться:" colorIcon="#0B486B" />}
          </div>
        ) : (
          <Share title="Поделиться:" colorIcon="#0B486B" />
        )}
      </div>
      <div className={classes.newsWrapper}>
        <div className={classes.infoPageContainer}>
          {newsData?.image && (
            <img
              src={newsData?.image}
              alt={newsData?.imgAlt}
              className={classes.infoImg}
            />
          )}
          {newsData?.content && newsData?.content}
        </div>
        {isMobile ? (
          <div className={classes.btnAllArticles}>
            <PartaBtn
              variant="contained"
              buttonText="Все статьи"
              type="button"
              fullWidth
              startIcon={
                <FluentNews28Filled width="16" height="16" color="#ffffff" />
              }
              onClick={handleArticles}
            />
            {showArticles && (
              <ModalFullscreen open={showArticles} handleClose={handleArticles}>
                <AllArticles />
                <div className={classes.returnBtn}>
                  <PartaBtn
                    variant="outlined"
                    buttonText="Вернуться"
                    type="button"
                    fullWidth
                    startIcon={<BxBxsChevronLeft width="16" height="16" />}
                    onClick={handleArticles}
                  />
                </div>
              </ModalFullscreen>
            )}
          </div>
        ) : (
          <div className={classes.articleSection}>
            <AllArticles />
          </div>
        )}
      </div>
      {dataSelections &&
        dataSelections.getSelection &&
        dataSelections.getSelection.map((section) => {
          return (
            !!section.textbooks.length && (
              <ScrollSection
                key={section.id}
                sectionTitle={section.title}
                productsList={section.textbooks}
                noLink
              />
            )
          );
        })}
    </div>
  );
};
export default InformationPage;
