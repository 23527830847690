import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

import Box from "@mui/material/Box";
import CloseIcon from "../../assets/icons/CloseIcon";
import useStyles from "./styles";

const ModalFullscreen = ({ children, open, handleClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog fullScreen open={open}>
        <Box className={classes.dialog}>
          {handleClose ? (
            <IconButton
              className={classes.closeBtn}
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon width="12" height="12" />
            </IconButton>
          ) : null}
          <DialogContent>{children}</DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default ModalFullscreen;
