import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  btnWrapper: {
    "& .MuiButtonBase-root.MuiButton-root": {
      marginBottom: 16,
    },
    "@media (max-width:1000px)": {
      display: "flex",
      justifyContent: "center",
      "& .MuiButtonBase-root.MuiButton-root": {
        width: "fit-content",
        "&.MuiButtonBase-root.MuiButton-root:not(:last-of-type)": {
          marginRight: 16,
        },
      },
    },
    "@media (max-width:440px)": {
      flexDirection: "column",
      "& .MuiButtonBase-root.MuiButton-root": {
        width: "100%",
        "&.MuiButtonBase-root.MuiButton-root:not(:last-of-type)": {
          marginRight: 0,
        },
      },
    },
  },
  allBooksGap: {
    gap: "64px 69px",
    marginTop: 64,
    "@media (max-width:639px)": {
      gap: "24px 0px",
      marginTop: 0,
    },
  },
  headFilter: {
    maxHeight: 32,
    "@media (max-width:640px)": {
      width: "100%",
      marginTop: 24,
    },
    "& .MuiSelect-nativeInput": {
      height: "100%",
    },
    "& .MuiSelect-select": {
      padding: "4.5px 14px",
      zIndex: 2,
      "@media (min-width:639px)": {
        padding: 0,
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0B486B",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
      border: "1px solid #C5D7E0",

      "@media (min-width:639px)": {
        border: "none",
      },
    },
    "& .MuiInputBase-root": {
      position: "relative",
      zIndex: 1,
    },
    "& .MuiInputBase-root svg": {
      position: "absolute",
      right: 16,
      zIndex: 0,
      "@media (min-width:639px)": {
        right: 0,
      },
    },
  },
}));

export default useStyles;
