import React, { useEffect, useState } from "react";
import useBreakpoint from "use-breakpoint";
import CiFilter from "../../assets/icons/CiFilter";
import PartaBtn from "../../common/button";
import ModalFullscreen from "../../common/modal-fullscreen";
import Filters from "../../components/filters";
import { BREAKPOINTS } from "../../constants";
import { useGetUserFavorites } from "../../graphql";
import AllBooks from "../../UI/AllBooks/AllBooks";
import CustomPagination from "../../UI/Pagination/Pagination";
import useStyles from "./styles";

const MyBookmarks = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [offset, setOffset] = useState(null);
  const [limit, setLimit] = useState(null);
  const [countProducs, setCountProducts] = useState(null);
  const [productsList, setProductsList] = useState([]);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const showSearchBelowHeader = ["smallMobile", "mobile"].includes(breakpoint);

  const { data, refetch } = useGetUserFavorites({
    variables: {
      options: {
        limit,
        offset,
      },
    },
  });

  useEffect(() => {
    if (data?.getUserFavorites) {
      setCountProducts(data.getUserFavorites.count);
      setProductsList(data.getUserFavorites.textbooks);
    }
  }, [data?.getUserFavorites]);

  return (
    <div>
      {showSearchBelowHeader ? (
        <div className={classes.btnWrapper}>
          <PartaBtn
            variant="outlined"
            buttonText="Фильтры"
            type="button"
            fullWidth
            startIcon={<CiFilter width="16" height="16" color="#0B486B" />}
            onClick={handleShowFilters}
          />
          {showFilters && (
            <ModalFullscreen open={showFilters} handleClose={handleShowFilters}>
              <Filters refetch={refetch} />
            </ModalFullscreen>
          )}
        </div>
      ) : (
        <Filters refetch={refetch} />
      )}
      <AllBooks className={classes.allBooksGap} allBooks={productsList} />
      <div className={classes.paginationWrapper}>
        <CustomPagination
          itemsPerPage={countProducs}
          setOffset={setOffset}
          setLimit={setLimit}
          offset={offset}
        />
      </div>
    </div>
  );
};
export default MyBookmarks;
