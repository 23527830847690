import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  marginCard: {
    marginRight: 24,
  },
  mb14: {
    marginBottom: 14,
  },
  w100: {
    width: "100%",
  },
  orderWrapper: {
    margin: "40px 0px 66px 0px",
    display: "flex",
    "@media (max-width: 999.9px)": {
      flexDirection: "column",
    },
    "@media (max-width: 360px)": {
      marginLeft: -16,
      marginRight: -16,
      margin: "24px 0px 48px 0px",
    },
  },
  orderContainer: {
    border: "1px solid #0000001A",
    padding: "16px 24px 40px 24px",
    width: "100%",
    marginBottom: 24,
    "@media (max-width: 360px)": {
      padding: "16px 16px 24px 16px",
      border: "none",
      borderBottom: "1px solid #0000001A",
      marginBottom: 0,
    },
  },
  orderTitle: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "23px",
    color: "#303030",
    paddingBottom: 16,
    width: "100%",
    borderBottom: "1px solid #0000001A",
    marginBottom: 22,
  },
  orderMaxWidth: {
    maxWidth: 382,
    width: "100%",
    height: "100%",
    marginLeft: 24,
    "@media (max-width: 999.9px)": {
      maxWidth: "unset",
      marginLeft: 0,
    },
    "@media (max-width: 360px)": {
      backgroundColor: "#FEFCF4",
    },
  },
  desc: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#00000066",
  },
  productName: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "19px",
    color: "#4D6E7D",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 30,
  },
  borderBottom: {
    borderBottom: "1px solid #0000001A",
    paddingBottom: 14,
  },
  price: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "19px",
    color: "#303030",
  },
  totalPrice: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "28px",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    marginBottom: 16,
  },
  userAgreement: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    color: "#5C5C5C",
    textAlign: "center",
    "& a": {
      textDecoration: "none",
      color: "#0B486B",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  helpBlock: {
    fontFamily: "Open Sans",
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "18px",
    color: "#303030;",
    letterSpacing: 0,
    marginBottom: 16,

    "& .MuiButtonBase-root.MuiButton-root": {
      padding: 0,
      textTransform: "capitalize",
      fontFamily: "Open Sans",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: "18px",
      color: "#0B486B;",
      letterSpacing: 0,
      textDecoration: "underline",
    },
  },
  orderInputWrapper: {
    display: "flex",
    "@media (max-width: 639.9px)": {
      flexDirection: "column",
    },
    "& > div": {
      marginBottom: 0,
      marginRight: 24,
      "@media (max-width: 639.9px)": {
        marginBottom: 24,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
    "& .MuiInputBase-root.MuiInput-root": {
      maxWidth: "unset",
    },
  },
  inputMaxWidth: {
    "&.MuiInputBase-root.MuiInput-root": {
      maxWidth: 618,
      minWidth: 618,
      "@media (max-width: 1440px)": {
        minWidth: 166,
      },
    },
  },
  paymentWrapper: {
    border: "1px solid #0000000D",
    padding: "16px 24px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 44,
    "@media (max-width: 1440px)": {
      flexDirection: "column",
    },
  },
  creditCardWrapper: {
    width: 64,
    minWidth: 64,
    height: 64,
    backgroundColor: "#F2F2F2",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 24,
  },
  dFlex: {
    display: "flex",
    "@media (max-width: 1440px)": {
      marginBottom: 24,
    },
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  paymentTitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: 0,
    color: "#4D6E7D",
    marginBottom: 7,
  },
  paymentDescription: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: 0,
    color: "#00000066",
    maxWidth: 450,
    width: "100%",
    "@media (max-width: 1440px)": {
      maxWidth: "unset",
    },
  },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 40,
    letterSpacing: "0.1em",
    textAlign: "center",
    minHeight: 550,
    color: "#22BB33",
    "@media (max-width: 440px)": {
      fontSize: 32,
    },
    "& svg": {
      width: 110,
      height: 110,
      marginBottom: 28,
      "@media (max-width: 440px)": {
        width: 70,
        height: 70,
      },
    },
  },
  payment: {
    color: "#0b486b",
    fontSize: 28,
    "@media (max-width: 440px)": {
      fontSize: 20,
    },
  },
}));

export default useStyles;
