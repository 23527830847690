import React from "react";
import Box from "@mui/material/Box";

import useBreakpoint from "use-breakpoint";
import * as style from "./style.module.css";
import ProductCard from "../ProductCard/ProductCard";
import ProductCardSecondary from "../ProductCardSecondary/ProductCardSecondary";
import { BREAKPOINTS } from "../../constants";

const ScrollSectionInner = ({ productsList }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isDesktop = ["tablet", "desktop"].includes(breakpoint);
  return (
    productsList &&
    productsList.map((product) => {
      if (isDesktop) {
        return (
          <Box key={product.id} className={style.smallCard}>
            <ProductCard product={product} cardWidth={222} cardHeight={346} />
          </Box>
        );
      }
      return (
        <Box key={product.id} className={style.smallCard}>
          <ProductCardSecondary
            product={product}
            heightImg={376}
            widthImg={247}
          />
        </Box>
      );
    })
  );
};

export default ScrollSectionInner;
