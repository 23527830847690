export const dateBuildFormatter = (timestamp) => {
  return new Intl.DateTimeFormat("ru-RU", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  })
    .format(timestamp)
    .slice(0, -2);
};
