import React from "react";

const Favorite = ({ color = "#000000", width = 14, height = 12 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1066 0.659948C10.3466 -0.540052 8.17331 0.0199478 6.99998 1.39328C5.82665 0.0199478 3.65331 -0.546719 1.89331 0.659948C0.959979 1.29995 0.373313 2.37995 0.333313 3.51995C0.239979 6.10661 2.53331 8.17995 6.03331 11.3599L6.09998 11.4199C6.60665 11.8799 7.38665 11.8799 7.89331 11.4133L7.96665 11.3466C11.4666 8.17328 13.7533 6.09995 13.6666 3.51328C13.6266 2.37995 13.04 1.29995 12.1066 0.659948ZM7.06665 10.3666L6.99998 10.4333L6.93331 10.3666C3.75998 7.49328 1.66665 5.59328 1.66665 3.66661C1.66665 2.33328 2.66665 1.33328 3.99998 1.33328C5.02665 1.33328 6.02665 1.99328 6.37998 2.90661H7.62665C7.97331 1.99328 8.97331 1.33328 9.99998 1.33328C11.3333 1.33328 12.3333 2.33328 12.3333 3.66661C12.3333 5.59328 10.24 7.49328 7.06665 10.3666Z"
        fill={color}
      />
    </svg>
  );
};

export default Favorite;
