import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  navMenu: {
    "& .MuiTypography-root.MuiLink-root": {
      display: "flex",
      margin: "42px 0px",
      "@media (max-width: 639.9px)": {
        margin: "32px 0px",
      },
    },
    "& .MuiBreadcrumbs-separator": {
      color: "#000000",
    },
    "& .MuiTypography-root": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "16px",
      color: "#303030",
      "@media (max-width: 360px)": {
        fontSize: 12,
      },
    },
  },
}));

export default useStyles;
