import clsx from "clsx";
import React from "react";
import useBreakpoint from "use-breakpoint";
import { Typography } from "@mui/material";
import ProductCardSecondary from "../ProductCardSecondary/ProductCardSecondary";
import * as style from "./style.module.css";
import { BREAKPOINTS } from "../../constants";

const AllBooks = ({ allBooks, className }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const showSearchBelowHeader = ["smallMobile", "mobile"].includes(breakpoint);
  if (!allBooks?.length) {
    return <Typography className={style.notFound}>Не найдено</Typography>;
  }
  return (
    <div className={clsx(style.allBooksWrapper, className)}>
      {allBooks.map((product) => {
        return (
          <div key={product?.textbook?.id || product.id}>
            <ProductCardSecondary
              product={product}
              widthImg={showSearchBelowHeader ? 328 : 247}
              heightImg={showSearchBelowHeader ? null : 376}
              maxWidthImg="100%"
            />
          </div>
        );
      })}
    </div>
  );
};

export default AllBooks;
