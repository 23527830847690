import React, { useEffect, useMemo, useState } from "react";
import { Formik, Field, Form } from "formik";
import useBreakpoint from "use-breakpoint";
import useStyles from "./styles";
import InputStandart from "../../common/input";
import PartaBtn from "../../common/button";
import CiFilter from "../../assets/icons/CiFilter";
import CiFilterOff from "../../assets/icons/CiFilterOff";
import FilterAutocomplete from "./FilterAutocomplete";
import { BREAKPOINTS } from "../../constants";
import { useGetSelectingsList } from "../../graphql";

const Filters = ({ refetch }) => {
  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isDesktop = ["tablet", "desktop"].includes(breakpoint);

  const { data } = useGetSelectingsList();

  const [years, setYears] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    if (data && data.selectingsList) {
      setYears(data.selectingsList.years);
      setSubjects(data.selectingsList.subjects);
    }
  }, [data && data.selectingsList]);

  const yearsList = useMemo(() => {
    return (
      years &&
      years.map((year) => {
        return { id: year, year };
      })
    );
  }, [years]);

  const subjectsList = useMemo(() => {
    return (
      subjects &&
      subjects.map((subject) => {
        return { id: subject.id, name: subject.name };
      })
    );
  }, [subjects]);

  const getSubjectOption = (option) => {
    return option.name;
  };

  const getYearOption = (option) => {
    return option.year;
  };

  const isResetDisabled = (values) => {
    return !values.searchString && !values.subject && !values.publishYear;
  };

  const handleSubmit = (values) => {
    refetch({
      options: {
        searchString: values.searchString,
        selectedSubjectId: (values.subject && values.subject.id) || null,
        selectedYear: (values.publishYear && values.publishYear.id) || null,
        offset: 0,
      },
    });
  };

  return (
    <div className={classes.filterContainer}>
      <Formik
        initialValues={{ searchString: "", subject: null, publishYear: null }}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form className={classes.form}>
              <div className={classes.topSection}>
                Фильтры
                {isDesktop && (
                  <div className={classes.btnWrapper}>
                    <PartaBtn
                      variant="outlined"
                      buttonText="Очистить"
                      type="reset"
                      fullWidth={false}
                      startIcon={
                        <CiFilterOff
                          width="16"
                          height="16"
                          color={
                            isResetDisabled(values) ? "#C5D7E0" : "#0B486B"
                          }
                        />
                      }
                      disabled={isResetDisabled(values)}
                    />
                    <PartaBtn
                      variant="contained"
                      buttonText="Применить"
                      type="submit"
                      fullWidth={false}
                      startIcon={
                        <CiFilter width="16" height="16" color="#ffffff" />
                      }
                    />
                  </div>
                )}
              </div>
              <div className={classes.bottomSection}>
                <Field name="searchString">
                  {({ field, meta }) => {
                    return (
                      <InputStandart
                        inputType="text"
                        placeholder="Название, автор"
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <Field name="subject">
                  {({ field, form }) => {
                    return (
                      <FilterAutocomplete
                        field={field}
                        form={form}
                        options={subjectsList}
                        getOptionLabel={getSubjectOption}
                        placeholder="Предмет"
                      />
                    );
                  }}
                </Field>
                <Field name="publishYear">
                  {({ field, form }) => {
                    return (
                      <FilterAutocomplete
                        field={field}
                        form={form}
                        options={yearsList}
                        getOptionLabel={getYearOption}
                        placeholder="Год выпуска"
                      />
                    );
                  }}
                </Field>
              </div>
              {!isDesktop && (
                <div className={classes.btnWrapper}>
                  <div className={classes.btnClear}>
                    <PartaBtn
                      variant="outlined"
                      buttonText="Очистить"
                      type="reset"
                      fullWidth={false}
                      startIcon={
                        <CiFilterOff
                          width="16"
                          height="16"
                          color={
                            isResetDisabled(values) ? "#C5D7E0" : "#0B486B"
                          }
                        />
                      }
                      disabled={isResetDisabled(values)}
                    />
                  </div>
                  <PartaBtn
                    variant="contained"
                    buttonText="Применить"
                    type="submit"
                    fullWidth={false}
                    startIcon={
                      <CiFilter width="16" height="16" color="#ffffff" />
                    }
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Filters;
