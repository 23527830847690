import React from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import * as style from "./style.module.css";

const PreLoader = ({ preloadWrapper, size }) => {
  return (
    <Box className={style.preloader} sx={preloadWrapper}>
      <CircularProgress
        className={style.spinner}
        disableShrink
        size={size || 50}
      />
    </Box>
  );
};

export default PreLoader;
