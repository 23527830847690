import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  allBooksGap: {
    gap: "80px 23px",
    "@media (max-width:639px)": {
      gap: "24px 0px",
      marginTop: 0,
    },
  },
}));

export default useStyles;
