import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cardImg: {
    width: (props) => (props.widthImg ? props.widthImg : "100%"),
    maxWidth: (props) => (props.maxWidthImg ? props.maxWidthImg : "auto"),
    height: (props) => (props.heightImg ? props.heightImg : "auto"),
    objectFit: "cover",
  },
}));

export default useStyles;
