import React from "react";

const Facebook = ({ color = "#C5D7E0", width = 10, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.39679 17.9967V9.80068H9.16179L9.57279 6.59168H6.39679V4.54768C6.39679 3.62168 6.65479 2.98768 7.98379 2.98768H9.66779V0.126681C8.84843 0.038872 8.02484 -0.0035263 7.20079 -0.000319115C4.75679 -0.000319115 3.07879 1.49168 3.07879 4.23068V6.58568H0.331787V9.79468H3.08479V17.9967H6.39679Z"
        fill={color}
      />
    </svg>
  );
};

export default Facebook;
