import React from "react";
import { Route, Routes } from "react-router-dom";
import Account from "../../pages/account/Account";
import BookPage from "../../pages/book/BookPage";
import CatalogPage from "../../pages/catalog/CatalogPage";
import Home from "../../pages/home/Home";
import InformationPage from "../../pages/news/InformationPage";
import News from "../../pages/news/News";
import ResultsPage from "../../pages/results/ResultsPage";
import Order from "../../pages/order/Order";
import NovetlyPage from "../../pages/novetly/NovetlyPage";
import ViewedPage from "../../pages/viewed/ViewedPage";
import SimilarPage from "../../pages/similar/SimilarPage";
import SubjectPage from "../../pages/subject/SubjectPage";
import OrderSuccess from "../../pages/order/OrderSuccess";
import { useGetAllStaticPages } from "../../graphql";
import StaticPage from "../../pages/static/StaticPage";

export default function Router() {
  const { data } = useGetAllStaticPages();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="novelty" element={<NovetlyPage />} />
      <Route path="viewed" element={<ViewedPage />} />
      <Route path="similar/:book" element={<SimilarPage />} />
      <Route path="all-books" element={<CatalogPage />} />
      <Route path="all-books/:subject" element={<SubjectPage />} />
      <Route path="account" element={<Account />} />
      <Route path="news" element={<News />} />
      <Route path="news/:informationPage" element={<InformationPage />} />
      <Route path="all-books/:subject/:book" element={<BookPage />} />
      <Route path="results" element={<ResultsPage />} />
      <Route path="order/:book" element={<Order />} />
      <Route path="order-success" element={<OrderSuccess />} />
      {!!data?.getAllStaticPages?.pages.length &&
        data?.getAllStaticPages?.pages.map((page) => {
          return (
            <Route key={page.id} path={page.alias} element={<StaticPage />} />
          );
        })}
    </Routes>
  );
}
