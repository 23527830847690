import React from "react";

const PaymentsWalletIcon = ({ width = 19, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.87394 8.65167C5.16327 6.88429 13.0581 0 13.0581 0L13.1072 9.63392L19 9.62915L18.9631 24L1.77841 23.9713C0.820424 23.9713 0.0540329 23.0918 0.0540329 22.1L0 13.0444C0 11.663 0.112983 10.7859 2.8764 8.65167H2.87394Z"
        fill="#F9C637"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0799 15.6041L2.52246 22.7429L15.4282 17.9319V9.64832L13.134 9.70088L13.0799 15.6041Z"
        fill="#C8A332"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58724 10.2364C8.10062 9.66283 8.8498 9.45969 9.26001 9.78353C9.67268 10.1086 9.59162 10.8375 9.07578 11.4123C8.56486 11.9859 7.81323 12.189 7.40301 11.8652C6.99034 11.5402 7.07386 10.8112 7.58724 10.2364Z"
        fill="#000106"
      />
    </svg>
  );
};

export default PaymentsWalletIcon;
