import React from "react";
import Facebook from "../../assets/icons/Facebook";
import Mail from "../../assets/icons/Mail";
import Twitter from "../../assets/icons/Twitter";
import VkIcon from "../../assets/icons/VkIcon";
import Youtube from "../../assets/icons/Youtube";
import Instagram from "../../assets/icons/Instagram";

const Contacts = ({
  contactsPosition,
  infoContainer,
  socialContainer,
  socialContent,
  colorIcon,
  textMail,
}) => {
  return (
    <div className={contactsPosition}>
      <div className={infoContainer}>
        <Mail color={colorIcon} width="20" height="16" />
        <a href="mailto:info@partashop.ru" className={textMail}>
          info@partashop.ru
        </a>
      </div>
      <div className={socialContainer}>
        <div className={socialContent}>
          <a href="https://www.facebook.com/">
            <Facebook color={colorIcon} />
          </a>
        </div>
        <div className={socialContent}>
          <a href="https://vk.com/">
            <VkIcon color={colorIcon} />
          </a>
        </div>
        <div className={socialContent}>
          <a href="https://www.instagram.com/">
            <Instagram color={colorIcon} />
          </a>
        </div>
        <div className={socialContent}>
          <a href="https://www.youtube.com/">
            <Youtube color={colorIcon} />
          </a>
        </div>
        <div className={socialContent}>
          <a href="https://twitter.com/">
            <Twitter color={colorIcon} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
