import React from "react";

const PaymentsQiwiIcon = ({ width = 23, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9213 21.0405C17.9454 21.0405 20.1455 21.7433 22.5769 23.9187C22.8214 24.1419 23.1421 23.8629 22.9318 23.5953C20.545 20.5496 18.3318 19.9695 16.1211 19.4786C13.4242 18.8762 12.041 17.3478 11.0679 15.6632C10.88 15.3285 10.7916 15.3843 10.7693 15.8194C10.7472 16.3437 10.7803 17.0466 10.902 17.7271C10.7916 17.7271 10.681 17.7271 10.5703 17.7271C6.71157 17.7271 3.58251 14.5699 3.58251 10.6764C3.58251 6.78294 6.71157 3.62574 10.5703 3.62574C14.4283 3.62574 17.5589 6.78294 17.5589 10.6764C17.5589 10.9553 17.5484 11.2231 17.5143 11.4908C17.0043 11.4016 16.0002 11.3904 15.2905 11.4462C15.0276 11.4685 15.0592 11.5912 15.2695 11.6358C17.58 12.0598 19.1624 13.4989 19.5278 16.1206C19.5383 16.1764 19.6145 16.1987 19.6487 16.1429C20.6002 14.5476 21.1417 12.6734 21.1417 10.6653C21.1417 4.77484 16.4076 0 10.5703 0C4.73224 0 0 4.77484 0 10.6653C0 16.5557 4.73224 21.3305 10.5703 21.3305C12.1183 21.3305 13.6555 21.0293 15.9108 21.0293L15.9213 21.0405ZM16.7073 17.1693C17.1831 17.5709 17.3145 18.0506 17.0727 18.3853C16.9281 18.575 16.7073 18.6754 16.4418 18.6754C16.1763 18.6754 15.9108 18.575 15.7242 18.4076C15.2905 18.0283 15.1696 17.4147 15.4456 17.0689C15.556 16.935 15.7347 16.8569 15.945 16.8569C16.1973 16.8569 16.476 16.9685 16.7073 17.1693ZM18.8732 16.1541C18.9416 16.6226 18.797 16.8123 18.6524 16.8123C18.4868 16.8123 18.2871 16.6226 18.0557 16.2545C17.8244 15.8863 17.7351 15.4624 17.856 15.2504C17.9348 15.1054 18.0899 15.0496 18.3002 15.1277C18.6866 15.2727 18.8417 15.8863 18.8864 16.1652L18.8732 16.1541Z"
        fill="#F69033"
      />
    </svg>
  );
};

export default PaymentsQiwiIcon;
