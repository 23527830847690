import React, { useRef, useContext } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LogoutIcon from "../../assets/icons/baseline-logout";
import useStyles from "./styles";
import * as style from "./style.module.css";
import { PRIVATE_URLS } from "../../constants";
import { UserLoginContext } from "../../contexts";

const AuthorizedUser = ({ openMenu, handleToggleMenu, anchorEl }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLogIn } = useContext(UserLoginContext);

  const handleLogout = () => {
    sessionStorage.removeItem("token_catalog");
    localStorage.removeItem("token_catalog");

    sessionStorage.removeItem("fullName");
    localStorage.removeItem("fullName");

    setLogIn(false);
    if (PRIVATE_URLS.includes(location.pathname)) {
      navigate("/");
    }
    handleToggleMenu();
  };

  const fullName =
    sessionStorage.getItem("fullName") || localStorage.getItem("fullName");

  const containerRef = useRef();

  return (
    <Box ref={containerRef}>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleToggleMenu}
        className={classes.menuAuthorized}
        container={containerRef.current}
      >
        <MenuItem>
          <Link to="/account">{fullName}</Link>
        </MenuItem>
        <hr className={style.userHr} />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon /> Выход
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AuthorizedUser;
