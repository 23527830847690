import React from "react";
import Box from "@mui/material/Box";

import MetaTags from "react-meta-tags";
import * as style from "./style.module.css";
import ScrollSection from "../../UI/ScrollSection/ScrollSection";
import GridSection from "../../UI/GridSection/GridSection";
import { useGetSelection } from "../../graphql";
import PreLoader from "../../UI/PreLoader/PreLoader";

const Home = () => {
  const { data, loading } = useGetSelection({
    variables: {
      page: "HOME_PAGE",
    },
  });

  if (loading) return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;

  return (
    <Box className={style.homePage}>
      <MetaTags>
        <title>Главная</title>
        <meta property="og:title" content="Главная" />
      </MetaTags>
      {data &&
        data.getSelection &&
        data.getSelection.map((section) => {
          if (section.style !== "HORIZONTAL") {
            const mainBook = section.textbooks.filter(
              (item) => item.id === section.mainTextbookId
            );
            const additionalBooks = section.textbooks.filter(
              (item) => item.id !== section.mainTextbookId
            );
            return (
              !!section.textbooks.length && (
                <GridSection
                  key={section.id}
                  sectionTitle={section.title}
                  mainCard={mainBook[0] || section.textbooks[0]}
                  additionalCards={additionalBooks}
                  slides={section.slider}
                  reverse={section.style === "MAIN_RIGHT"}
                  sliderSettingsAutoplay={section.sliderAutoScroling}
                />
              )
            );
          }
          return (
            !!section.textbooks.length && (
              <ScrollSection
                key={section.id}
                sectionTitle={section.title}
                productsList={section.textbooks}
                noLink
              />
            )
          );
        })}
    </Box>
  );
};

export default Home;
