import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tooltipDropdown: {
    padding: 0,
    "&.MuiTooltip-popper .MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltip":
      {
        margin: "7px 0 0 0",
        backgroundColor: "#ffffff",
        color: "#303030",
        boxShadow: "0px 1px 10px rgba(50, 71, 78, 0.15)",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: 0,
        padding: 0,
        maxWidth: (props) => {
          return props.widthTooltipDropdown ? props.widthTooltipDropdown : 222;
        },
        minWidth: (props) => {
          return props.widthTooltipDropdown ? props.widthTooltipDropdown : 222;
        },
      },
  },
  card: {
    position: "relative",
    width: (props) => (props.cardWidth ? props.cardWidth : "100%"),
    maxWidth: (props) => (props.cardMaxWidth ? props.cardMaxWidth : "auto"),
    height: (props) => (props.cardHeight ? props.cardHeight : "100%"),
  },
}));

export default useStyles;
