import React from "react";

const TopbarUser = ({ outlineFill = "#C5D7E0", width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="-2 -2 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0V0C31.0457 0 40 8.95431 40 20V20C40 31.0457 31.0457 40 20 40V40C8.95431 40 0 31.0457 0 20V20Z"
        fill="white"
      />
      <path
        d="M28.1212 25.8969C27.679 24.8496 27.0374 23.8984 26.2321 23.0961C25.4292 22.2915 24.4781 21.65 23.4313 21.207C23.4219 21.2023 23.4126 21.2 23.4032 21.1953C24.8633 20.1406 25.8126 18.4227 25.8126 16.4844C25.8126 13.2734 23.211 10.6719 20.0001 10.6719C16.7891 10.6719 14.1876 13.2734 14.1876 16.4844C14.1876 18.4227 15.1368 20.1406 16.5969 21.1977C16.5876 21.2023 16.5782 21.2047 16.5688 21.2094C15.5188 21.6523 14.5766 22.2875 13.768 23.0984C12.9634 23.9013 12.3219 24.8524 11.879 25.8992C11.4438 26.924 11.2091 28.0228 11.1876 29.1359C11.1869 29.161 11.1913 29.1858 11.2005 29.2091C11.2096 29.2324 11.2233 29.2537 11.2408 29.2716C11.2583 29.2895 11.2791 29.3037 11.3022 29.3134C11.3253 29.3231 11.35 29.3281 11.3751 29.3281H12.7813C12.8844 29.3281 12.9665 29.2461 12.9688 29.1453C13.0157 27.3359 13.7422 25.6414 15.0266 24.357C16.3555 23.0281 18.1204 22.2969 20.0001 22.2969C21.8797 22.2969 23.6446 23.0281 24.9735 24.357C26.2579 25.6414 26.9844 27.3359 27.0313 29.1453C27.0337 29.2484 27.1157 29.3281 27.2188 29.3281H28.6251C28.6501 29.3281 28.6749 29.3231 28.6979 29.3134C28.721 29.3037 28.7419 29.2895 28.7593 29.2716C28.7768 29.2537 28.7905 29.2324 28.7997 29.2091C28.8088 29.1858 28.8132 29.161 28.8126 29.1359C28.7891 28.0156 28.5571 26.9258 28.1212 25.8969V25.8969ZM20.0001 20.5156C18.9243 20.5156 17.9118 20.0961 17.1501 19.3344C16.3883 18.5727 15.9688 17.5602 15.9688 16.4844C15.9688 15.4086 16.3883 14.3961 17.1501 13.6344C17.9118 12.8727 18.9243 12.4531 20.0001 12.4531C21.0758 12.4531 22.0883 12.8727 22.8501 13.6344C23.6118 14.3961 24.0313 15.4086 24.0313 16.4844C24.0313 17.5602 23.6118 18.5727 22.8501 19.3344C22.0883 20.0961 21.0758 20.5156 20.0001 20.5156Z"
        fill="#0B486B"
      />
      <path
        d="M20 38C10.0589 38 2 29.9411 2 20H-2C-2 32.1503 7.84974 42 20 42V38ZM38 20C38 29.9411 29.9411 38 20 38V42C32.1503 42 42 32.1503 42 20H38ZM20 2C29.9411 2 38 10.0589 38 20H42C42 7.84974 32.1503 -2 20 -2V2ZM20 -2C7.84974 -2 -2 7.84974 -2 20H2C2 10.0589 10.0589 2 20 2V-2Z"
        fill={outlineFill}
      />
    </svg>
  );
};

export default TopbarUser;
