import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../../assets/icons/CloseIcon";
import useStyles from "./styles";

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon width="12" height="12" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ModalWindow({
  modalTitle,
  children,
  open,
  handleClose,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog onClose={handleClose} open={open} className={classes.Dialog}>
        <CustomDialogTitle onClose={handleClose}>
          {modalTitle}
        </CustomDialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
}
