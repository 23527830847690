import React from "react";

const AntDesignHomeFilled = ({
  color = "#0B486B",
  width = 22,
  height = 22,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1838 10.8359L11.5299 1.18907C11.4604 1.11942 11.3778 1.06417 11.2869 1.02647C11.196 0.988766 11.0986 0.96936 11.0002 0.96936C10.9018 0.96936 10.8044 0.988766 10.7135 1.02647C10.6226 1.06417 10.54 1.11942 10.4705 1.18907L0.816602 10.8359C0.535352 11.1172 0.375977 11.4992 0.375977 11.8977C0.375977 12.725 1.04863 13.3977 1.87598 13.3977H2.89316V20.2813C2.89316 20.6961 3.22832 21.0313 3.64316 21.0313H9.50019V15.7813H12.1252V21.0313H18.3572C18.7721 21.0313 19.1072 20.6961 19.1072 20.2813V13.3977H20.1244C20.5229 13.3977 20.9049 13.2406 21.1861 12.957C21.7697 12.3711 21.7697 11.4219 21.1838 10.8359Z"
        fill={color}
      />
    </svg>
  );
};

export default AntDesignHomeFilled;
