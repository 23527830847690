import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

import { Scrollbar } from "react-scrollbars-custom";
import * as style from "./style.module.css";

const ScrollbarWrap = ({ children }) => {
  const sectionRef = useRef(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    setHeight(sectionRef.current.clientHeight + 10);
  });

  return (
    <Scrollbar
      className={style.scroll}
      style={{ width: "100%", height: height || 0 }}
      trackXProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <Box {...restProps} ref={elementRef} className={style.trackX} />
          );
        },
      }}
      thumbXProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <Box {...restProps} ref={elementRef} className={style.thumbX} />
          );
        },
      }}
    >
      <Box ref={sectionRef} className={style.additionalCards}>
        {children}
      </Box>
    </Scrollbar>
  );
};

export default ScrollbarWrap;
