import React from "react";

const BiCreditCardFill = ({ color = "#0B486B", width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66699 11.1111C1.66699 9.93233 2.14988 8.80187 3.00942 7.96837C3.86896 7.13488 5.03475 6.66663 6.25033 6.66663H33.7503C34.9659 6.66663 36.1317 7.13488 36.9912 7.96837C37.8508 8.80187 38.3337 9.93233 38.3337 11.1111V13.3333H1.66699V11.1111ZM1.66699 17.7777V28.8888C1.66699 30.0676 2.14988 31.198 3.00942 32.0315C3.86896 32.865 5.03475 33.3333 6.25033 33.3333H33.7503C34.9659 33.3333 36.1317 32.865 36.9912 32.0315C37.8508 31.198 38.3337 30.0676 38.3337 28.8888V17.7777H1.66699ZM8.54199 22.2222H10.8337C11.4414 22.2222 12.0243 22.4563 12.4541 22.8731C12.8839 23.2898 13.1253 23.855 13.1253 24.4444V26.6666C13.1253 27.256 12.8839 27.8212 12.4541 28.238C12.0243 28.6547 11.4414 28.8888 10.8337 28.8888H8.54199C7.9342 28.8888 7.35131 28.6547 6.92154 28.238C6.49177 27.8212 6.25033 27.256 6.25033 26.6666V24.4444C6.25033 23.855 6.49177 23.2898 6.92154 22.8731C7.35131 22.4563 7.9342 22.2222 8.54199 22.2222Z"
        fill={color}
      />
    </svg>
  );
};

export default BiCreditCardFill;
