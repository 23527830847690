import React from "react";

const BurgerMenu = ({ color = "#ffffff", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="5" width="24" height="2" fill={color} />
      <rect y="11" width="24" height="2" fill={color} />
      <rect y="17" width="24" height="2" fill={color} />
    </svg>
  );
};

export default BurgerMenu;
