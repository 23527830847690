import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tooltipDropdown: {
    padding: 0,
    marginRight: "-50px !important",
    "&.MuiTooltip-popper .MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltip":
      {
        margin: "27.5px 0 0 0",
        backgroundColor: "#ffffff",
        color: "#303030",
        boxShadow: "0px 1px 10px rgba(50, 71, 78, 0.15)",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: 0,
        padding: 0,
        "@media (min-width:1000px)": {
          width: 614,
          maxWidth: "100%",
        },
      },
  },
}));

export default useStyles;
