import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dialog: {
    position: "relative",
    width: "100%",
    height: "100%",
    background: "#FAFAFA",

    "& .MuiTypography-root.MuiDialogTitle-root": {
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: 0,
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 48,
      color: "#5C5C5C",
    },
    "& .MuiButtonBase-root.MuiIconButton-root": {
      padding: 0,
    },
    "& .MuiDialogContent-root": {
      padding: 0,
      height: "100%",
    },
  },
  closeBtn: {
    "&.MuiButtonBase-root.MuiIconButton-root": {
      position: "absolute",
      right: 17,
      top: 17,
      width: 25,
      height: 25,
      borderRadius: "50%",
    },
  },
}));

export default useStyles;
