import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import ChangePassword from "./ChangePassword";

const ChangePasswordService = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [recoverToken, setRecoverToken] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const clearRecoverToken = () => setRecoverToken("");

  const handleToggleModal = () => {
    setModalOpen((prev) => {
      if (prev) clearRecoverToken();
      return !prev;
    });
  };

  const recToken = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).t;
  }, [location.search]);

  useEffect(() => {
    if (!isModalOpen && recToken) {
      setRecoverToken(recToken);
      navigate(location.pathname);
      handleToggleModal();
    }
  }, [recToken, isModalOpen, location.pathname]);

  return (
    <ChangePassword
      open={isModalOpen}
      handleClose={handleToggleModal}
      recoverToken={recoverToken}
      clearRecoverToken={clearRecoverToken}
    />
  );
};
export default ChangePasswordService;
