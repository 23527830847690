import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

import { CardActions, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import clsx from "clsx";
import ThreeDotsVerticalIcon from "../../assets/icons/ThreeDotsVerticalIcon";
import BookmarkIcon from "../../assets/icons/BookmarkIcon";
import BiBagFill from "../../assets/icons/BiBagFill";
import Favorite from "../../assets/icons/Favorite";
import * as style from "./style.module.css";
import useStyles from "./styles";
import PartaBtn from "../../common/button";
import NoImg from "../../assets/icons/NoImg";
import {
  useAddToFavorited,
  useDeleteFavorite,
  useGetUserFavoritesLazy,
} from "../../graphql";

const ProductCard = ({ product, cardMaxWidth, cardWidth, cardHeight }) => {
  const cardRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [favoriteBtnState, setFavoriteBtnState] = useState(false);
  const [widthTooltipDropdown, setWidthTooltipDropdown] = useState(null);
  const token =
    localStorage.getItem("token_catalog") ||
    sessionStorage.getItem("token_catalog");
  const classes = useStyles({
    widthTooltipDropdown,
    cardWidth,
    cardHeight,
    cardMaxWidth,
  });

  const [
    setAddFavorited,
    { data: dataAddFavorited, loading: loadingAddFavorite },
  ] = useAddToFavorited();

  const [
    setDelFavorited,
    { data: dataDelFavorited, loading: loadingDelFavorite },
  ] = useDeleteFavorite();

  const [getUserFavorites, { data, loading }] = useGetUserFavoritesLazy();

  useEffect(() => {
    if (token) {
      getUserFavorites({
        variables: {
          options: {
            limit: 0,
            offset: 0,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (data?.getUserFavorites) {
      const isIncludesUserFavorites = data.getUserFavorites.textbooks.some(
        (item) => item.id === product.id
      );
      setFavoriteBtnState(isIncludesUserFavorites);
    }
  }, [data?.getUserFavorites]);

  useEffect(() => {
    if (dataAddFavorited?.addToFavorite) {
      setFavoriteBtnState(dataAddFavorited.addToFavorite.status);
    }
  }, [dataAddFavorited?.addToFavorite]);

  useEffect(() => {
    if (dataDelFavorited?.deleteFavorite) {
      setFavoriteBtnState(!dataDelFavorited.deleteFavorite.status);
    }
  }, [dataDelFavorited?.deleteFavorite]);

  useEffect(() => {
    setWidthTooltipDropdown(cardRef.current.offsetWidth);
  }, [cardRef.current]);

  const handleAddFavorite = () => {
    setAddFavorited({
      variables: {
        textBookId: Number(product.id),
      },
    });
  };

  const handleDeleteFavorite = () => {
    setDelFavorited({
      variables: {
        textBookId: Number(product.id),
      },
    });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleLeave = () => {
    setExpanded(false);
  };

  return (
    <Box
      ref={cardRef}
      className={clsx(classes.card, style.card)}
      onMouseLeave={handleLeave}
    >
      <Link
        className={clsx(style.cardLink, !product.cover && style.cardLinkBorder)}
        to={`/all-books/${product.subject.alias}/${product.id}`}
      >
        {product.cover ? (
          <img
            className={style.cardImg}
            src={`${process.env.REACT_APP_URL}${product.cover}`}
            alt={product.title}
          />
        ) : (
          <NoImg />
        )}
      </Link>
      <Box className={style.cardContent}>
        <CardActions disableSpacing className={style.cardActions}>
          <Tooltip
            open={expanded}
            placement="bottom"
            classes={{ popper: classes.tooltipDropdown }}
            title={
              <Box className={style.dropdown}>
                <Box className={style.dropdownInfo}>
                  <Box className={style.dropdownRow}>
                    <Typography className={style.dropdownRowName}>
                      Автор
                    </Typography>
                    <Typography className={style.dropdownRowVal}>
                      {product.authors}
                    </Typography>
                  </Box>
                  <Box className={style.dropdownRow}>
                    <Typography className={style.dropdownRowName}>
                      Предмет
                    </Typography>
                    <Typography className={style.dropdownRowVal}>
                      {product.subject.name}
                    </Typography>
                  </Box>
                </Box>
                <Box className={style.dropdownBtns}>
                  <Box className={style.btnPrice}>
                    <Link className={style.link} to={`/order/${product.id}`}>
                      <PartaBtn
                        variant="contained"
                        buttonText={`Купить ${product.price} ₽`}
                        startIcon={<BiBagFill color="#fff" />}
                      />
                    </Link>
                  </Box>
                  {token && (
                    <Box className={style.btnWishlist}>
                      <PartaBtn
                        variant={favoriteBtnState ? "contained" : "outlined"}
                        buttonText={
                          favoriteBtnState ? "Из избранного" : "В избранное"
                        }
                        disabled={
                          loadingAddFavorite || loadingDelFavorite || loading
                        }
                        onClick={
                          favoriteBtnState
                            ? handleDeleteFavorite
                            : handleAddFavorite
                        }
                        startIcon={
                          <Favorite
                            color={favoriteBtnState ? "#ffffff" : "#0B486B"}
                          />
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            }
          >
            <Box className={style.cardActionsVisible}>
              <Typography className={style.cardActionsVisibleText}>
                {product.title}
              </Typography>
              <Box
                className={style.cardActionsVisibleBtn}
                onClick={handleExpandClick}
              >
                <ThreeDotsVerticalIcon color="#fff" />
              </Box>
            </Box>
          </Tooltip>
          <Box className={style.cardActionsInfo}>
            <Typography className={style.cardActionsInfoText}>
              {product.authors}
            </Typography>
            <Typography className={style.cardActionsInfoText}>
              {product.issueYear}
            </Typography>
          </Box>
          <Box className={style.cardActionsBtn}>
            <Link
              className={style.link}
              to={`/all-books/${product.subject.alias}/${product.id}`}
            >
              <PartaBtn
                variant="contained"
                buttonText="Подробнее"
                startIcon={<BookmarkIcon color="#fff" />}
              />
            </Link>
          </Box>
        </CardActions>
      </Box>
    </Box>
  );
};

export default ProductCard;
