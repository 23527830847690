import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";

import { Scrollbar } from "react-scrollbars-custom";
import { Link } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import * as style from "./style.module.css";
import BxBxsChevronRight from "../../assets/icons/BxBxsChevronRight";
import { BREAKPOINTS } from "../../constants";
import PartaBtn from "../../common/button";
import ScrollSectionInner from "./ScrollSectionInner";

const RScrollSection = ({
  productsList,
  sectionTitle,
  sectionLinkText,
  arrowRightStyle,
  linkUrl,
  noLink,
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isDesktop = ["tablet", "desktop"].includes(breakpoint);
  const sectionRef = useRef(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    setHeight(sectionRef.current.clientHeight + 10);
  });

  return (
    <Box className={style.sectionBorder}>
      <Box className={style.sectionHead}>
        <Box className={style.sectionTitle}>{sectionTitle}</Box>
        {isDesktop && !noLink && (
          <Link className={style.sectionLink} to={linkUrl || "/"}>
            <Box className={style.sectionLinkInner}>
              {sectionLinkText}
              <BxBxsChevronRight
                color="#0B486B"
                arrowRightStyle={arrowRightStyle}
              />
            </Box>
          </Link>
        )}
      </Box>
      <Scrollbar
        className={style.scroll}
        style={{ width: "100%", height: height || 0 }}
        trackXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <Box {...restProps} ref={elementRef} className={style.trackX} />
            );
          },
        }}
        thumbXProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <Box {...restProps} ref={elementRef} className={style.thumbX} />
            );
          },
        }}
      >
        <Box ref={sectionRef} className={style.sectionScroll}>
          <ScrollSectionInner productsList={productsList} />
        </Box>
      </Scrollbar>
      {!isDesktop && !noLink && (
        <Link className={style.sectionLinkMobile} to={linkUrl || "/"}>
          <PartaBtn
            variant="contained"
            buttonText={sectionLinkText}
            endIcon={<BxBxsChevronRight color="#ffffff" />}
          />
        </Link>
      )}
    </Box>
  );
};

export default RScrollSection;
