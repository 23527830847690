import React from "react";
import { Link } from "react-router-dom";
import * as style from "./style.module.css";

const Copyright = () => (
  <div className={style.copyright}>
    <div>&copy;2021, partashop.ru</div>
    <Link to="/rules">Правила и Условия</Link>
  </div>
);

export default Copyright;
