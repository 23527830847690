import { useMutation } from "@apollo/client";
import * as MUTATIONS from "../mutations.gql";

export const useSignUp = (options) => {
  return useMutation(MUTATIONS.signUpGQL, options);
};

export const useResetPassword = (options) => {
  return useMutation(MUTATIONS.resetPasswordGQL, options);
};

export const useNewPassword = (options) => {
  return useMutation(MUTATIONS.newPasswordGQL, options);
};

export const useUserBuyBook = (options) => {
  return useMutation(MUTATIONS.userBuyBookGQL, options);
};

export const useEditInfoUser = (options) => {
  return useMutation(MUTATIONS.editInfoUserGQL, options);
};

export const useChangePassword = (options) => {
  return useMutation(MUTATIONS.changePasswordGQL, options);
};

export const useAddToFavorited = (options) => {
  return useMutation(MUTATIONS.addToFavoritedGQL, options);
};

export const useDeleteFavorite = (options) => {
  return useMutation(MUTATIONS.deleteFavoriteGQL, options);
};
