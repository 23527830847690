import React from "react";
import CheckIcon from "../../assets/icons/CheckIcon";
import useStyles from "./styles";

const OrderSuccess = () => {
  const classes = useStyles();

  return (
    <div className={classes.successContainer}>
      <CheckIcon />
      <span>Спасибо за покупку!</span>
      <span className={classes.payment}>Вы успешно произвели оплату.</span>
    </div>
  );
};
export default OrderSuccess;
