import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  checkboxWrapper: {
    "&.MuiFormControlLabel-root": {
      margin: 0,
      "& .MuiFormControlLabel-label": {
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: 0,
        color: "#00000066",
      },
    },
    "& .MuiButtonBase-root.MuiCheckbox-root": {
      padding: 0,
      marginRight: 9,
    },
  },
}));

export default useStyles;
