import React from "react";

const Twitter = ({ color = "#C5D7E0", width = 20, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7499 1.96017C19.0327 2.26954 18.2522 2.4922 17.4483 2.57892C18.283 2.08306 18.908 1.2997 19.2061 0.375791C18.4229 0.841703 17.5649 1.16841 16.6702 1.34142C16.2963 0.941634 15.844 0.62315 15.3415 0.405808C14.8391 0.188467 14.2973 0.0769255 13.7499 0.0781347C11.5351 0.0781347 9.75381 1.87345 9.75381 4.07657C9.75381 4.38595 9.79131 4.69532 9.85225 4.99298C6.53584 4.81954 3.57803 3.23517 1.61162 0.809385C1.25332 1.42137 1.06556 2.1182 1.06787 2.82735C1.06787 4.21485 1.77334 5.43829 2.84912 6.15782C2.21515 6.13286 1.59602 5.9586 1.04209 5.64923V5.69845C1.04209 7.64142 2.41553 9.25157 4.246 9.62189C3.90231 9.71116 3.54875 9.75683 3.19365 9.75782C2.9335 9.75782 2.6874 9.73204 2.43896 9.69689C2.94521 11.2813 4.41943 12.432 6.1749 12.4695C4.80146 13.5453 3.08115 14.1781 1.21318 14.1781C0.878027 14.1781 0.568652 14.1664 0.247559 14.1289C2.01943 15.2656 4.12178 15.9219 6.38584 15.9219C13.7358 15.9219 17.7577 9.83282 17.7577 4.54767C17.7577 4.37423 17.7577 4.20079 17.746 4.02735C18.5241 3.45782 19.2062 2.75235 19.7499 1.96017Z"
        fill={color}
      />
    </svg>
  );
};

export default Twitter;
