import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckboxIcon from "../../assets/icons/Checkbox";
import CheckboxChecked from "../../assets/icons/CheckboxChecked";
import useStyles from "./styles";

const CheckboxStandart = ({ label, name, value, onChange, onBlur }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.checkboxWrapper}
      control={
        <Checkbox
          name={name}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          checkedIcon={<CheckboxChecked />}
          icon={<CheckboxIcon />}
        />
      }
      label={label}
    />
  );
};

export default CheckboxStandart;
