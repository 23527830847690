import React, { useMemo, useState, useContext, useEffect } from "react";
import clsx from "clsx";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { Formik, Field, Form } from "formik";
import MetaTags from "react-meta-tags";
import NavigationMenu from "../../components/nav-menu";
import useStyles from "./styles";
import PartaBtn from "../../common/button";
import LoginForm from "../../components/forms/LoginForm";
import { schemaOrder } from "../../components/forms/schema";
import InputStandart from "../../common/input";
import RegistrationForm from "../../components/forms/RegistrationForm";
import Payments from "../../UI/Payments/Payments";
import BiCreditCardFill from "../../assets/icons/BiCreditCardFill";
import ScrollSection from "../../UI/ScrollSection/ScrollSection";
import PreLoader from "../../UI/PreLoader/PreLoader";
import {
  useCurrentUser,
  useGetSelection,
  useGetTextBookById,
  useUserBuyBook,
} from "../../graphql";
import { UserLoginContext } from "../../contexts";

const Order = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);
  const { isLoggedIn } = useContext(UserLoginContext);
  const classes = useStyles();

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleOpenRegistration = () => {
    setOpenRegistration(true);
  };
  const handleCloseRegistration = () => {
    setOpenRegistration(false);
  };

  const { data: dataSelections, loading: loadingSelections } = useGetSelection({
    variables: {
      page: "CHECKOUT_PAGE",
    },
  });
  const {
    data: dataCurrentUser,
    loading: loadingCurrentUser,
    refetch,
  } = useCurrentUser();

  useEffect(() => {
    if (isLoggedIn) {
      refetch();
    }
  }, [isLoggedIn]);

  const initialValues = useMemo(() => {
    if (isLoggedIn && dataCurrentUser?.currentUser) {
      return {
        fullname: dataCurrentUser.currentUser.fullName,
        phone: dataCurrentUser.currentUser.phone,
        email: dataCurrentUser.currentUser.email,
      };
    }
    return {
      fullname: "",
      phone: "",
      email: "",
    };
  }, [dataCurrentUser, isLoggedIn]);

  const params = useParams();
  const { data: dataBookId, loading: loadingBookId } = useGetTextBookById({
    variables: {
      textbookId: params.book,
    },
  });

  const breadcrumbNameMap = useMemo(() => {
    if (dataBookId?.getTextbookById) {
      const { id: bookId } = dataBookId.getTextbookById;

      return {
        [`/order/${bookId}`]: "Оформление заказа",
      };
    }

    return {};
  }, [dataBookId]);

  const [userBuyBook] = useUserBuyBook();

  if (loadingSelections || loadingCurrentUser || loadingBookId) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <div>
      <MetaTags>
        <title>Оформление заказа</title>
        <meta property="og:title" content="Оформление заказа" />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <div className="page-title">Оформление заказа</div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          userBuyBook({
            variables: {
              userInfo: {
                fullName: values.fullname,
                phone: values.phone,
                email: values.email,
              },
              textBookId: +params.book,
            },
          })
            .then((response) => {
              window.location.href = response.data.userBuyBook.link;
            })
            .catch((e) => {
              actions.setErrors({
                email: e.message,
              });
            });
        }}
        validationSchema={schemaOrder}
        enableReinitialize
      >
        <Form>
          <div className={classes.orderWrapper}>
            <div className={classes.w100}>
              {!isLoggedIn && (
                <div className={classes.orderContainer}>
                  <div className={classes.orderTitle}>
                    Информация о покупателе
                  </div>
                  <div className={classes.helpBlock}>
                    Уже зарегистрированы?{" "}
                    <Button onClick={handleClickOpenLogin}>
                      Авторизуйтесь
                    </Button>{" "}
                    и Вам не придется вводить заново контактную информацию.
                  </div>
                  <div className={classes.orderInputWrapper}>
                    <Field name="fullname">
                      {({ field, meta }) => {
                        return (
                          <InputStandart
                            inputType="text"
                            placeholder="ФИО покупателя"
                            inputStyle={classes.inputMaxWidth}
                            touched={meta.touched}
                            error={meta.error}
                            {...field}
                          />
                        );
                      }}
                    </Field>
                    <Field name="phone">
                      {({ field, meta }) => {
                        return (
                          <InputStandart
                            inputType="tel"
                            placeholder="Номер телефона"
                            touched={meta.touched}
                            error={meta.error}
                            {...field}
                          />
                        );
                      }}
                    </Field>
                    <Field name="email">
                      {({ field, meta }) => {
                        return (
                          <InputStandart
                            inputType="email"
                            placeholder="Email адрес"
                            touched={meta.touched}
                            error={meta.error}
                            {...field}
                          />
                        );
                      }}
                    </Field>
                  </div>
                </div>
              )}
              <div className={classes.orderContainer}>
                <div className={classes.orderTitle}>Способ оплаты</div>
                <div className={classes.paymentWrapper}>
                  <div className={classes.dFlex}>
                    <div className={classes.creditCardWrapper}>
                      <BiCreditCardFill />
                    </div>
                    <div className={classes.flexColumn}>
                      <div className={classes.paymentTitle}>
                        Оплата картой онлайн: VISA, Master Card...
                      </div>
                      <div className={classes.paymentDescription}>
                        Оплата онлайн картами VISA, MasterCard, МИР или с
                        помощью популярных систем электронных платежей (через
                        Яндекс.Кассу)
                      </div>
                    </div>
                  </div>
                  <Payments />
                </div>
              </div>
            </div>
            <div className={classes.orderMaxWidth}>
              <div className={classes.orderContainer}>
                <div className={classes.orderTitle}>Итого</div>
                <div className={clsx(classes.desc, classes.mb14)}>Товар:</div>
                <div className={classes.productName}>
                  {dataBookId?.getTextbookById?.title}
                </div>
                <div className={classes.spaceBetween}>
                  <div className={classes.desc}>Товар:</div>
                  <div className={classes.price}>
                    {dataBookId?.getTextbookById?.price} ₽
                  </div>
                </div>
                <div
                  className={clsx(classes.spaceBetween, classes.borderBottom)}
                />
                <div className={classes.spaceBetween}>
                  <div className={classes.desc}>Всего к оплате:</div>
                  <div className={clsx(classes.price, classes.totalPrice)}>
                    {dataBookId?.getTextbookById?.price} ₽
                  </div>
                </div>
                <div className={classes.btnWrapper}>
                  <PartaBtn
                    variant="contained"
                    buttonText="Оформить заказ"
                    fullWidth={false}
                    type="submit"
                  />
                </div>
                <div className={classes.userAgreement}>
                  Подтверждая заказ, я принимаю условия
                  <br />
                  <Link to="/">пользовательского соглашения</Link>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>

      {dataSelections &&
        dataSelections.getSelection &&
        dataSelections.getSelection.map((section) => {
          return (
            !!section.textbooks.length && (
              <ScrollSection
                key={section.id}
                sectionTitle={section.title}
                productsList={section.textbooks}
                noLink
              />
            )
          );
        })}

      <LoginForm
        handleClose={handleCloseLogin}
        open={openLogin}
        handleOpenRegistration={handleOpenRegistration}
        handleClickOpenLogin={handleClickOpenLogin}
      />

      <RegistrationForm
        handleClose={handleCloseRegistration}
        open={openRegistration}
        handleClickOpenLogin={handleClickOpenLogin}
      />
    </div>
  );
};
export default Order;
