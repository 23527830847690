import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  newsWrapper: {
    display: "flex",
    "@media (max-width:1220px)": {
      flexDirection: "column-reverse",
      marginTop: 24,
    },
  },
  infoSection: {
    marginTop: 40,
    marginRight: 24,
    "@media (max-width: 639.9px)": {
      marginRight: 0,
    },
  },
  infoContainer: {
    display: "flex",
    marginBottom: 40,
    maxWidth: 1059,
    width: "100%",
    "@media (max-width: 639.9px)": {
      flexDirection: "column",
    },
  },
  infoLinkImg: {
    maxWidth: 247,
    width: "100%",
    minHeight: 160,
    marginRight: 24,
    minWidth: 247,
    "@media (max-width: 639.9px)": {
      maxWidth: "unset",
      minWidth: "unset",
      marginRight: 0,
    },
  },
  infoImg: {
    width: "100%",
    minHeight: 160,
    objectFit: "cover",
    "@media (max-width: 639.9px)": {
      maxWidth: "unset",
      minWidth: "unset",
      marginRight: 0,
    },
  },
  infoTitle: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "19px",
    letterSpacing: 0,
    marginBottom: 12,
    color: "#303030",
    "@media (max-width: 639.9px)": {
      marginTop: 24,
    },
  },
  infoDescription: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "23px",
    color: "#303030",
    marginBottom: 20,
    "@media (max-width: 639.9px)": {
      marginBottom: 12,
    },
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "@media (max-width: 639.9px)": {
      flexDirection: "column",
    },
    "& a": {
      "@media (max-width: 639.9px)": {
        display: "flex",
        justifyContent: "center",
      },
    },
  },
  infoDate: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#00000066",
    textTransform: "capitalize",
    "@media (max-width: 639.9px)": {
      marginBottom: 32,
    },
  },
  marginCard: {
    marginRight: 24,
  },
  link: {
    textDecoration: "none",
    color: "#303030",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkText: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#303030",
    display: "flex",
    alignItems: "center",
    "& span": {
      marginRight: 8,
    },
  },
  linkBtn: {
    height: 32,
    borderRadius: 8,
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: "normal",
    textTransform: "initial",
    padding: "8px 16px",
    whiteSpace: "nowrap",
    background: "#0B486B",
    border: "1px solid #0B486B",
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: 16,
      height: 16,
    },
    "& span": {
      marginRight: 10,
    },
  },
  articleWrapper: {
    marginBottom: 20,
  },
  articleTitle: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#4D6E7D",
    marginBottom: 8,
  },
  articleDate: {
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#00000066",
  },
  articleSection: {
    maxWidth: 517,
    width: "100%",
    height: "fit-content",
    marginBottom: 20,
  },
  articlesHeight: {
    maxHeight: 456,
    height: "100%",
    overflowY: "auto",
    "@media (max-width: 639.9px)": {
      paddingBottom: 8,
      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    },
  },
  returnBtn: {
    padding: "0px 24px",
  },
  paginationContainer: {
    margin: "64px 0px",
    maxWidth: 1059,
    width: "100%",
  },
  marginBtn: {
    marginTop: 16,
  },
  infoPageContainer: {
    maxWidth: 1059,
    width: "100%",
    marginRight: 24,
    marginBottom: 64,
    "@media (max-width: 639.9px)": {
      marginRight: 0,
    },
    "& img": {
      margin: " 40px 0px",
      maxHeight: 320,
    },
    "& img:first-child": {
      marginTop: 0,
    },
  },
  detailInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "12px 24px 36px 0px",
    maxWidth: 1059,
    width: "100%",
    "@media (max-width: 639.9px)": {
      marginRight: 0,
      marginBottom: 0,
      flexDirection: "column",
      alignItems: "unset",
    },
  },
  shareContainer: {
    "& > div p": {
      display: "none !important",
    },
    "& > div": {
      marginTop: 24,
    },
  },
  btnAllArticles: {
    marginBottom: 40,
  },
}));

export default useStyles;
