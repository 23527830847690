import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import MetaTags from "react-meta-tags";
import NavigationMenu from "../../components/nav-menu";
import useStyles from "./styles";
import AntDesignUserOutlined from "../../assets/icons/AntDesignUserOutlined";
import IcomoonFreeBooks from "../../assets/icons/IcomoonFreeBooks";
import FavoriteFill from "../../assets/icons/FaviriteFill";
import Information from "./Information";
import ScrollSection from "../../UI/ScrollSection/ScrollSection";
import MyBooks from "./MyBooks";
import MyBookmarks from "./MyBookmarks";
import { useGetViewedUserBook } from "../../graphql";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const breadcrumbNameMap = {
  "/account": "Мой аккаунт",
};

const accountTabsRoutes = {
  0: "/account?information",
  1: "/account?myBooks",
  2: "/account?myBookmarks",
};

const Account = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);

    navigate(accountTabsRoutes[newValue]);
  };

  const query = useLocation();

  useEffect(() => {
    if (query.search === "?myBooks") {
      setValue(1);
    }
  }, []);

  const classes = useStyles();

  const { data } = useGetViewedUserBook();

  return (
    <div>
      <MetaTags>
        <title>Мой аккаунт</title>
        <meta property="og:title" content="Мой аккаунт" />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <div className="page-title">Мой аккаунт</div>
      <div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            className={classes.tabsContainer}
          >
            <Tab
              icon={<AntDesignUserOutlined width="20" heightt="20" />}
              iconPosition="start"
              label="Информация"
              disableRipple
              {...a11yProps(0)}
            />
            <Tab
              icon={<IcomoonFreeBooks width="20" height="20" />}
              iconPosition="start"
              label="Мои книги"
              disableRipple
              {...a11yProps(1)}
            />
            <Tab
              icon={<FavoriteFill width="20" height="20" />}
              iconPosition="start"
              label="Мои закладки"
              disableRipple
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className={classes.tabPanelInfo}>
          <Information />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanelBook}>
          <MyBooks />
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabPanelBook}>
          <MyBookmarks />
        </TabPanel>
      </div>
      {!!data?.getViewedUserBook.length && (
        <ScrollSection
          sectionTitle="Просмотренные книги"
          sectionLinkText="Все просмотренные"
          linkUrl="/viewed"
          productsList={data?.getViewedUserBook}
        />
      )}
    </div>
  );
};
export default Account;
