import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemButton } from "@mui/material/";
import { Link } from "react-router-dom";
import IcSharpSupport from "../../assets/icons/IcSharpSupport";
import { useGetAllStaticVisiblePages } from "../../graphql";

const MainMenu = ({
  listContainer,
  listItemButton,
  listItem,
  listItemLink,
  colorIcon,
}) => {
  const { data } = useGetAllStaticVisiblePages();
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    if (data?.getAllStaticVisiblePages) {
      setMenuList(data?.getAllStaticVisiblePages);
    }
  }, [data?.getAllStaticVisiblePages]);

  const menu = [
    { title: "Все книги", link: "all-books" },
    { title: "Новинки", link: "novelty" },
    { title: "Новости", link: "news" },
  ];

  return (
    <List className={listContainer}>
      {menu.map((menuItem) => {
        return (
          <ListItem key={Math.random()} className={listItem}>
            <ListItemButton className={listItemButton}>
              <Link to={menuItem.link} className={listItemLink}>
                {menuItem.title}
              </Link>
            </ListItemButton>
          </ListItem>
        );
      })}
      {!!menuList?.length &&
        menuList.map((menuItem) => {
          return (
            <ListItem key={menuItem.id} className={listItem}>
              <ListItemButton className={listItemButton}>
                <Link to={menuItem.alias} className={listItemLink}>
                  {menuItem.title}
                </Link>
              </ListItemButton>
            </ListItem>
          );
        })}
      <ListItem className={listItem}>
        <ListItemButton className={listItemButton}>
          <a
            href="#support"
            className={listItemLink}
            target="_blank"
            rel="noreferrer"
          >
            <IcSharpSupport color={colorIcon} width="20" height="20" />
            Служба поддержки
          </a>
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default MainMenu;
