import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import CiFilter from "../../assets/icons/CiFilter";
import PartaBtn from "../../common/button";
import ModalFullscreen from "../../common/modal-fullscreen";
import Filters from "../../components/filters";
import { BREAKPOINTS } from "../../constants";
import { useGetUserBooks } from "../../graphql";
import AllBooks from "../../UI/AllBooks/AllBooks";
import CustomPagination from "../../UI/Pagination/Pagination";
import { getToken } from "../../utils/getToken";
import useStyles from "./styles";

const MyBooks = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [offset, setOffset] = useState(null);
  const [limit, setLimit] = useState(null);
  const [countProducs, setCountProducts] = useState(null);
  const [productsList, setProductsList] = useState([]);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const showSearchBelowHeader = ["smallMobile", "mobile"].includes(breakpoint);

  const { data, refetch } = useGetUserBooks({
    variables: {
      options: {
        limit,
        offset,
      },
    },
  });

  useEffect(() => {
    if (data?.getUserBooks) {
      setCountProducts(data.getUserBooks.count);
      setProductsList(data.getUserBooks.textbooks);
    }
  }, [data?.getUserBooks]);

  const location = useLocation();
  const navigate = useNavigate();

  const cookiesToken = getToken();

  // if user is not auhorized and is on route "/account?myBooks"
  const redirectHome = useMemo(() => {
    const isUserAuthorized =
      localStorage.getItem("token_catalog") ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token_catalog") ||
      sessionStorage.getItem("token") ||
      cookiesToken;

    return (
      `${location.pathname}${location.search}` === "/account?myBooks" &&
      !isUserAuthorized
    );
  }, [cookiesToken, location]);

  useEffect(() => {
    if (redirectHome) {
      navigate("/", {
        state: {
          openLogin: true,
        },
      });
    }
  }, [redirectHome]);

  return (
    <div>
      {showSearchBelowHeader ? (
        <div className={classes.btnWrapper}>
          <PartaBtn
            variant="outlined"
            buttonText="Фильтры"
            type="button"
            fullWidth
            startIcon={<CiFilter width="16" height="16" color="#0B486B" />}
            onClick={handleShowFilters}
          />
          <ModalFullscreen open={showFilters} handleClose={handleShowFilters}>
            <Filters refetch={refetch} />
          </ModalFullscreen>
        </div>
      ) : (
        <Filters refetch={refetch} />
      )}
      <AllBooks className={classes.allBooksGap} allBooks={productsList} />
      <div className={classes.paginationWrapper}>
        <CustomPagination
          itemsPerPage={countProducs}
          setOffset={setOffset}
          setLimit={setLimit}
          offset={offset}
        />
      </div>
    </div>
  );
};
export default MyBooks;
