import React from "react";

const PaymentsPayPalIcon = ({ width = 20, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1723 1.8C16.127 0.599991 14.1859 0 11.4979 0H4.03163C3.58391 0 3.13592 0.449993 2.98641 0.899986L0 20.55C0 21 0.298755 21.3 0.597237 21.3H5.22636L6.42108 13.9501V14.2499C6.57032 13.8001 7.01831 13.35 7.46629 13.35H9.70597C14.0366 13.35 17.3215 11.55 18.367 6.6C18.367 6.45001 18.367 6.29998 18.367 6.15001C18.2175 6.15001 18.2175 6.15001 18.367 6.15001C18.5162 4.19999 18.2175 2.99998 17.1723 1.8Z"
        fill="#263B80"
      />
      <path
        d="M18.2174 6.15015C18.2174 6.30014 18.2174 6.45014 18.2174 6.60014C17.1722 11.7001 13.8871 13.3502 9.55668 13.3502H7.31675C6.86876 13.3502 6.42077 13.8002 6.27153 14.2502L4.77832 23.4003C4.77832 23.7002 4.92757 24.0001 5.37556 24.0001H9.25794C9.70593 24.0001 10.1539 23.7002 10.1539 23.2502V23.1003L10.9007 18.4502V18.1502C10.9007 17.7002 11.3486 17.4002 11.7966 17.4002H12.3939C16.127 17.4002 19.1134 15.9001 19.8602 11.4001C20.1589 9.60015 20.0094 7.95015 19.1134 6.90014C18.9642 6.60014 18.6654 6.30014 18.2174 6.15015Z"
        fill="#139AD6"
      />
      <path
        d="M17.1721 5.70012C17.0228 5.70012 16.8733 5.55012 16.7241 5.55012C16.5749 5.55012 16.4253 5.55012 16.2761 5.40009C15.6789 5.25012 15.0816 5.25012 14.3349 5.25012H8.51133C8.36209 5.25012 8.21259 5.25012 8.06335 5.40009C7.7646 5.55012 7.61536 5.85011 7.61536 6.15011L6.4209 13.9501V14.2502C6.57014 13.8001 7.01813 13.3501 7.46612 13.3501H9.70579C14.0364 13.3501 17.3213 11.5501 18.3668 6.6001C18.3668 6.4501 18.3668 6.30011 18.5161 6.15011C18.2173 6.00011 18.0681 5.85011 17.7693 5.85011C17.3213 5.70011 17.3213 5.70012 17.1721 5.70012Z"
        fill="#232C65"
      />
    </svg>
  );
};

export default PaymentsPayPalIcon;
