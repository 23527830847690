import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  btnWrapper: {
    "& .MuiButtonBase-root.MuiButton-root": {
      marginBottom: 16,
    },
    "@media (max-width:1000px)": {
      display: "flex",
      justifyContent: "center",
      "& .MuiButtonBase-root.MuiButton-root": {
        width: "fit-content",
        "&.MuiButtonBase-root.MuiButton-root:not(:last-of-type)": {
          marginRight: 16,
        },
      },
    },
    "@media (max-width:440px)": {
      flexDirection: "column",
      "& .MuiButtonBase-root.MuiButton-root": {
        width: "100%",
        "&.MuiButtonBase-root.MuiButton-root:not(:last-of-type)": {
          marginRight: 0,
        },
      },
    },
  },
  allBooksGap: {
    gap: "64px 69px",
    marginTop: 64,
    "@media (max-width:639px)": {
      gap: "24px 0px",
      marginTop: 0,
    },
  },
}));

export default useStyles;
