import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  Dialog: {
    "& .MuiPaper-root.MuiDialog-paper": {
      margin: "22px 16px",
      padding: "32px 24px",
      maxWidth: 400,
      width: "100%",
      "@media (max-width:639px)": {
        maxWidth: 328,
      },
    },
    "& .MuiTypography-root.MuiDialogTitle-root": {
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: 0,
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 48,
      color: "#5C5C5C",
    },
    "& .MuiButtonBase-root.MuiIconButton-root": {
      padding: 0,
    },
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
}));

export default useStyles;
