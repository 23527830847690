import React, { useMemo } from "react";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import useStyles from "./styles";

const InputUnderline = ({
  label,
  inputType,
  placeholder,
  handleIconClick,
  inputIcon,
  name,
  value,
  onChange,
  onBlur,
  touched,
  error,
}) => {
  const classes = useStyles();

  const inputEndAdornment = useMemo(() => {
    if (inputIcon) {
      return <IconButton onClick={handleIconClick}>{inputIcon}</IconButton>;
    }
    return null;
  }, [inputIcon, handleIconClick]);

  return (
    <div className={classes.inputWrapperUnderline}>
      <div className={classes.label}>{label}</div>
      <Input
        placeholder={placeholder}
        type={inputType}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={Boolean(touched && error)}
        endAdornment={inputEndAdornment}
        className={classes.inputUnderline}
      />
      {touched && error && <div className={classes.error}>{error}</div>}
    </div>
  );
};

export default InputUnderline;
