import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paginationBox: {
    display: "flex",
    width: "100%",
  },
  pagination: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: 773,
    marginLeft: "auto",
    "& .MuiPagination-ul li ": {
      alignSelf: "flex-end",
    },
    "& .MuiPaginationItem-root": {
      margin: "0 4px",
      borderRadius: 5,
      fontWeight: 500,
      fontSize: 16,
      color: " #000000",
    },
    "& .MuiPaginationItem-root.Mui-disabled": {
      opacity: 1,
      background: "none",
    },
    "& .MuiPaginationItem-previousNext, .MuiPaginationItem-firstLast": {
      border: "1px solid #C5D7E0",
      background: "#C5D7E0",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      background: "#0B486B",
      border: "1px solid #0B486B",
      color: "#ffffff",
      "&:hover": {
        background: "#0B486B",
      },
    },
  },
  paginationLimitBox: {
    display: "flex",
    alignItems: "center",
  },
  paginationLimitTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.4)",
    marginRight: 16,
  },
  paginationLimit: {
    maxHeight: 32,
    "& .MuiSelect-select": {
      padding: 0,
      zIndex: 2,
      "&[aria-expanded=true]": {
        "&~ svg": {
          transform: "rotate(180deg)",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      position: "relative",
    },
    "& .MuiInputBase-root svg": {
      position: "absolute",
      right: 0,
      zIndex: 0,
      transform: "rotate(0)",
      transition: ".15s",
    },
  },

  paginationMobileBox: {
    width: "100%",
    padding: "90px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid #0000001A",
    borderBottom: "1px solid #0000001A",
  },
  paginationMobileText: {
    "&.MuiTypography-root": {
      fontWeight: "normal",
      fontSize: 24,
      lineHeight: "28px",
      color: "#303030",
      marginBottom: 24,
    },
  },
  paginationMobileBtn: {
    width: "fit-content",
  },
}));

export default useStyles;
