import React from "react";
import { Formik, Field, Form } from "formik";
import ModalWindow from "../../common/modal-window";
import InputStandart from "../../common/input";
import PartaBtn from "../../common/button";
import { schemaChangeOldPassword, schemaChangePassword } from "./schema";
import * as style from "./style.module.css";
import { useChangePassword, useNewPassword } from "../../graphql";

const ChangePassword = ({
  open,
  handleClose,
  oldPasswordMode = false,
  recoverToken,
}) => {
  const initValues = oldPasswordMode
    ? { oldPassword: "", password: "", confirmPassword: "" }
    : { password: "", confirmPassword: "" };

  const [setNewPassword] = useNewPassword();
  const [changePassword] = useChangePassword();

  const handleSubmit = (values, actions) => {
    if (!oldPasswordMode) {
      setNewPassword({
        variables: {
          passwordResetToken: recoverToken,
          password: values.password,
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.setNewPassword &&
            response.data.setNewPassword.status
          ) {
            handleClose();
          }
        })
        .catch((e) => console.error(e));
    } else {
      changePassword({
        variables: {
          oldPassword: values.oldPassword,
          newPassword: values.password,
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.changePassword &&
            response.data.changePassword.status
          ) {
            handleClose();
          }
        })
        .catch((e) => {
          actions.setErrors({
            oldPassword: e.message,
          });
        });
    }
  };

  return (
    <ModalWindow
      modalTitle="Изменение пароля"
      open={open}
      handleClose={handleClose}
    >
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
        validationSchema={
          oldPasswordMode ? schemaChangeOldPassword : schemaChangePassword
        }
      >
        <Form>
          {oldPasswordMode && (
            <>
              <Field name="oldPassword">
                {({ field, meta }) => {
                  return (
                    <InputStandart
                      inputType="password"
                      placeholder="Старый пароль"
                      touched={meta.touched}
                      error={meta.error}
                      {...field}
                    />
                  );
                }}
              </Field>
              <hr className={style.hrFormsPassword} />
            </>
          )}
          <Field name="password">
            {({ field, meta }) => {
              return (
                <InputStandart
                  inputType="password"
                  placeholder="Новый пароль"
                  touched={meta.touched}
                  error={meta.error}
                  {...field}
                />
              );
            }}
          </Field>
          <Field name="confirmPassword">
            {({ field, meta }) => {
              return (
                <InputStandart
                  inputType="password"
                  placeholder="Подтвердите пароль"
                  touched={meta.touched}
                  error={meta.error}
                  {...field}
                />
              );
            }}
          </Field>

          <div className={style.alignCenter}>
            <PartaBtn
              variant="contained"
              buttonText="Изменить пароль"
              type="submit"
              fullWidth={false}
            />
          </div>
        </Form>
      </Formik>
    </ModalWindow>
  );
};

export default ChangePassword;
