import React from "react";
import Box from "@mui/material/Box";

import useBreakpoint from "use-breakpoint";
import * as style from "./style.module.css";
import SliderSection from "./SliderSection";
import ProductCard from "../ProductCard/ProductCard";
import useStyles from "./styles";
import { BREAKPOINTS } from "../../constants";
import ProductCardSecondary from "../ProductCardSecondary/ProductCardSecondary";
import AdditionalCards from "./AdditionalCards";

const GridSection = ({
  mainCard,
  slides,
  additionalCards,
  sectionTitle,
  reverse,
  sliderSettingsAutoplay,
}) => {
  const classes = useStyles({ reverse });
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isDesktop = ["tablet", "desktop"].includes(breakpoint);
  return (
    <Box className={style.section}>
      <Box className={style.sectionHead}>
        <Box className={style.sectionTitle}>{sectionTitle}</Box>
      </Box>

      <Box className={classes.sectionGrid}>
        {isDesktop ? (
          <Box className={style.mainCard}>
            <ProductCard product={mainCard} />
          </Box>
        ) : (
          <Box className={style.mainCardWrap}>
            <Box className={style.mainCard}>
              <ProductCardSecondary
                product={mainCard}
                widthImg={517}
                maxWidthImg="100%"
              />
            </Box>
          </Box>
        )}

        <Box className={style.additional}>
          <AdditionalCards additionalCards={additionalCards} />
          {isDesktop && (
            <Box className={style.slider}>
              <SliderSection
                slides={slides}
                sliderSettingsAutoplay={sliderSettingsAutoplay}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GridSection;
