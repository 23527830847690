import React from "react";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import Router from "./components/router";
import Layout from "./layout";
import { client } from "./graphql";
import ChangePasswordService from "./components/forms/ChangePasswordService";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
      <ToastContainer />
      <ApolloProvider client={client}>
        <BrowserRouter>
          <CssBaseline />
          <Layout>
            <Router />
            <ChangePasswordService />
          </Layout>
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
};

export default App;
