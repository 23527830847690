import { Button, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import useStyles from "./styles";

const PartaBtn = ({
  className,
  buttonText,
  variant,
  disabled,
  fullWidth = true,
  endIcon,
  startIcon,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.button, className)}
      disabled={disabled}
      variant={variant}
      startIcon={startIcon}
      fullWidth={fullWidth}
      endIcon={endIcon}
      {...rest}
    >
      <Typography className={classes.buttonText}>{buttonText}</Typography>
    </Button>
  );
};

export default PartaBtn;
