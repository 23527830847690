import { gql } from "@apollo/client";

export const getTextbooksGQL = gql`
  query getTextbooks($options: optionsGettingTextbooks) {
    getTextbooks(options: $options) {
      count
      textbooks {
        id
        title
        authors
        issueYear
        subject {
          id
          name
          alias
        }
        creatorId
        subjectId
        cover
        price
      }
    }
  }
`;

export const getTextBookByIdGQL = gql`
  query getTextbookById($textbookId: ID!) {
    getTextbookById(textbookId: $textbookId) {
      id
      title
      subject {
        id
        name
        alias
      }
      authors
      issueYear
      creatorId
      subjectId
      cover
      audioDuration
      price
    }
  }
`;

export const getSelectingsListGQL = gql`
  query {
    selectingsList {
      subjects {
        id
        name
        alias
      }
      years
    }
  }
`;

export const signInGQL = gql`
  query signIn($email: String!, $password: String!, $rememberMe: Boolean) {
    signIn(email: $email, password: $password, rememberMe: $rememberMe) {
      token
      user {
        id
        email
        fullName
      }
    }
  }
`;

export const getNewsGQL = gql`
  query getNews($limit: Int!, $offset: Int!, $sort: NewsPageSort) {
    getNews(limit: $limit, offset: $offset, sort: $sort) {
      news {
        id
        title
        description
        content
        image
        imageAlt
        visible
        viewsCount
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const getPopularNewsGQL = gql`
  query getPopularNews($limit: Int!) {
    getPopularNews(limit: $limit) {
      id
      title
      description
      content
      image
      imageAlt
      visible
      viewsCount
      createdAt
      updatedAt
    }
  }
`;

export const currentUserGQL = gql`
  query currentUser {
    currentUser {
      id
      email
      fullName
      phone
      dateOfBirth
      universityId
      university {
        name
        icon
      }
    }
  }
`;

export const getAllUniversityGQL = gql`
  query getAllUniversity {
    getAllUniversity {
      id
      name
      icon
    }
  }
`;

export const getViewedUserBookGQL = gql`
  query getViewedUserBook {
    getViewedUserBook {
      id
      title
      authors
      issueYear
      subject {
        id
        name
        alias
      }
      creatorId
      subjectId
      cover
      dateCreate
      price
      audioDuration
      description
    }
  }
`;

export const getUserBooksGQL = gql`
  query {
    getUserBooks(options: { limit: null, offset: null }) {
      count
      textbooks {
        purchaseDate
        purchasePrice
        textbook {
          id
          title
          subject {
            id
            name
            alias
          }
          authors
          issueYear
          cover
        }
      }
    }
  }
`;

export const getUserFavoritesGQL = gql`
  query getUserFavorites($options: getUserBooks) {
    getUserFavorites(options: $options) {
      textbooks {
        id
        title
        authors
        issueYear
        subject {
          id
          name
          alias
        }
        creatorId
        subjectId
        cover
        status
        dateCreate
        price
        audioDuration
        description
      }
      count
    }
  }
`;

export const getSelectionGQL = gql`
  query getSelection($page: SelectionPages) {
    getSelection(page: $page) {
      id
      title
      style
      page
      mainTextbookId
      showingStatus
      sliderAutoScroling
      order
      slider {
        alt
        url
      }
      textbooks {
        id
        title
        authors
        issueYear
        subject {
          name
          alias
        }
        creatorId
        subjectId
        cover
        price
      }
    }
  }
`;

export const getTextBookSimilarGQL = gql`
  query getTextBookSimilar($textbookId: ID!) {
    getTextBookSimilar(textbookId: $textbookId) {
      id
      title
      authors
      issueYear
      subject {
        name
        alias
      }
      cover
    }
  }
`;

export const getNewsByIdGQL = gql`
  query getNewsById($id: Int!) {
    getNewsById(id: $id) {
      title
      description
      content
      image
      imageAlt
      createdAt
      metaDescription
      metaKeywords
    }
  }
`;

export const getLastPublicateBookGQL = gql`
  query getLastPublicateBook {
    getLastPublicateBook {
      id
      title
      authors
      issueYear
      subject {
        name
        alias
      }
      cover
      publicationDate
    }
  }
`;

export const getAllStaticVisiblePagesGQL = gql`
  query getAllStaticVisiblePages {
    getAllStaticVisiblePages {
      id
      alias
      title
    }
  }
`;

export const getAllStaticPagesGQL = gql`
  query getAllStaticPages($limit: Int, $offset: Int, $sort: StaticPageSort) {
    getAllStaticPages(limit: $limit, offset: $offset, sort: $sort) {
      pages {
        id
        title
        alias
      }
      count
    }
  }
`;

export const getStaticPageByAliasGQL = gql`
  query getStaticPageByAlias($alias: String!) {
    getStaticPageByAlias(alias: $alias) {
      id
      title
      alias
      content
      metaDescription
      metaKeywords
    }
  }
`;
