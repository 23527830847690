import React from "react";

const FluentSave20Filled = ({ color = "#0B486B", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.59998 6.00001C3.59998 5.36349 3.85283 4.75304 4.30292 4.30295C4.75301 3.85286 5.36346 3.60001 5.99998 3.60001H7.19998V7.80001C7.19998 8.2774 7.38962 8.73523 7.72718 9.0728C8.06475 9.41036 8.52259 9.60001 8.99998 9.60001H13.8C14.2774 9.60001 14.7352 9.41036 15.0728 9.0728C15.4103 8.73523 15.6 8.2774 15.6 7.80001V3.60001H16.0548C16.6912 3.60014 17.3016 3.85309 17.7516 4.30321L19.6968 6.24841C20.1469 6.69839 20.3998 7.30874 20.4 7.94521V18C20.4 18.6365 20.1471 19.247 19.697 19.6971C19.2469 20.1472 18.6365 20.4 18 20.4V13.8C18 13.3226 17.8103 12.8648 17.4728 12.5272C17.1352 12.1896 16.6774 12 16.2 12H7.79998C7.32259 12 6.86475 12.1896 6.52718 12.5272C6.18962 12.8648 5.99998 13.3226 5.99998 13.8V20.4C5.36346 20.4 4.75301 20.1472 4.30292 19.6971C3.85283 19.247 3.59998 18.6365 3.59998 18V6.00001Z"
        fill={color}
      />
      <path
        d="M14.4 3.60001H8.40002V7.80001C8.40002 7.95914 8.46324 8.11175 8.57576 8.22427C8.68828 8.33679 8.84089 8.40001 9.00002 8.40001H13.8C13.9592 8.40001 14.1118 8.33679 14.2243 8.22427C14.3368 8.11175 14.4 7.95914 14.4 7.80001V3.60001Z"
        fill={color}
      />
      <path
        d="M16.7999 13.8V20.4H7.19995V13.8C7.19995 13.6409 7.26317 13.4883 7.37569 13.3757C7.48821 13.2632 7.64082 13.2 7.79995 13.2H16.1999C16.3591 13.2 16.5117 13.2632 16.6242 13.3757C16.7367 13.4883 16.7999 13.6409 16.7999 13.8Z"
        fill={color}
      />
    </svg>
  );
};

export default FluentSave20Filled;
