import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  menuUnauthorized: {
    "& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
      top: "57px !important",
      width: 174,
      boxShadow: "0px 1px 20px 0px #32474E40 ",
      padding: "10px 0px",
    },
    "& .MuiList-root.MuiMenu-list": {
      padding: 0,
    },
    "& .MuiButtonBase-root.MuiMenuItem-root": {
      fontSize: 14,
      lineHeight: "16px",
      color: "#0B486B",
      padding: "13px 33px",
    },
    "& .MuiButtonBase-root.MuiMenuItem-root svg": {
      marginRight: 12,
    },
  },
  menuAuthorized: {
    "& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
      top: "57px !important",
      width: 181,
      boxShadow: "0px 1px 20px 0px #32474E40 ",
      padding: "10px 0px",
    },
    "& .MuiList-root.MuiMenu-list": {
      padding: 0,
    },
    "& .MuiButtonBase-root.MuiMenuItem-root": {
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: 0,
      color: "#00000066",
      whiteSpace: "pre-wrap",
      padding: "13px 16px",
    },

    "& .MuiButtonBase-root.MuiMenuItem-root a": {
      color: "inherit",
      textDecoration: "none",
    },
    "& .MuiButtonBase-root.MuiMenuItem-root:first-child": {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiButtonBase-root.MuiMenuItem-root:last-child": {
      lineHeight: "16px",
      color: "#0B486B",
    },
    "& .MuiButtonBase-root.MuiMenuItem-root svg": {
      marginRight: 17,
    },
  },
  userBtn: {
    "&.MuiButtonBase-root.MuiButton-root": {
      padding: 0,
      minWidth: "unset",
      zIndex: 1,
      "@media (max-width: 999.9px)": {
        marginRight: -17,
      },
      "@media (min-width: 1000px)": {
        marginRight: -25,
      },
    },
  },
}));

export default useStyles;
