import React from "react";

const FavoriteFill = ({ color = "#000000", width = 14, height = 12 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.35 20.13C12.59 20.82 11.42 20.82 10.66 20.12L10.55 20.02C5.30003 15.27 1.87003 12.16 2.00003 8.28001C2.06003 6.58001 2.93003 4.95001 4.34003 3.99001C6.98003 2.19001 10.24 3.03001 12 5.09001C13.76 3.03001 17.02 2.18001 19.66 3.99001C21.07 4.95001 21.94 6.58001 22 8.28001C22.14 12.16 18.7 15.27 13.45 20.04L13.35 20.13Z"
        fill={color}
      />
    </svg>
  );
};

export default FavoriteFill;
