import React from "react";

const FaSolidEye = ({ color = "#0B486B", width = 22, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8671 7.39167C19.7958 2.98292 15.6953 0 11 0C6.30475 0 2.20304 2.985 0.132896 7.39208C0.045523 7.58063 0 7.78894 0 8.00021C0 8.21148 0.045523 8.41979 0.132896 8.60833C2.20418 13.0171 6.30475 16 11 16C15.6953 16 19.797 13.015 21.8671 8.60792C21.9545 8.41937 22 8.21106 22 7.99979C22 7.78852 21.9545 7.58021 21.8671 7.39167ZM11 14C9.9122 14 8.84883 13.6481 7.94436 12.9888C7.03989 12.3295 6.33494 11.3925 5.91865 10.2961C5.50237 9.19974 5.39345 7.99334 5.60567 6.82946C5.81789 5.66557 6.34172 4.59647 7.11091 3.75736C7.8801 2.91824 8.8601 2.3468 9.927 2.11529C10.9939 1.88378 12.0998 2.0026 13.1048 2.45672C14.1098 2.91085 14.9687 3.67988 15.5731 4.66658C16.1774 5.65327 16.5 6.81331 16.5 8C16.5004 8.78804 16.3583 9.56843 16.0821 10.2966C15.8058 11.0247 15.4007 11.6863 14.8899 12.2435C14.3791 12.8007 13.7726 13.2427 13.1052 13.5441C12.4377 13.8455 11.7224 14.0004 11 14ZM11 4C10.6727 4.00499 10.3476 4.05811 10.0333 4.15792C10.2923 4.54195 10.4166 5.01454 10.3837 5.48998C10.3507 5.96542 10.1626 6.41223 9.85359 6.74938C9.54454 7.08652 9.13496 7.29168 8.69914 7.32765C8.26332 7.36362 7.83011 7.22801 7.47808 6.94542C7.27763 7.75108 7.31381 8.60503 7.58155 9.38708C7.84928 10.1691 8.33508 10.8399 8.97058 11.305C9.60607 11.7701 10.3593 12.006 11.1241 11.9797C11.889 11.9533 12.627 11.666 13.2343 11.158C13.8417 10.6501 14.2877 9.94726 14.5096 9.14833C14.7316 8.3494 14.7183 7.49466 14.4716 6.70441C14.2249 5.91415 13.7573 5.22818 13.1346 4.74305C12.5118 4.25791 11.7652 3.99803 11 4Z"
        fill={color}
      />
    </svg>
  );
};

export default FaSolidEye;
