import React from "react";

const CiFilterOff = ({ color = "#C5D7E0", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.414 0.414001L7 1.828L8.172 3H4C3.447 3 3 3.442 3 3.99V6.546C3 7.071 3.211 7.575 3.586 7.946L9.707 14C9.79974 14.0915 9.8734 14.2005 9.92369 14.3206C9.97399 14.4408 9.99993 14.5697 10 14.7V21.01C10 21.745 10.782 22.223 11.447 21.894L13.447 20.905C13.6126 20.8238 13.7521 20.6979 13.8499 20.5415C13.9476 20.3851 13.9996 20.2044 14 20.02V14.7C14 14.438 14.105 14.186 14.293 14L16.746 11.574L19.728 14.556L21.142 13.142L8.414 0.414001Z"
        fill={color}
      />
      <path
        d="M20.414 7.946L19.59 8.761L13.828 3H20C20.553 3 21 3.442 21 3.99V6.546C21 7.071 20.789 7.575 20.414 7.946Z"
        fill={color}
      />
    </svg>
  );
};

export default CiFilterOff;
