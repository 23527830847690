import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import NotificationIcon from "../../assets/icons/NotificationIcon";
import * as style from "./style.module.css";
import useStyles from "./styles";
import CloseIcon from "../../assets/icons/CloseIcon";
import BxBxsChevronRight from "../../assets/icons/BxBxsChevronRight";
import { useGetLastPublicateBook } from "../../graphql";

const Notification = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [lastPublicateBook, setLastPublicateBook] = useState(null);
  const [isActiveIcon, setIsActiveIcon] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
    setIsActiveIcon(false);
  };

  const { data } = useGetLastPublicateBook();

  useEffect(() => {
    if (data && data.getLastPublicateBook) {
      setLastPublicateBook(data.getLastPublicateBook);
    }
  }, [data && data.getLastPublicateBook]);

  const toggleActiveIcon = useCallback(() => {
    setIsActiveIcon(true);
  }, [lastPublicateBook]);

  useEffect(() => {
    toggleActiveIcon();
  }, []);

  return (
    lastPublicateBook && (
      <Box className={style.notification}>
        <Tooltip
          open={expanded}
          classes={{ popper: classes.tooltipDropdown }}
          placement="bottom-end"
          title={
            <Box className={style.dropdown}>
              <IconButton
                className={style.dropdownCloseBtn}
                aria-label="close"
                onClick={handleExpandClick}
              >
                <CloseIcon width="12" height="12" />
              </IconButton>
              <Box className={style.dropdownTitle}>Новинка каталога</Box>
              <Box className={style.dropdownContent}>
                <Box className={style.dropdownItem}>
                  <Link
                    className={style.dropdownItemArrowLink}
                    to={`/all-books/book/${lastPublicateBook.id}`}
                    onClick={handleExpandClick}
                  >
                    <IconButton>
                      <BxBxsChevronRight width="16" height="16" />
                    </IconButton>
                  </Link>
                  <Box className={style.dropdownItemImg}>
                    <Link
                      className={style.dropdownInfoHeadName}
                      to={`/all-books/book/${lastPublicateBook.id}`}
                      onClick={handleExpandClick}
                    >
                      <img
                        src={`${process.env.REACT_APP_URL}${lastPublicateBook.cover}`}
                        alt={lastPublicateBook.title}
                      />
                    </Link>
                  </Box>
                  <Box className={style.dropdownInfo}>
                    <Box className={style.dropdownInfoHead}>
                      <Link
                        className={style.dropdownInfoHeadName}
                        to={`/all-books/book/${lastPublicateBook.id}`}
                        onClick={handleExpandClick}
                      >
                        {lastPublicateBook.title}
                      </Link>
                      {lastPublicateBook?.publicationDate && (
                        <Box className={style.dropdownInfoHeadDate}>
                          {new Intl.DateTimeFormat("ru-RU").format(
                            lastPublicateBook.publicationDate
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box className={style.dropdownInfoRows}>
                      <Box className={style.dropdownInfoRow}>
                        <Box className={style.dropdownInfoRowName}>Автор</Box>
                        <Box className={style.dropdownInfoRowVal}>
                          {lastPublicateBook.authors}
                        </Box>
                      </Box>
                    </Box>
                    <Box className={style.dropdownInfoRows}>
                      <Box className={style.dropdownInfoRow}>
                        <Box className={style.dropdownInfoRowName}>Предмет</Box>
                        <Box className={style.dropdownInfoRowVal}>
                          {lastPublicateBook?.subject?.name}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        >
          <Box className={style.notification} onClick={handleExpandClick}>
            <NotificationIcon color={isActiveIcon ? "#ffffff" : "#C5D7E0"} />
          </Box>
        </Tooltip>
      </Box>
    )
  );
};

export default Notification;
