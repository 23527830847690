import React from "react";
import Box from "@mui/material/Box";

import useBreakpoint from "use-breakpoint";
import * as style from "./style.module.css";
import ProductCard from "../ProductCard/ProductCard";
import { BREAKPOINTS } from "../../constants";
import ProductCardSecondary from "../ProductCardSecondary/ProductCardSecondary";
import ScrollbarWrap from "./ScrollbarWrap";

const AdditionalCards = ({ additionalCards }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isDesktop = ["tablet", "desktop"].includes(breakpoint);

  if (isDesktop && additionalCards) {
    return (
      <Box className={style.additionalCards}>
        {additionalCards.map((product) => {
          return (
            <Box key={product.id} className={style.smallCard}>
              <ProductCard product={product} />
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <ScrollbarWrap>
      {additionalCards &&
        additionalCards.map((product) => {
          return (
            <Box key={product.id} className={style.smallCard}>
              <ProductCardSecondary
                product={product}
                heightImg={144}
                widthImg={130}
              />
            </Box>
          );
        })}
    </ScrollbarWrap>
  );
};

export default AdditionalCards;
