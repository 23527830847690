import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import * as style from "./style.module.css";
import useStyles from "./styles";
import NoImg from "../../assets/icons/NoImg";
import PartaBtn from "../../common/button";
import BookmarkIcon from "../../assets/icons/BookmarkIcon";

const ProductCardSecondary = ({
  product: productMetaData,
  heightImg,
  widthImg,
  maxWidthImg,
}) => {
  const classes = useStyles({ heightImg, widthImg, maxWidthImg });

  const product = productMetaData?.textbook || productMetaData;

  const location = useLocation();

  return (
    <Box className={style.card}>
      <Link
        to={`/all-books/${product.subject.alias}/${product.id}`}
        className={style.cardImgLink}
      >
        {product.cover ? (
          <img
            className={classes.cardImg}
            src={`${process.env.REACT_APP_URL}${product.cover}`}
            alt={product.title}
          />
        ) : (
          <NoImg />
        )}
      </Link>
      <Box className={style.cardContent}>
        <Box className={style.dropdownInfo}>
          <Box className={style.dropdownRow}>
            <Link
              to={`/all-books/${product.subject.alias}/${product.id}`}
              className={style.cardLink}
            >
              <Typography className={style.dropdownRowTitle}>
                {product.title}
              </Typography>
            </Link>
          </Box>
          <Box className={style.dropdownRow}>
            <Typography className={style.dropdownRowVal}>
              {product.authors}
            </Typography>
            <Typography className={style.dropdownRowYear}>
              {product.issueYear}
            </Typography>
          </Box>

          {`${location.pathname}${location.search}` === "/account?myBooks" && (
            <Box className={style.btnContainer}>
              <Box
                onClick={() => {
                  document.cookie = `token_catalog=${
                    localStorage.getItem("token_catalog") ||
                    sessionStorage.getItem("token_catalog")
                  }; domain=parta.demo.malevich.com.ua; path=/; `;
                  window.location = `${process.env.REACT_APP_READER_URL}${product.id}`;
                }}
              >
                <PartaBtn
                  variant="contained"
                  buttonText="Читать"
                  startIcon={<BookmarkIcon color="#fff" />}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductCardSecondary;
