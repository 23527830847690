import React from "react";

const AntDesignUserOutlined = ({
  color = "#0B486B",
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.121 17.8969C19.6789 16.8496 19.0373 15.8984 18.232 15.0961C17.4291 14.2915 16.478 13.65 15.4312 13.207C15.4218 13.2023 15.4124 13.2 15.4031 13.1953C16.8632 12.1406 17.8124 10.4227 17.8124 8.48438C17.8124 5.27344 15.2109 2.67188 11.9999 2.67188C8.789 2.67188 6.18744 5.27344 6.18744 8.48438C6.18744 10.4227 7.13666 12.1406 8.59681 13.1977C8.58744 13.2023 8.57806 13.2047 8.56869 13.2094C7.51869 13.6523 6.5765 14.2875 5.76791 15.0984C4.96332 15.9013 4.32182 16.8524 3.87884 17.8992C3.44366 18.924 3.20896 20.0228 3.18744 21.1359C3.18681 21.161 3.1912 21.1858 3.20034 21.2091C3.20948 21.2324 3.2232 21.2537 3.24067 21.2716C3.25814 21.2895 3.27902 21.3037 3.30208 21.3134C3.32514 21.3231 3.34991 21.3281 3.37494 21.3281H4.78119C4.88431 21.3281 4.96634 21.2461 4.96869 21.1453C5.01556 19.3359 5.74212 17.6414 7.0265 16.357C8.35541 15.0281 10.1202 14.2969 11.9999 14.2969C13.8796 14.2969 15.6445 15.0281 16.9734 16.357C18.2578 17.6414 18.9843 19.3359 19.0312 21.1453C19.0335 21.2484 19.1156 21.3281 19.2187 21.3281H20.6249C20.65 21.3281 20.6747 21.3231 20.6978 21.3134C20.7208 21.3037 20.7417 21.2895 20.7592 21.2716C20.7767 21.2537 20.7904 21.2324 20.7995 21.2091C20.8087 21.1858 20.8131 21.161 20.8124 21.1359C20.789 20.0156 20.557 18.9258 20.121 17.8969ZM11.9999 12.5156C10.9242 12.5156 9.91166 12.0961 9.14994 11.3344C8.38822 10.5727 7.96869 9.56016 7.96869 8.48438C7.96869 7.40859 8.38822 6.39609 9.14994 5.63437C9.91166 4.87266 10.9242 4.45312 11.9999 4.45312C13.0757 4.45312 14.0882 4.87266 14.8499 5.63437C15.6117 6.39609 16.0312 7.40859 16.0312 8.48438C16.0312 9.56016 15.6117 10.5727 14.8499 11.3344C14.0882 12.0961 13.0757 12.5156 11.9999 12.5156Z"
        fill={color}
      />
    </svg>
  );
};

export default AntDesignUserOutlined;
