import React from "react";

const CibMinutemailer = ({ color = "#4D6E7D", width = 64, height = 64 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.148 32.854L17.7 30.76L14.406 31.802L14.062 31.322L15.428 30L13.646 30.718C6.83201 33.364 2.00001 39.958 2.00001 47.688C1.99754 50.1578 2.50094 52.6019 3.4792 54.8697C4.45745 57.1375 5.88982 59.1809 7.68801 60.874C6.13306 58.1478 5.31746 55.0625 5.32201 51.924C5.32201 44.29 10.156 37.486 16.832 34.81L17.678 32.77L15.678 33.644L15.116 32.884L15.148 32.854Z"
        fill={color}
      />
      <path
        d="M51.3206 9.46287L56.4971 22.6492C57.025 23.9936 57.0184 25.4891 56.4787 26.8288C55.939 28.1685 54.9071 29.2509 53.5947 29.8539L53.2998 29.979L31.436 38.562C30.0916 39.0899 28.5961 39.0833 27.2564 38.5436C25.9167 38.0039 24.8343 36.972 24.2313 35.6596L24.1062 35.3648L18.9297 22.1784L37.776 24.2303C37.9857 24.2531 38.1977 24.2248 38.3941 24.1477C38.5904 24.0707 38.7651 23.9472 38.9032 23.7878L51.3206 9.46287ZM21.2635 12.6493L43.1274 4.06628C44.432 3.55395 45.8804 3.5444 47.1917 4.03949C48.5029 4.53457 49.5836 5.499 50.224 6.74573L37.3796 21.5636L17.8849 19.4409C17.5211 18.1451 17.6315 16.762 18.1961 15.5403C18.7608 14.3185 19.7428 13.3382 20.9655 12.7756L21.2635 12.6493L43.1274 4.06628L21.2635 12.6493Z"
        fill={color}
      />
    </svg>
  );
};

export default CibMinutemailer;
