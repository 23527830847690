import React from "react";

const VkIcon = ({ color = "#C5D7E0", width = 21, height = 12 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5788 0.854828C19.7188 0.389828 19.5788 0.0488281 18.9168 0.0488281H16.7238C16.1658 0.0488281 15.9108 0.343828 15.7708 0.667828C15.7708 0.667828 14.6558 3.38683 13.0758 5.14983C12.5658 5.66283 12.3328 5.82483 12.0548 5.82483C11.9158 5.82483 11.7138 5.66283 11.7138 5.19783V0.854828C11.7138 0.296828 11.5528 0.0488281 11.0878 0.0488281H7.64176C7.29376 0.0488281 7.08375 0.306828 7.08375 0.552828C7.08375 1.08083 7.87375 1.20283 7.95475 2.69083V5.91883C7.95475 6.62583 7.82776 6.75483 7.54776 6.75483C6.80476 6.75483 4.99676 4.02583 3.92376 0.901828C3.71476 0.294828 3.50376 0.0498285 2.94376 0.0498285H0.751756C0.124756 0.0498285 -0.000244141 0.344828 -0.000244141 0.668828C-0.000244141 1.25083 0.742756 4.13083 3.46076 7.93983C5.27276 10.5408 7.82375 11.9508 10.1478 11.9508C11.5408 11.9508 11.7128 11.6378 11.7128 11.0978V9.13183C11.7128 8.50583 11.8458 8.37983 12.2868 8.37983C12.6108 8.37983 13.1688 8.54383 14.4698 9.79683C15.9558 11.2828 16.2018 11.9498 17.0368 11.9498H19.2288C19.8548 11.9498 20.1678 11.6368 19.9878 11.0188C19.7908 10.4038 19.0808 9.50883 18.1388 8.44983C17.6268 7.84583 16.8618 7.19583 16.6288 6.87083C16.3038 6.45183 16.3978 6.26683 16.6288 5.89483C16.6298 5.89583 19.3008 2.13383 19.5788 0.854828Z"
        fill={color}
      />
    </svg>
  );
};

export default VkIcon;
