import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  sliderWrap: {
    maxHeight: "100%",
    height: "100%",
    "& .slick-list, .slick-track": {
      maxHeight: "100%",
      height: "100%",
    },
    "& .slick-slide .slide": {
      backgroundSize: "cover",
    },
    "& .slick-slide div": {
      maxHeight: "100%",
      height: "100%",
    },
  },
  dots: {
    bottom: -36,
    "& li.slick-active button::before": {
      color: "#0B486B",
      fontSize: 16,
      opacity: 1,
    },
    "& li": {
      margin: "0 8px",
      "& button::before": {
        opacity: 1,
        color: "#D6DDE0",
        fontSize: 16,
      },
    },
  },
  sectionGrid: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",

    "@media (min-width:1000px)": {
      justifyContent: "space-between",
      flexDirection: (props) => {
        return props.reverse ? "row-reverse" : "row";
      },
      alignItems: "inherit",
    },
  },
}));

export default useStyles;
