import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import uniqid from "uniqid";
import useStyles from "./styles";

const SliderSection = ({ slides, sliderSettingsAutoplay }) => {
  const classes = useStyles();

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: sliderSettingsAutoplay,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: `slick-dots ${classes.dots}`,
  };
  return (
    <Slider className={classes.sliderWrap} {...settings}>
      {slides.length &&
        slides.map((slide) => {
          return (
            <Box
              className="slide"
              key={uniqid()}
              sx={{ backgroundImage: `url(${slide.url})` }}
            />
          );
        })}
    </Slider>
  );
};

export default SliderSection;
