import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MetaTags from "react-meta-tags";
import NavigationMenu from "../../components/nav-menu";
import { useGetStaticPageByAlias } from "../../graphql";
import PreLoader from "../../UI/PreLoader/PreLoader";
import * as style from "./style.module.css";

const StaticPage = () => {
  const location = useLocation();

  const [pageData, setPageData] = useState(null);

  const { data, loading } = useGetStaticPageByAlias({
    variables: {
      alias: location.pathname.slice(1),
    },
  });

  useEffect(() => {
    if (data?.getStaticPageByAlias) {
      setPageData(data?.getStaticPageByAlias);
    }
  }, [data?.getStaticPageByAlias]);

  const breadcrumbNameMap = {
    [`${location.pathname}`]: pageData?.title,
  };

  if (loading) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <div>
      <MetaTags>
        <title>{pageData?.title}</title>
        <meta name="description" content={pageData?.metaDescription} />
        <meta name="keywords" content={pageData?.metaKeywords} />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <div className="page-title">{pageData?.title}</div>
      <div className={style.content}>{pageData?.content}</div>
    </div>
  );
};
export default StaticPage;
