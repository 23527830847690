import React from "react";

const NotificationIcon = ({ color = "#000000", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.04204 19.003H14.958C14.8398 19.702 14.4779 20.3365 13.9364 20.7941C13.395 21.2517 12.709 21.5028 12 21.5028C11.2911 21.5028 10.6051 21.2517 10.0636 20.7941C9.52218 20.3365 9.16025 19.702 9.04204 19.003ZM12 2.00299C13.9892 2.00299 15.8968 2.79317 17.3033 4.19969C18.7099 5.60621 19.5 7.51387 19.5 9.50299V13.503L20.918 16.663C20.9825 16.8075 21.0099 16.9659 20.9976 17.1236C20.9853 17.2814 20.9338 17.4336 20.8478 17.5665C20.7618 17.6993 20.6439 17.8085 20.5049 17.8842C20.366 17.9599 20.2103 17.9997 20.052 18H3.95204C3.79358 18 3.63763 17.9603 3.49841 17.8846C3.35919 17.8089 3.24112 17.6996 3.15494 17.5666C3.06877 17.4336 3.01723 17.2812 3.00502 17.1232C2.99281 16.9652 3.02032 16.8066 3.08504 16.662L4.50004 13.502V9.48999L4.50504 9.23999C4.57345 7.29784 5.39304 5.45807 6.79114 4.10828C8.18924 2.75849 10.0567 2.00408 12 2.00399V2.00299Z"
        fill={color}
      />
    </svg>
  );
};

export default NotificationIcon;
