import React, { useRef } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import UserOutline from "../../assets/icons/ant-design_user-outlined";
import PlusIcon from "../../assets/icons/carbon_add-alt";
import useStyles from "./styles";
import * as style from "./style.module.css";

const UnauthorizedUser = ({
  openMenu,
  handleToggleMenu,
  anchorEl,
  setOpenLogin,
  setOpenRegistration,
}) => {
  const classes = useStyles();

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
    if (anchorEl) handleToggleMenu();
  };

  const handleOpenRegistration = () => {
    setOpenRegistration(true);
    if (anchorEl) handleToggleMenu();
  };
  const containerRef = useRef();

  return (
    <Box ref={containerRef}>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleToggleMenu}
        className={classes.menuUnauthorized}
        container={containerRef.current}
      >
        <MenuItem onClick={handleClickOpenLogin}>
          <UserOutline />
          Вход
        </MenuItem>
        <MenuItem onClick={handleOpenRegistration}>
          <PlusIcon className={style.plusSvg} />
          Регистрация
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UnauthorizedUser;
