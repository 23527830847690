import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetNews } from "../../graphql";
import BorderWrapper from "../../UI/BorderWrapper/BorderWrapper";
import { dateBuildFormatter } from "../../utils/dateBuildFormatter";
import useStyles from "./styles";

const AllArticles = () => {
  const classes = useStyles();
  const [newsData, setNewsData] = useState(null);

  const { data } = useGetNews({
    variables: {
      limit: 7,
      offset: 0,
      sort: {
        field: "CREATED_AT",
        flow: "DESC",
      },
    },
  });

  useEffect(() => {
    if (data && data.getNews) {
      setNewsData(data.getNews);
    }
  }, [data && data.getNews]);

  return (
    <BorderWrapper
      title="Статьи"
      linkVisible
      linkName="Все статьи"
      linkUrl="/news"
      marginBtn={classes.marginBtn}
    >
      <div className={classes.articlesHeight}>
        {newsData &&
          !!newsData.news.length &&
          newsData.news.map((article) => {
            return (
              <div key={article.id} className={classes.articleWrapper}>
                <Link to={`/news/${article.id}`} className={classes.link}>
                  <div className={classes.articleTitle}>{article.title}</div>
                </Link>
                <div className={classes.articleDate}>
                  {dateBuildFormatter(article.createdAt)}
                </div>
              </div>
            );
          })}
      </div>
    </BorderWrapper>
  );
};

export default AllArticles;
