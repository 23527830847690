import React from "react";

const PaymentsVisaIcon = ({ width = 61, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 61 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5477 6.50292C31.5129 9.26662 33.9932 10.809 35.8616 11.7259C37.7812 12.6668 38.4259 13.2701 38.4186 14.1114C38.4038 15.3992 36.8871 15.9674 35.4677 15.9896C32.9912 16.0283 31.5513 15.3162 30.4066 14.7774L29.5145 18.9821C30.663 19.5152 32.7896 19.9802 34.9949 20.0005C40.1716 20.0005 43.5583 17.4268 43.5766 13.4362C43.5969 8.37183 36.6217 8.09141 36.6693 5.82768C36.6858 5.14135 37.3361 4.40892 38.761 4.22259C39.4663 4.12848 41.4133 4.05653 43.6207 5.08047L44.4871 1.01239C43.3 0.576986 41.7743 0.160034 39.8747 0.160034C35.0023 0.160034 31.5751 2.76876 31.5477 6.50292ZM52.8122 0.510578C51.8669 0.510578 51.0703 1.06589 50.7148 1.91825L43.3202 19.7016H48.4931L49.5225 16.8364H55.8436L56.441 19.7016H61L57.0214 0.510578H52.8122ZM53.5358 5.69484L55.0287 12.9012H50.9402L53.5358 5.69484ZM25.2759 0.510578L21.1985 19.7016H26.1278L30.2033 0.510578H25.2759ZM17.9838 0.510578L12.8532 13.5727L10.7778 2.46619C10.5342 1.2264 9.57257 0.510578 8.50469 0.510578H0.11723L0 1.06774C1.72182 1.44411 3.67811 2.05109 4.86322 2.70051C5.58858 3.09718 5.79557 3.44403 6.03369 4.38677L9.96456 19.7016H15.174L23.1603 0.510578H17.9838Z"
        fill="url(#paint0_linear_109_5289)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_109_5289"
          x1="28.0443"
          y1="20.3985"
          x2="28.6227"
          y2="0.0222926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#222357" />
          <stop offset="1" stopColor="#254AA5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PaymentsVisaIcon;
