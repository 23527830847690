import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MetaTags from "react-meta-tags";
import * as style from "./style.module.css";
import NavigationMenu from "../../components/nav-menu";
import AllBooks from "../../UI/AllBooks/AllBooks";
import useStyles from "./styles";
import CustomPagination from "../../UI/Pagination/Pagination";
import ScrollSection from "../../UI/ScrollSection/ScrollSection";
import PreLoader from "../../UI/PreLoader/PreLoader";
import { useGetSelection, useGetTextbooks } from "../../graphql";

const NovetlyPage = () => {
  const classes = useStyles();

  const [offset, setOffset] = useState(null);
  const [limit, setLimit] = useState(null);
  const [countProducs, setCountProducts] = useState(null);
  const [productsList, setProductsList] = useState([]);

  const { data } = useGetTextbooks({
    variables: {
      options: {
        limit,
        offset,
        sortBy: "publicationDate",
        sortType: "DESC",
      },
    },
  });

  useEffect(() => {
    if (data && data.getTextbooks) {
      setCountProducts(data.getTextbooks.count);
      setProductsList(data.getTextbooks.textbooks);
    }
  }, [data && data.getTextbooks]);

  const breadcrumbNameMap = {
    "/novelty": "Новинки",
  };

  const { data: dataSelections, loading: loadingSelections } = useGetSelection({
    variables: {
      page: "NOVELTIES_PAGE",
    },
  });

  if (loadingSelections) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <Box className={style.page}>
      <MetaTags>
        <title>Новинки</title>
        <meta property="og:title" content="Новинки" />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <Box className={style.pageHead}>
        <Box className="page-title">Новинки</Box>
      </Box>
      <Box className={style.pageWrap}>
        <AllBooks className={classes.allBooksGap} allBooks={productsList} />
      </Box>
      <Box className={style.pagePagination}>
        <CustomPagination
          itemsPerPage={countProducs}
          setOffset={setOffset}
          setLimit={setLimit}
          offset={offset}
          customLimitsList={[12, 24, 48]}
        />
      </Box>

      {dataSelections &&
        dataSelections.getSelection &&
        dataSelections.getSelection.map((section) => {
          return (
            !!section.textbooks.length && (
              <ScrollSection
                key={section.id}
                sectionTitle={section.title}
                productsList={section.textbooks}
                noLink
              />
            )
          );
        })}
    </Box>
  );
};

export default NovetlyPage;
