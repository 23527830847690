import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import BxBxsChevronDown from "../../assets/icons/BxBxsChevronDown";
import useStyles from "./styles";

const FilterAutocomplete = ({
  field,
  form,
  options,
  placeholder,
  getOptionLabel,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      className={classes.autocomplete}
      {...field}
      onChange={(e, value) => {
        form.setFieldValue(field.name, value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            placeholder,
            endAdornment: <BxBxsChevronDown />,
          }}
        />
      )}
    />
  );
};

export default FilterAutocomplete;
