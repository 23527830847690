import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { useApolloClient } from "@apollo/client";
import ModalWindow from "../../common/modal-window";
import InputStandart from "../../common/input";
import PartaBtn from "../../common/button";
import { schemaLogin } from "./schema";
import CheckboxStandart from "../../common/checkbox";
import * as style from "./style.module.css";
import RecoverPassword from "./RecoverPassword";
import { signInGQL } from "../../graphql";
import { UserLoginContext } from "../../contexts";

const LoginForm = ({
  open,
  handleClose,
  handleOpenRegistration,
  handleClickOpenLogin,
}) => {
  const [openRecoverPassword, setRecoverPassword] = useState(false);
  const client = useApolloClient();

  const handleOpenRecoverPassword = () => {
    setRecoverPassword(true);
    handleClose();
  };
  const handleCloseRecoverPassword = () => {
    setRecoverPassword(false);
  };

  const handleOpenSignUp = () => {
    handleOpenRegistration();
    handleClose();
  };

  return (
    <>
      <UserLoginContext.Consumer>
        {({ setLogIn }) => {
          return (
            <ModalWindow
              modalTitle="Добро пожаловать!"
              open={open}
              handleClose={handleClose}
            >
              <Formik
                initialValues={{ email: "", password: "", rememberMe: false }}
                onSubmit={(values, actions) => {
                  client
                    .query({
                      query: signInGQL,
                      variables: {
                        email: values.email,
                        password: values.password,
                        rememberMe: values.rememberMe,
                      },
                    })
                    .then((response) => {
                      if (response && response.data && response.data.signIn) {
                        const storage = values.rememberMe
                          ? localStorage
                          : sessionStorage;

                        setLogIn(true);

                        storage.setItem(
                          "token_catalog",
                          response.data.signIn.token
                        );
                        storage.setItem(
                          "fullName",
                          response.data.signIn.user.fullName
                        );
                        handleClose();
                      }
                    })
                    .catch((e) => {
                      actions.setErrors({
                        email: e.message,
                        password: e.message,
                      });
                    });
                }}
                validationSchema={schemaLogin}
              >
                <Form>
                  <Field name="email">
                    {({ field, meta }) => {
                      return (
                        <InputStandart
                          inputType="email"
                          placeholder="Email адрес"
                          touched={meta.touched}
                          error={meta.error}
                          {...field}
                        />
                      );
                    }}
                  </Field>
                  <Field name="password">
                    {({ field, meta }) => {
                      return (
                        <InputStandart
                          inputType="password"
                          placeholder="Пароль"
                          touched={meta.touched}
                          error={meta.error}
                          {...field}
                        />
                      );
                    }}
                  </Field>
                  <div className={style.checkboxContainer}>
                    <Field name="rememberMe">
                      {({ field }) => {
                        return (
                          <CheckboxStandart label="Запомнить меня" {...field} />
                        );
                      }}
                    </Field>
                    <div
                      className={style.link}
                      onClick={handleOpenRecoverPassword}
                      role="button"
                      tabIndex="0"
                    >
                      Забыли пароль?
                    </div>
                  </div>
                  <div className={style.alignCenter}>
                    <PartaBtn
                      variant="contained"
                      buttonText="Вход"
                      type="submit"
                      fullWidth={false}
                    />
                  </div>
                </Form>
              </Formik>
              <div className={style.alignCenter}>
                <span className={style.tip}>Ещё нет аккаунта?</span>
              </div>
              <div className={style.alignCenter}>
                <div
                  className={style.link}
                  onClick={handleOpenSignUp}
                  role="button"
                  tabIndex="0"
                >
                  Зарегистрироваться
                </div>
              </div>
            </ModalWindow>
          );
        }}
      </UserLoginContext.Consumer>

      <RecoverPassword
        handleClose={handleCloseRecoverPassword}
        open={openRecoverPassword}
        handleClickOpenLogin={handleClickOpenLogin}
      />
    </>
  );
};

export default LoginForm;
