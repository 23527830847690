import React, { useEffect, useState } from "react";
import useBreakpoint from "use-breakpoint";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import NavigationMenu from "../../components/nav-menu";
import PopularArticles from "./PopularArticles";
import { BREAKPOINTS } from "../../constants";
import useStyles from "./styles";
import PartaBtn from "../../common/button";
import FluentNews28Filled from "../../assets/icons/FluentNews28Filled";
import BxBxsChevronRight from "../../assets/icons/BxBxsChevronRight";
import CustomPagination from "../../UI/Pagination/Pagination";
import ModalFullscreen from "../../common/modal-fullscreen";
import BxBxsChevronLeft from "../../assets/icons/BxBxsChevronLeft";
import ScrollSection from "../../UI/ScrollSection/ScrollSection";
import { useGetNews, useGetSelection } from "../../graphql";
import { dateBuildFormatter } from "../../utils/dateBuildFormatter";
import PreLoader from "../../UI/PreLoader/PreLoader";

const News = () => {
  const [showPopularArticles, setPopularArticles] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(8);
  const [countProducs, setCountProducts] = useState(null);
  const [newsData, setNewsData] = useState(null);

  const { data } = useGetNews({
    variables: {
      limit,
      offset,
      sort: {
        field: "CREATED_AT",
        flow: "DESC",
      },
    },
  });

  useEffect(() => {
    if (data && data.getNews) {
      setCountProducts(data.getNews.count);
      setNewsData(data.getNews);
    }
  }, [data && data.getNews]);

  const handlePopularArticles = () => {
    setPopularArticles(!showPopularArticles);
  };

  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const showSearchBelowHeader = ["smallMobile", "mobile"].includes(breakpoint);

  const breadcrumbNameMap = {
    "/news": "Новости",
  };

  const { data: dataSelections, loading: loadingSelections } = useGetSelection({
    variables: {
      page: "NEWS_MATERIAL_PAGE",
    },
  });

  if (loadingSelections) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <div>
      <MetaTags>
        <title>Новости</title>
        <meta property="og:title" content="Новости" />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <div className="page-title">Новости</div>
      <div className={classes.newsWrapper}>
        <div className={classes.infoSection}>
          {newsData &&
            newsData.news.map((news) => {
              return (
                <div key={news.id} className={classes.infoContainer}>
                  <Link to={`/news/${news.id}`} className={classes.infoLinkImg}>
                    <img
                      src={news.image}
                      alt={news.imageAlt}
                      className={classes.infoImg}
                    />
                  </Link>
                  <div>
                    <div className={classes.infoTitle}>{news.title}</div>
                    <div className={classes.infoDescription}>
                      {news.description}
                    </div>
                    <div className={classes.spaceBetween}>
                      {news.createdAt && (
                        <div className={classes.infoDate}>
                          {dateBuildFormatter(news.createdAt)}
                        </div>
                      )}
                      <div>
                        <Link to={`/news/${news.id}`} className={classes.link}>
                          <div
                            className={
                              showSearchBelowHeader
                                ? classes.linkBtn
                                : classes.linkText
                            }
                          >
                            <span>Читать далее</span>
                            <BxBxsChevronRight
                              color={
                                showSearchBelowHeader ? "#ffffff" : "#0B486B"
                              }
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className={classes.paginationContainer}>
            <CustomPagination
              itemsPerPage={countProducs}
              setOffset={setOffset}
              setLimit={setLimit}
              offset={offset}
              customLimitsList={[8, 16, 32]}
            />
          </div>
        </div>
        {showSearchBelowHeader ? (
          <div className={classes.btnWrapper}>
            <PartaBtn
              variant="contained"
              buttonText="Популярные статьи"
              type="button"
              fullWidth
              startIcon={
                <FluentNews28Filled width="16" height="16" color="#ffffff" />
              }
              onClick={handlePopularArticles}
            />
            {showPopularArticles && (
              <ModalFullscreen
                open={showPopularArticles}
                handleClose={handlePopularArticles}
              >
                <PopularArticles />
                <div className={classes.returnBtn}>
                  <PartaBtn
                    variant="outlined"
                    buttonText="Вернуться"
                    type="button"
                    fullWidth
                    startIcon={<BxBxsChevronLeft width="16" height="16" />}
                    onClick={handlePopularArticles}
                  />
                </div>
              </ModalFullscreen>
            )}
          </div>
        ) : (
          <div className={classes.articleSection}>
            <PopularArticles />
          </div>
        )}
      </div>
      {dataSelections &&
        dataSelections.getSelection &&
        dataSelections.getSelection.map((section) => {
          return (
            !!section.textbooks.length && (
              <ScrollSection
                key={section.id}
                sectionTitle={section.title}
                productsList={section.textbooks}
                noLink
              />
            )
          );
        })}
    </div>
  );
};
export default News;
