import React from "react";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";
import {
  FacebookShareButton,
  VKShareButton,
  InstapaperShareButton,
  TwitterShareButton,
} from "react-share";
import * as style from "./style.module.css";
import Facebook from "../../assets/icons/Facebook";
import VkIcon from "../../assets/icons/VkIcon";
import Instagram from "../../assets/icons/Instagram";
import Twitter from "../../assets/icons/Twitter";

const Share = ({ title, colorIcon }) => {
  const currentLocationURL = window.location.href;

  return (
    <Box className={style.share}>
      <Typography className={style.shareTitle}>{title}</Typography>
      <Box className={style.shareContent}>
        <Box className={style.shareItem}>
          <FacebookShareButton url={currentLocationURL}>
            <Facebook color={colorIcon} />
          </FacebookShareButton>
        </Box>
        <Box className={style.shareItem}>
          <VKShareButton url={currentLocationURL}>
            <VkIcon color={colorIcon} />
          </VKShareButton>
        </Box>
        <Box className={style.shareItem}>
          <InstapaperShareButton url={currentLocationURL}>
            <Instagram color={colorIcon} />
          </InstapaperShareButton>
        </Box>
        <Box className={style.shareItem}>
          <TwitterShareButton url={currentLocationURL}>
            <Twitter color={colorIcon} />
          </TwitterShareButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Share;
