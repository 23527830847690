import React from "react";

const PaymentsMasterCardIcon = ({ width = 39, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7726 2.56689H14.2275V21.4335H24.7726V2.56689Z"
        fill="#FF5F00"
      />
      <path
        d="M14.8972 12C14.8972 8.16663 16.7048 4.76666 19.4833 2.56665C17.4412 0.966665 14.8637 0 12.0514 0C5.38977 0 0 5.36665 0 12C0 18.6333 5.38977 24 12.0514 24C14.8637 24 17.4412 23.0332 19.4833 21.4332C16.7048 19.2666 14.8972 15.8333 14.8972 12Z"
        fill="#EB001B"
      />
      <path
        d="M38.9999 12C38.9999 18.6333 33.6103 24 26.9484 24C24.1364 24 21.5587 23.0332 19.5166 21.4332C22.3286 19.2333 24.1029 15.8333 24.1029 12C24.1029 8.16663 22.2951 4.76666 19.5166 2.56665C21.5587 0.966665 24.1364 0 26.9484 0C33.6103 0 38.9999 5.39997 38.9999 12Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default PaymentsMasterCardIcon;
