import React from "react";

const Youtube = ({ color = "#C5D7E0", width = 21, height = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5928 2.20301C20.4789 1.78041 20.2563 1.39501 19.9472 1.08517C19.6381 0.775338 19.2532 0.551868 18.8308 0.437007C17.2648 0.00700739 10.9998 7.59651e-06 10.9998 7.59651e-06C10.9998 7.59651e-06 4.73584 -0.00699261 3.16884 0.404007C2.74677 0.524154 2.36266 0.750784 2.05341 1.06214C1.74415 1.3735 1.52013 1.75913 1.40284 2.18201C0.989841 3.74801 0.985841 6.99601 0.985841 6.99601C0.985841 6.99601 0.981841 10.26 1.39184 11.81C1.62184 12.667 2.29684 13.344 3.15484 13.575C4.73684 14.005 10.9848 14.012 10.9848 14.012C10.9848 14.012 17.2498 14.019 18.8158 13.609C19.2383 13.4943 19.6236 13.2714 19.9335 12.9622C20.2434 12.653 20.4672 12.2682 20.5828 11.846C20.9968 10.281 20.9998 7.03401 20.9998 7.03401C20.9998 7.03401 21.0198 3.76901 20.5928 2.20301ZM8.99584 10.005L9.00084 4.00501L14.2078 7.01001L8.99584 10.005Z"
        fill={color}
      />
    </svg>
  );
};

export default Youtube;
