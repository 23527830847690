import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";
import useBreakpoint from "use-breakpoint";
import { Link, useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import * as style from "./style.module.css";
import PartaBtn from "../../common/button";
import BiBagFill from "../../assets/icons/BiBagFill";
import Favorite from "../../assets/icons/Favorite";
import { BREAKPOINTS } from "../../constants";
import Share from "../../UI/Share/Share";
import Payments from "../../UI/Payments/Payments";
import useStyles from "./styles";
import NavigationMenu from "../../components/nav-menu";
import {
  useAddToFavorited,
  useDeleteFavorite,
  useGetTextBookById,
  useGetUserFavoritesLazy,
} from "../../graphql";
import NoImg from "../../assets/icons/NoImg";
import PreLoader from "../../UI/PreLoader/PreLoader";
import BookPageSelections from "./BookPageSelections";
import { secondToTime } from "../../utils/secondToTime";

const BookPage = () => {
  const classes = useStyles();
  const params = useParams();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isDesktop = ["tablet", "desktop"].includes(breakpoint);
  const [book, setBook] = useState(null);
  const [favoriteBtnState, setFavoriteBtnState] = useState(false);
  const token =
    localStorage.getItem("token_catalog") ||
    sessionStorage.getItem("token_catalog");

  const { data, loading } = useGetTextBookById({
    variables: {
      textbookId: params.book,
    },
  });

  const [
    setAddFavorited,
    { data: dataAddFavorited, loading: loadingAddFavorite },
  ] = useAddToFavorited();

  const [
    setDelFavorited,
    { data: dataDelFavorited, loading: loadingDelFavorite },
  ] = useDeleteFavorite();

  const [
    getUserFavorites,
    { data: dataGetUserFavorites, loading: loadingGetUserFavorites },
  ] = useGetUserFavoritesLazy();

  useEffect(() => {
    if (token) {
      getUserFavorites({
        variables: {
          options: {
            limit: 0,
            offset: 0,
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (dataGetUserFavorites?.getUserFavorites) {
      const isIncludesUserFavorites =
        dataGetUserFavorites.getUserFavorites.textbooks.some(
          (item) => item.id === params.book
        );
      setFavoriteBtnState(isIncludesUserFavorites);
    }
  }, [dataGetUserFavorites?.getUserFavorites]);

  useEffect(() => {
    if (dataAddFavorited?.addToFavorite) {
      setFavoriteBtnState(dataAddFavorited.addToFavorite.status);
    }
  }, [dataAddFavorited?.addToFavorite]);

  useEffect(() => {
    if (dataDelFavorited?.deleteFavorite) {
      setFavoriteBtnState(!dataDelFavorited.deleteFavorite.status);
    }
  }, [dataDelFavorited?.deleteFavorite]);

  const handleAddFavorite = () => {
    setAddFavorited({
      variables: {
        textBookId: Number(params.book),
      },
    });
  };

  const handleDeleteFavorite = () => {
    setDelFavorited({
      variables: {
        textBookId: Number(params.book),
      },
    });
  };

  useEffect(() => {
    if (data && data.getTextbookById) {
      setBook(data.getTextbookById);
    }
  }, [data && data.getTextbookById]);

  const breadcrumbNameMap = useMemo(() => {
    if (data && data.getTextbookById) {
      const {
        title,
        id: bookId,
        subject: { alias, name },
      } = data.getTextbookById;

      return {
        "/all-books": "Все книги",
        [`/all-books/${alias}`]: name,
        [`/all-books/${alias}/${bookId}`]: title,
      };
    }

    return {};
  }, [data]);

  const isDisabled =
    loadingAddFavorite || loadingDelFavorite || loadingGetUserFavorites;

  if (loading) return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;

  return (
    <Box className={style.bookPage}>
      <MetaTags>
        <title>{book?.title}</title>
        <meta name="description" content={book?.description} />
        <meta property="og:title" content={book?.title} />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_URL}${book?.cover}`}
        />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <Box className={style.bookPageWrap}>
        <Box className={style.bookPageImg}>
          {book && book.cover ? (
            <img
              src={`${process.env.REACT_APP_URL}${book.cover}`}
              alt={book.title}
            />
          ) : (
            <NoImg width={250} height={250} />
          )}
        </Box>
        <Box className={style.bookPageInfoBox}>
          <Box className={style.bookPageHead}>
            <Typography className={style.bookPageTitle}>
              {book && book.title}
            </Typography>
            {isDesktop && (
              <Box className={style.bookPageShare}>
                <Share title="Поделиться:" colorIcon="#0B486B" />
              </Box>
            )}
          </Box>
          <Box className={style.bookPageInfo}>
            <Box className={style.bookPageInfoRows}>
              {book && book.authors && (
                <Box className={style.bookPageInfoRow}>
                  <Typography className={style.dropdownRowName}>
                    Автор
                  </Typography>
                  <Typography className={style.dropdownRowVal}>
                    {book.authors}
                  </Typography>
                </Box>
              )}
              {book && book.subject && (
                <Box className={style.bookPageInfoRow}>
                  <Typography className={style.dropdownRowName}>
                    Предмет
                  </Typography>
                  <Typography className={style.dropdownRowVal}>
                    {book.subject.name}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box className={style.bookPageInfoBtns}>
              {book && book.price && (
                <Box className={style.btnPrice}>
                  <Link className={style.link} to={`/order/${book.id}`}>
                    <PartaBtn
                      className={classes.bookPageInfoBtn}
                      variant="contained"
                      buttonText={`Купить ${book.price} ₽`}
                      startIcon={<BiBagFill color="#fff" />}
                    />
                  </Link>
                </Box>
              )}
              {token && (
                <Box className={style.btnWishlist}>
                  <PartaBtn
                    className={classes.bookPageInfoBtn}
                    variant={favoriteBtnState ? "contained" : "outlined"}
                    buttonText={
                      favoriteBtnState ? "Из избранного" : "В избранное"
                    }
                    disabled={isDisabled}
                    onClick={
                      favoriteBtnState
                        ? handleDeleteFavorite
                        : handleAddFavorite
                    }
                    startIcon={
                      <Favorite
                        color={
                          isDisabled || favoriteBtnState ? "#ffffff" : "#0B486B"
                        }
                      />
                    }
                  />
                </Box>
              )}
            </Box>
            {!isDesktop && (
              <Box className={style.bookPageShare}>
                <Share title="Поделиться:" colorIcon="#0B486B" />
              </Box>
            )}
            <Box className={style.bookPageInfoPaymants}>
              <Payments />
            </Box>
            {book && book.description && (
              <Box className={style.bookPageInfoDescr}>
                {!isDesktop && (
                  <Box className={style.bookPageInfoDescrTitle}>
                    Краткое описание
                  </Box>
                )}
                <Typography className={style.bookPageInfoDescrText}>
                  {book.description}
                </Typography>
              </Box>
            )}
            {book && !!book.audioDuration && (
              <Box className={style.bookPageInfoDuration}>
                <Box className={style.bookPageInfoRow}>
                  <Typography className={style.dropdownRowName}>
                    Время
                  </Typography>
                  <Typography className={style.dropdownRowVal}>
                    {secondToTime(book.audioDuration * 1000)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <BookPageSelections
        subjectName={book?.subject?.name}
        subjectAlias={book?.subject?.alias}
      />
    </Box>
  );
};

export default BookPage;
