import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import BxBxsChevronUpDown from "../../assets/icons/BxBxsChevronUpDown";
import useStyles from "./styles";

const SortSelect = ({ setSortBy, setSortType }) => {
  const classes = useStyles();
  const [placeholder, setPlaceholder] = useState("Сначала популярные");
  const options = [
    {
      label: "Последние опубликованные",
      sortBy: "publicationDate",
      sortType: "DESC",
    },
    {
      label: "По названию (А-Я)",
      sortBy: "title",
      sortType: "ASC",
    },
    {
      label: "По названию (Я-А)",
      sortBy: "title",
      sortType: "DESC",
    },
    {
      label: "От новых к старым",
      sortBy: "issueYear",
      sortType: "DESC",
    },
    {
      label: "От старых к новым",
      sortBy: "issueYear",
      sortType: "ASC",
    },
  ];
  const handleChage = (e) => {
    setSortBy(e.target.value ? e.target.value.sortBy : "");
    setSortType(e.target.value ? e.target.value.sortType : "");
    setPlaceholder(e.target.value.label || "");
  };
  return (
    <Box className={classes.headFilter}>
      <FormControl fullWidth>
        <Select
          variant="outlined"
          IconComponent={BxBxsChevronUpDown}
          value=""
          displayEmpty
          onChange={handleChage}
          MenuProps={{
            anchorOrigin: {
              vertical: 35,
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
          {options.map((selectItem) => {
            return (
              <MenuItem
                key={selectItem.label}
                value={selectItem}
                onClick={selectItem.onClick}
              >
                {selectItem.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SortSelect;
