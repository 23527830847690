import React from "react";
import { Link } from "react-router-dom";
import Contacts from "../contacts";
import MainMenu from "../main-menu";
import * as style from "./style.module.css";
import useStyles from "./styles";
import Logo from "../../assets/icons/Logo";

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={style.footer}>
      <div className={style.footerWrapper}>
        <div className={style.footerContainer}>
          <MainMenu
            listContainer={classes.listContainer}
            listItemButton={classes.listItemButton}
            listItemLink={style.listItemLink}
            colorIcon="#C5D7E0"
          />
          <div className={style.footerContentRight}>
            <Contacts
              contactsPosition={style.contactsPosition}
              socialContainer={style.socialContainer}
              socialContent={style.socialContent}
              infoContainer={style.infoContainer}
            />
            <div className={style.logoImg}>
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr className={style.footerHr} />
      <div className={style.description}>
        Внимание! Обращаем Ваше внимание на то, что данный интернет-сайт носит
        исключительно информационный характер и ни при каких условиях не
        является публичной офертой, определяемой положениями ч.2 ст.437 ГК РФ.
        Для получения подробной информации, пожалуйста, обращайтесь к
        сотрудникам компании Parta
      </div>
    </footer>
  );
};
export default Footer;
