import React from "react";
import Box from "@mui/material/Box";

import * as style from "./style.module.css";
import PaymentsVisaIcon from "../../assets/icons/PaymentsVisaIcon";
import PaymentsMasterCardIcon from "../../assets/icons/PaymentsMasterCardIcon";
import PaymentsMirIcon from "../../assets/icons/PaymentsMirIcon";
import PaymentsPayPalIcon from "../../assets/icons/PaymentsPayPalIcon";
import PaymentsQiwiIcon from "../../assets/icons/PaymentsQiwiIcon";
import PaymentsWalletIcon from "../../assets/icons/PaymentsWalletIcon";
import PaymentsSberIcon from "../../assets/icons/PaymentsSberIcon";

const Payments = () => {
  return (
    <Box className={style.payments}>
      <PaymentsVisaIcon />
      <PaymentsMasterCardIcon />
      <PaymentsMirIcon />
      <PaymentsPayPalIcon />
      <PaymentsQiwiIcon />
      <PaymentsWalletIcon />
      <PaymentsSberIcon />
    </Box>
  );
};

export default Payments;
