import React, { useMemo, useState } from "react";
import useBreakpoint from "use-breakpoint";
import { Button } from "@mui/material";
import clsx from "clsx";
import UserIcon from "../../assets/icons/topbar_user";
import useStyles from "./styles";
import * as style from "./style.module.css";
import { BREAKPOINTS } from "../../constants";
import AuthorizedUser from "./AuthorizedUser";
import UnauthorizedUser from "./UnauthorizedUser";

const User = ({ setOpenLogin, setOpenRegistration }) => {
  const token =
    sessionStorage.getItem("token_catalog") ||
    localStorage.getItem("token_catalog");
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isNotDesktop = ["smallMobile", "mobile", "bigMobile"].includes(
    breakpoint
  );
  const classes = useStyles();

  const isUserAuthorized = useMemo(() => typeof token === "string", [token]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleMenu = (event) => {
    setAnchorEl((prev) => (prev === null ? event.currentTarget : null));
  };

  return (
    <>
      <Button
        className={clsx(style.alignCenter, classes.userBtn)}
        onClick={handleToggleMenu}
      >
        <UserIcon
          outlineFill={anchorEl ? "#C5D7E0" : "#ffffff"}
          width={isNotDesktop ? "32" : "40"}
          height={isNotDesktop ? "32" : "40"}
        />
      </Button>
      {isUserAuthorized ? (
        <AuthorizedUser
          anchorEl={anchorEl}
          openMenu={Boolean(anchorEl)}
          handleToggleMenu={handleToggleMenu}
        />
      ) : (
        <UnauthorizedUser
          anchorEl={anchorEl}
          openMenu={Boolean(anchorEl)}
          handleToggleMenu={handleToggleMenu}
          setOpenLogin={setOpenLogin}
          setOpenRegistration={setOpenRegistration}
        />
      )}
    </>
  );
};

export default User;
