import * as yup from "yup";

export const messages = {
  PROMPT_MAIL: "Введите адрес электронной почты",
  PROMPT_PASSWORD: "Введите свой пароль",
  CONFIRM_PASSWORD: "Пароли должны совпадать",
  REQUIRE_MESSAGE: "Обязательное поле",
  INCORRECT_SYMBOL: "Запрещенный символ",
};

export const schemaLogin = yup.object().shape({
  email: yup
    .string(messages.PROMPT_MAIL)
    .email(messages.PROMPT_MAIL)
    .required(messages.REQUIRE_MESSAGE),
  password: yup
    .string(messages.PROMPT_PASSWORD)
    .required(messages.REQUIRE_MESSAGE),
  rememberMe: yup.boolean(),
});

export const schemaRegistration = yup.object().shape({
  name: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
  lastName: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
  phone: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
  email: yup
    .string(messages.PROMPT_MAIL)
    .email(messages.PROMPT_MAIL)
    .required(messages.REQUIRE_MESSAGE),
  password: yup
    .string(messages.PROMPT_PASSWORD)
    .required(messages.REQUIRE_MESSAGE),
  confirmPassword: yup
    .string(messages.PROMPT_PASSWORD)
    .oneOf([yup.ref("password"), null], messages.CONFIRM_PASSWORD)
    .required(messages.REQUIRE_MESSAGE),
  agree: yup.boolean().required(messages.REQUIRE_MESSAGE),
});

export const schemaRecoverPassword = yup.object().shape({
  email: yup
    .string(messages.PROMPT_MAIL)
    .email(messages.PROMPT_MAIL)
    .required(messages.REQUIRE_MESSAGE),
});

export const schemaChangePassword = yup.object().shape({
  password: yup
    .string(messages.PROMPT_PASSWORD)
    .required(messages.REQUIRE_MESSAGE),
  confirmPassword: yup
    .string(messages.PROMPT_PASSWORD)
    .oneOf([yup.ref("password"), null], messages.CONFIRM_PASSWORD)
    .required(messages.REQUIRE_MESSAGE),
});

export const schemaChangeOldPassword = yup.object().shape({
  oldPassword: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
  password: yup
    .string(messages.PROMPT_PASSWORD)
    .required(messages.REQUIRE_MESSAGE),
  confirmPassword: yup
    .string(messages.PROMPT_PASSWORD)
    .oneOf([yup.ref("password"), null], messages.CONFIRM_PASSWORD)
    .required(messages.REQUIRE_MESSAGE),
});

export const schemaAccount = yup.object().shape({
  fullname: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
  birthday: yup.date().nullable(),
  email: yup
    .string(messages.INCORRECT_SYMBOL)
    .email()
    .required(messages.REQUIRE_MESSAGE),
  phone: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
  school: yup.object().nullable(),
});

export const schemaOrder = yup.object().shape({
  fullname: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
  email: yup
    .string(messages.INCORRECT_SYMBOL)
    .email()
    .required(messages.REQUIRE_MESSAGE),
  phone: yup
    .string(messages.INCORRECT_SYMBOL)
    .required(messages.REQUIRE_MESSAGE),
});
