import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  searchWidth: {
    "&.MuiFormControl-root": {
      width: "100%",
      maxWidth: 811,
      display: "flex",
      flexDirection: "row",
      "@media (min-width:1920px)": {
        marginRight: 57,
      },
      "@media (min-width:1000px)": {
        marginRight: 40,
      },
      "@media (max-width:999.9px)": {
        margin: "0px 40px",
      },
      "@media (max-width: 749.9px)": {
        margin: "0px 24px",
      },
      "@media (max-width: 639.9px)": {
        margin: "0px 16px",
        display: "flex",
        justifyContent: "space-between",
      },
    },
  },
  inputBase: {
    "&.MuiInputBase-root.MuiInput-root": {
      minHeight: 32,
      minWidth: 260,
      width: "100% ",
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #4D6E7D",
      backgroundColor: "#ffffff",
      borderRadius: 8,
      paddingLeft: 16,

      "@media (max-width: 999.9px)": {
        marginRight: 24,
        borderRadius: 16,
        maxWidth: 497,
        minWidth: 186,
      },
      "@media (max-width: 638px)": {
        maxWidth: 510,
        minWidth: 186,
      },
    },
    "&.MuiInputBase-root.MuiInput-root:before, &.MuiInputBase-root.MuiInput-root:after, &.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before":
      {
        content: " ",
        borderBottom: "none",
      },
    "& .MuiInputBase-input.MuiInput-input::placeholder, & .MuiInputBase-input.MuiInput-input":
      {
        fontSize: 15,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        letterSpacing: "0.03em",
        color: "#4D6E7D",
        opacity: 1,
        padding: 0,
      },
    "& .MuiButtonBase-root.MuiIconButton-root": {
      padding: 0,
      marginLeft: 10,
    },
  },
  searchBtnDefault: {
    "&.MuiButtonBase-root.MuiButton-root": {
      width: 73,
      height: 32,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: 16,
      letterSpacing: 0,
      textAlign: "center",
      padding: "8px 16px",
      borderRadius: 8,
      backgroundColor: "#ffffff",
      color: "#0b486b",
      textTransform: "capitalize",

      "@media (max-width:639px)": {
        backgroundColor: "#0b486b",
        color: "#ffffff",
      },
      "@media (min-width:1000px)": {
        display: "none",
      },
    },
  },
}));

export default useStyles;
