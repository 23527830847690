import React from "react";

export default function antDesignUserOutlined() {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4141 10.9312C11.1194 10.2331 10.6916 9.59891 10.1547 9.06406C9.61947 8.52767 8.9854 8.10001 8.28754 7.80469C8.28129 7.80156 8.27504 7.8 8.26879 7.79688C9.24223 7.09375 9.87504 5.94844 9.87504 4.65625C9.87504 2.51562 8.14066 0.78125 6.00004 0.78125C3.85941 0.78125 2.12504 2.51562 2.12504 4.65625C2.12504 5.94844 2.75785 7.09375 3.73129 7.79844C3.72504 7.80156 3.71879 7.80312 3.71254 7.80625C3.01254 8.10156 2.38441 8.525 1.84535 9.06563C1.30896 9.60089 0.881296 10.235 0.585977 10.9328C0.295855 11.616 0.139386 12.3485 0.125039 13.0906C0.124622 13.1073 0.127547 13.1239 0.133642 13.1394C0.139737 13.1549 0.148878 13.1691 0.160527 13.181C0.172176 13.193 0.186098 13.2025 0.201471 13.2089C0.216844 13.2154 0.233358 13.2188 0.250039 13.2188H1.18754C1.25629 13.2188 1.31098 13.1641 1.31254 13.0969C1.34379 11.8906 1.82816 10.7609 2.68441 9.90469C3.57035 9.01875 4.74691 8.53125 6.00004 8.53125C7.25316 8.53125 8.42973 9.01875 9.31566 9.90469C10.1719 10.7609 10.6563 11.8906 10.6875 13.0969C10.6891 13.1656 10.7438 13.2188 10.8125 13.2188H11.75C11.7667 13.2188 11.7832 13.2154 11.7986 13.2089C11.814 13.2025 11.8279 13.193 11.8396 13.181C11.8512 13.1691 11.8603 13.1549 11.8664 13.1394C11.8725 13.1239 11.8755 13.1073 11.875 13.0906C11.8594 12.3438 11.7047 11.6172 11.4141 10.9312ZM6.00004 7.34375C5.28285 7.34375 4.60785 7.06406 4.10004 6.55625C3.59223 6.04844 3.31254 5.37344 3.31254 4.65625C3.31254 3.93906 3.59223 3.26406 4.10004 2.75625C4.60785 2.24844 5.28285 1.96875 6.00004 1.96875C6.71723 1.96875 7.39223 2.24844 7.90004 2.75625C8.40785 3.26406 8.68754 3.93906 8.68754 4.65625C8.68754 5.37344 8.40785 6.04844 7.90004 6.55625C7.39223 7.06406 6.71723 7.34375 6.00004 7.34375Z"
        fill="#0B486B"
      />
    </svg>
  );
}
