import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import useBreakpoint from "use-breakpoint";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/icons/SearchIcon";
import useStyles from "./styles";
import * as style from "./style.module.css";
import { BREAKPOINTS } from "../../constants";

const Search = () => {
  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const isNotDesktop = ["smallMobile", "mobile", "bigMobile"].includes(
    breakpoint
  );
  const navigate = useNavigate();
  const [searchStr, setSearchStr] = useState("");

  const handleClickSearch = () => {
    navigate({
      pathname: "/results",
      search: `?${searchStr}`,
    });
  };

  const handleChangeSearch = (e) => {
    setSearchStr(e.target.value);
  };

  return (
    <FormControl variant="standard" className={classes.searchWidth}>
      <Input
        id="input-with-icon-adornment"
        placeholder={isNotDesktop ? "Поиск" : "Название, предмет, автор"}
        value={searchStr}
        onChange={handleChangeSearch}
        endAdornment={
          <IconButton
            className={style.searchIcon}
            type="submit"
            aria-label="search"
            onClick={handleClickSearch}
          >
            <SearchIcon />
          </IconButton>
        }
        className={classes.inputBase}
      />
      <Button
        disableRipple
        className={classes.searchBtnDefault}
        onClick={handleClickSearch}
      >
        Найти
      </Button>
    </FormControl>
  );
};

export default Search;
