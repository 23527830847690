import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  form: {
    "@media (max-width:1000px)": {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
  },
  filterContainer: {
    backgroundColor: "#FAFAFA",
    border: "1px solid #0000001A",
    padding: "16px 24px 4px 24px",
    "@media (max-width:1000px)": {
      paddingBottom: 24,
      height: "100%",
    },
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: 0,
    color: "#303030",
    borderBottom: "1px solid #0000001A",
    marginBottom: 18,
    paddingBottom: 16,

    "& .MuiButton-startIcon": {
      marginRight: 10,
      marginLeft: 0,
    },
    "& .MuiButtonBase-root.MuiButton-root:last-child": {
      marginLeft: 24,
      "@media (max-width:440px)": {
        marginLeft: 0,
      },
    },
  },
  autocomplete: {
    "&.MuiAutocomplete-root": {
      width: "100%",
      maxWidth: 352,
      marginLeft: 15,
      "@media (max-width:639px)": {
        marginLeft: 0,
        marginBottom: 20,
        maxWidth: "unset",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& svg": {
        transform: "rotate(180deg)",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0B486B",
      },
    },
    "& .MuiAutocomplete-input": {
      position: "relative",
      zIndex: 1,
    },
    "& .MuiInputBase-root svg": {
      position: "absolute",
      right: 4,
      zIndex: 0,
      transform: "rotate(0)",
      transition: ".15s",
    },
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root .MuiOutlinedInput-root, &.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root":
      {
        padding: "0px 8px 0px 0px",
        backgroundColor: "#ffffff",
        borderRadius: 8,
        maxWidth: 352,
        width: "100%",
        "@media (max-width:639px)": {
          maxWidth: "unset",
        },
      },
    "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input": {
      height: 32,
      padding: "0px 4px 0px 10px",
    },
    "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input, &.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input::placeholder":
      {
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "14px",
        letterSpacing: 0,
        color: "#5C5C5C",
      },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C5D7E0",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#C5D7E0",
      },
    "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root":
      {
        paddingRight: 8,
      },
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "@media (max-width:639px)": {
      flexDirection: "column",
    },

    "& .MuiInputBase-root.MuiInput-root, & .inputWrapperWidth": {
      maxWidth: 800,
    },
  },
  btnWrapper: {
    display: "flex",
    "@media (max-width:1000px)": {
      marginTop: "auto",
    },
  },
  btnClear: {
    marginRight: 24,
    width: "fit-content",
  },
}));

export default useStyles;
