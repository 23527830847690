import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import { Link } from "react-router-dom";
import * as style from "./style.module.css";
import { useGetSelectingsList } from "../../graphql";
import BorderWrapper from "../BorderWrapper/BorderWrapper";

const CatalogSidebar = () => {
  const { data } = useGetSelectingsList();

  const [subjects, setSubjects] = useState(null);

  useEffect(() => {
    if (data && data.selectingsList) {
      setSubjects(data.selectingsList.subjects);
    }
  }, [data && data.selectingsList]);

  const saveSubjectId = (subject) => () => {
    sessionStorage.setItem("subjectData", JSON.stringify(subject));
  };

  return (
    <Box className={style.catalogSidebar}>
      <BorderWrapper title="Предметы">
        <ol className={style.catalogSidebarList}>
          {subjects &&
            subjects.map((subject) => {
              return (
                <li key={subject.id} className={style.catalogSidebarItem}>
                  <Link
                    className={style.catalogSidebarLink}
                    to={`/all-books/${subject.alias}`}
                    onClick={saveSubjectId(subject)}
                  >
                    {subject.name}
                  </Link>
                </li>
              );
            })}
        </ol>
      </BorderWrapper>
    </Box>
  );
};

export default CatalogSidebar;
