import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  bookPageInfoBtn: {
    "@media (max-width: 1000px)": {
      "&.MuiButtonBase-root.MuiButton-root": {
        height: 40,
        display: "flex",
        justifyContent: "space-between",

        "& .MuiButton-startIcon": {
          marginLeft: 0,
          marginRight: 20,
        },
      },
    },
  },
}));

export default useStyles;
