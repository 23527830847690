import React, { useState, useMemo } from "react";
import { Formik, Field, Form } from "formik";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import clsx from "clsx";
import InputUnderline from "../../common/input/InputUnderline";
import PartaBtn from "../../common/button";
import { schemaAccount } from "../../components/forms/schema";
import useStyles from "./styles";
import AntDesignEditFilled from "../../assets/icons/AntDesignEditFilled";
import ChangePassword from "../../components/forms/ChangePassword";
import CarbonAddAlt from "../../assets/icons/carbon_add-alt";
import MdiLockReset from "../../assets/icons/MdiLockReset";
import FluentSave20Filled from "../../assets/icons/FluentSave20Filled";
import {
  useCurrentUser,
  useEditInfoUser,
  useGetAllUniversity,
} from "../../graphql";

const Information = () => {
  const classes = useStyles();
  const [openChangePassword, setChangePassword] = useState(false);

  const handleOpenChangePassword = () => {
    setChangePassword(true);
  };
  const handleCloseChangePassword = () => {
    setChangePassword(false);
  };

  const { data } = useCurrentUser();

  const { data: dataUniversity } = useGetAllUniversity();

  const initialValues = useMemo(() => {
    if (data && data.currentUser) {
      const schoolValue = data.currentUser.university
        ? {
            name: data.currentUser.university?.name,
            icon: data.currentUser.university?.icon,
          }
        : null;

      return {
        fullname: data.currentUser.fullName,
        birthday: data.currentUser.dateOfBirth,
        email: data.currentUser.email,
        phone: data.currentUser.phone,
        school: schoolValue,
      };
    }
    return {
      fullname: "",
      birthday: null,
      email: "",
      phone: "",
      school: null,
    };
  }, [data]);

  const [editInfoUser] = useEditInfoUser();

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          editInfoUser({
            variables: {
              input: {
                fullName: values.fullname,
                dateOfBirth: new Date(+values.birthday),
                email: values.email,
                phone: values.phone,
                universityId: values.school ? Number(values.school.id) : null,
              },
            },
          });
        }}
        validationSchema={schemaAccount}
        enableReinitialize
      >
        <Form>
          <div className={classes.dFlex}>
            <div className={classes.formContainer}>
              <div className={classes.w100}>
                <Field name="fullname">
                  {({ field, meta }) => {
                    return (
                      <InputUnderline
                        label="Имя Фамилия"
                        inputType="text"
                        inputIcon={
                          <AntDesignEditFilled width="20" height="20" />
                        }
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <Field name="birthday">
                  {({ field, form }) => {
                    return (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          value={field.value ? new Date(+field.value) : null}
                          name={field.name}
                          placeholder="Добавить дату"
                          onChange={(newValue) => {
                            form.setFieldValue(field.name, newValue);
                          }}
                          renderInput={(params) => (
                            <>
                              <div className={classes.labelBirthday}>
                                Дата рождения
                              </div>
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "Добавить дату",
                                }}
                                className={classes.datePickerWrapper}
                              />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    );
                  }}
                </Field>
                <Field name="school">
                  {({ field, form }) => {
                    return (
                      <Autocomplete
                        options={
                          (dataUniversity && dataUniversity.getAllUniversity) ||
                          []
                        }
                        getOptionLabel={(option) => {
                          return option.name;
                        }}
                        className={classes.autocomplete}
                        renderOption={(props, option) => {
                          return (
                            <div
                              {...props}
                              key={option.name}
                              className={clsx(
                                classes.autocompeteOptions,
                                props.className
                              )}
                            >
                              {option.name}
                              <img
                                src={`${process.env.REACT_APP_URL}${option.icon}`}
                                alt="Emblem"
                              />
                            </div>
                          );
                        }}
                        {...field}
                        onChange={(e, value) => {
                          form.setFieldValue(field.name, value);
                        }}
                        renderInput={(params) => (
                          <>
                            <div className={classes.labelBirthday}>
                              Учебное заведение
                            </div>
                            <TextField
                              {...params}
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                ...(field.value && {
                                  endAdornment: (
                                    <img
                                      src={`${process.env.REACT_APP_URL}${field.value.icon}`}
                                      alt="Emblem"
                                    />
                                  ),
                                }),
                                ...(!field.value && {
                                  startAdornment: (
                                    <div
                                      className={clsx(
                                        classes.addBtn,
                                        "hide-add-btn"
                                      )}
                                    >
                                      <CarbonAddAlt /> Добавить
                                    </div>
                                  ),
                                }),
                              }}
                              className={classes.autocompleteWrapper}
                            />
                          </>
                        )}
                      />
                    );
                  }}
                </Field>
              </div>
              <div className={classes.w100}>
                <Field name="email">
                  {({ field, meta }) => {
                    return (
                      <InputUnderline
                        label="Email адрес"
                        inputType="email"
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <Field name="phone">
                  {({ field, meta }) => {
                    return (
                      <InputUnderline
                        label="Телефон"
                        inputType="tel"
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
              </div>
            </div>
            <div className={classes.passwordBtn}>
              <PartaBtn
                variant="outlined"
                buttonText="Изменить пароль"
                type="button"
                startIcon={
                  <MdiLockReset width="16" height="16" color="#0B486B" />
                }
                fullWidth={false}
                onClick={handleOpenChangePassword}
              />
            </div>
          </div>

          <div className={classes.saveBtn}>
            <PartaBtn
              variant="contained"
              buttonText="Сохранить"
              type="submit"
              startIcon={
                <FluentSave20Filled width="16" height="16" color="#ffffff" />
              }
              fullWidth={false}
            />
          </div>
        </Form>
      </Formik>
      <ChangePassword
        handleClose={handleCloseChangePassword}
        open={openChangePassword}
        oldPasswordMode
      />
    </div>
  );
};

export default Information;
