import React from "react";
import { Link } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import * as style from "./style.module.css";
import BxBxsChevronRight from "../../assets/icons/BxBxsChevronRight";
import { BREAKPOINTS } from "../../constants";
import PartaBtn from "../../common/button";

const BorderWrapper = ({
  children,
  title,
  linkVisible = false,
  linkUrl,
  linkName,
  marginBtn,
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isMobile = ["smallMobile", "mobile"].includes(breakpoint);
  const isDesktop = ["bigMobile", "tablet", "desktop"].includes(breakpoint);

  return (
    <div className={style.borderWrapper}>
      <div className={style.topSection}>
        {title}
        {isDesktop && linkVisible && (
          <Link to={linkUrl || "/"} className={style.link}>
            <div className={style.linkText}>
              {linkName}
              <BxBxsChevronRight color="#0B486B" />
            </div>
          </Link>
        )}
      </div>
      {children}
      {isMobile && (
        <div className={marginBtn}>
          {linkVisible && (
            <Link to={linkUrl || "/"} className={style.link}>
              <PartaBtn
                variant="contained"
                buttonText={linkName}
                type="button"
                fullWidth
                endIcon={
                  <BxBxsChevronRight width="16" height="16" color="#ffffff" />
                }
              />
            </Link>
          )}
        </div>
      )}
    </div>
  );
};
export default BorderWrapper;
