import React from "react";

const PaymentsMirIcon = ({ width = 63, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 63 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9765 3.41399e-07V0.00785218C16.9688 0.00785218 14.5156 4.32847e-07 13.8616 2.36595C13.2619 4.53537 11.572 10.5249 11.5254 10.6899H11.0581C11.0581 10.6899 9.3294 4.56682 8.7219 2.35807C8.06782 -0.00785162 5.607 3.41399e-07 5.607 3.41399e-07H0V18H5.607V7.31005H5.84055H6.07409L9.34493 18H13.2386L16.5094 7.3179H16.9765V18H22.5835V3.41399e-07H16.9765Z"
        fill="#4DB45E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4569 0C37.4569 0 35.8137 0.149347 35.0429 1.88645L31.0712 10.6899H30.6041V0H24.9971V18H30.2925C30.2925 18 32.0135 17.8428 32.7846 16.1135L36.6782 7.31005H37.1455V18H42.7523V0H37.4569Z"
        fill="#4DB45E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.2451 8.1748V18.0001H50.8519V12.2621H56.9262C59.5738 12.2621 61.8166 10.5565 62.65 8.1748H45.2451Z"
        fill="#4DB45E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.9257 0H44.458C45.081 3.42708 47.6276 6.17816 50.9216 7.07424C51.6693 7.2786 52.4556 7.38864 53.2656 7.38864H62.8752C62.9609 6.97991 62.9998 6.56332 62.9998 6.131C62.9998 2.74323 60.282 0 56.9257 0Z"
        fill="url(#paint0_linear_109_5277)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_109_5277"
          x1="44.4596"
          y1="3.69432"
          x2="62.9998"
          y2="3.69432"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3" stopColor="#00B4E6" />
          <stop offset="1" stopColor="#088CCB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PaymentsMirIcon;
