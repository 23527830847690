import React from "react";

const CiFilter = ({ color = "#000000", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.447 20.905C13.6126 20.8238 13.7521 20.6979 13.8499 20.5415C13.9476 20.3851 13.9996 20.2044 14 20.02V14.7C14 14.438 14.105 14.186 14.293 14L20.414 7.94599C20.789 7.57499 21 7.07099 21 6.54599V3.98999C20.9995 3.85928 20.9732 3.72997 20.9226 3.60944C20.8721 3.48891 20.7982 3.37954 20.7053 3.28758C20.6124 3.19563 20.5023 3.12289 20.3813 3.07354C20.2603 3.02419 20.1307 2.9992 20 2.99999H4C3.447 2.99999 3 3.44199 3 3.98999V6.54599C3 7.07099 3.211 7.57499 3.586 7.94599L9.707 14C9.79974 14.0915 9.8734 14.2004 9.92369 14.3206C9.97399 14.4408 9.99993 14.5697 10 14.7V21.01C10 21.745 10.782 22.223 11.447 21.894L13.447 20.905Z"
        fill={color}
      />
    </svg>
  );
};

export default CiFilter;
