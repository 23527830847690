import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import MetaTags from "react-meta-tags";
import * as style from "./style.module.css";
import NavigationMenu from "../../components/nav-menu";
import SortSelect from "../../UI/SortSelect/SortSelect";
import { declensionNumbers } from "../../utils/declensionNumbers";
import AllBooks from "../../UI/AllBooks/AllBooks";
import useStyles from "./styles";
import CustomPagination from "../../UI/Pagination/Pagination";
import { useGetSelection, useGetTextbooks } from "../../graphql";
import ScrollSection from "../../UI/ScrollSection/ScrollSection";
import PreLoader from "../../UI/PreLoader/PreLoader";

const ResultsPage = () => {
  const classes = useStyles();
  const querySearch = useLocation();
  const [offset, setOffset] = useState(null);
  const [limit, setLimit] = useState(null);
  const [countProducs, setCountProducts] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");

  const { data, loading } = useGetTextbooks({
    variables: {
      options: {
        searchString,
        limit,
        offset,
        sortBy,
        sortType,
      },
    },
  });

  const { data: dataSelections, loading: loadingSelections } = useGetSelection({
    variables: {
      page: "SEARCH_RESULTS_PAGE",
    },
  });

  useEffect(() => {
    const searchStr = decodeURIComponent(querySearch.search.slice(1));
    setSearchString(searchStr);
  }, [querySearch && querySearch.search]);

  useEffect(() => {
    if (data && data.getTextbooks) {
      setCountProducts(data.getTextbooks.count);
      setProductsList(data.getTextbooks.textbooks);
    }
  }, [data && data.getTextbooks]);

  const breadcrumbNameMap = {
    "/results": "Результаты поиска",
  };

  if (loading || loadingSelections) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <Box className={style.resultsPage}>
      <MetaTags>
        <title>Результаты поиска</title>
        <meta property="og:title" content="Результаты поиска" />
      </MetaTags>
      <NavigationMenu breadcrumbNameMap={breadcrumbNameMap} />
      <Box className={style.resultsHead}>
        <Box className="page-title">Результаты поиска</Box>
        {!!productsList.length && (
          <SortSelect setSortBy={setSortBy} setSortType={setSortType} />
        )}
      </Box>
      <Box className={style.resultsString}>
        {`По запросу “${searchString}” найдено ${countProducs} ${declensionNumbers(
          countProducs,
          ["результат", "результата", "результатов"]
        )}`}
      </Box>
      <Box className={style.resultsWrap}>
        <AllBooks className={classes.allBooksGap} allBooks={productsList} />
      </Box>
      <Box className={style.resultsPagination}>
        <CustomPagination
          itemsPerPage={countProducs}
          setOffset={setOffset}
          setLimit={setLimit}
          offset={offset}
          customLimitsList={[24, 48]}
        />
      </Box>

      {dataSelections &&
        dataSelections.getSelection &&
        dataSelections.getSelection.map((section) => {
          return (
            !!section.textbooks.length && (
              <ScrollSection
                key={section.id}
                sectionTitle={section.title}
                productsList={section.textbooks}
                noLink
              />
            )
          );
        })}
    </Box>
  );
};

export default ResultsPage;
