import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import ScrollSection from "../../UI/ScrollSection/ScrollSection";
import PreLoader from "../../UI/PreLoader/PreLoader";
import {
  useGetTextbooks,
  useGetTextBookSimilar,
  useGetViewedUserBookLazy,
} from "../../graphql";

const BookPageSelections = ({ subjectName, subjectAlias }) => {
  const params = useParams();

  const token =
    localStorage.getItem("token_catalog") ||
    sessionStorage.getItem("token_catalog");

  const { data: dataSimilar, loading: loadingSimilar } = useGetTextBookSimilar({
    variables: {
      textbookId: params.book,
    },
  });

  const { data: dataSubjectBooks, loading: loadingSubjectBooks } =
    useGetTextbooks({
      variables: {
        options: {
          subjectAlias,
          limit: 15,
        },
      },
    });

  const [
    getViewed,
    { data: dataGetViewedUser, loading: loadingGetViewedUser },
  ] = useGetViewedUserBookLazy();

  useEffect(() => {
    if (token) {
      getViewed();
    }
  }, []);

  if (loadingSimilar || loadingSubjectBooks || loadingGetViewedUser) {
    return <PreLoader preloadWrapper={{ padding: "50px 0" }} />;
  }

  return (
    <>
      {!!dataSimilar?.getTextBookSimilar.length && (
        <ScrollSection
          sectionTitle="Похожие книги"
          sectionLinkText="Похожие"
          linkUrl={`/similar/${params.book}`}
          productsList={dataSimilar?.getTextBookSimilar}
        />
      )}

      {!!dataSubjectBooks?.getTextbooks?.textbooks.length && (
        <ScrollSection
          sectionTitle={subjectName}
          sectionLinkText="Все книги по предмету"
          linkUrl={`/all-books/${params.subject}`}
          productsList={dataSubjectBooks?.getTextbooks?.textbooks}
        />
      )}

      {!!dataGetViewedUser?.getViewedUserBook.length && (
        <ScrollSection
          sectionTitle="Просмотренные книги"
          sectionLinkText="Все просмотренные"
          linkUrl="/viewed"
          productsList={dataGetViewedUser?.getViewedUserBook}
        />
      )}
    </>
  );
};

export default BookPageSelections;
