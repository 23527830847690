import React from "react";

const BxBxsChevronDown = ({ color = "#0B486B", width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.939 7.939L12 12.879L7.06096 7.939L4.93896 10.061L12 17.121L19.061 10.061L16.939 7.939Z"
        fill={color}
      />
    </svg>
  );
};

export default BxBxsChevronDown;
