import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tabsContainer: {
    "&.MuiTabs-root": {
      minHeight: 20,
      marginTop: 48,
      "@media (max-width:639px)": {
        marginLeft: -10,
        marginRight: -10,
      },
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: 0,
      color: "#4D6E7D",
      textTransform: "capitalize",
      borderRight: "1px solid #0000001A",
      minHeight: 20,
      marginBottom: 15,
      padding: "0px 48px",
      "@media (max-width:639px)": {
        padding: "0px 16px",
        flexDirection: "column",
      },
    },
    "& .MuiButtonBase-root.MuiTab-root:last-child": {
      borderRight: "none",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: "#0B486B",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#4D6E7D",
    },
    "& .MuiButtonBase-root.MuiTab-root svg ": {
      marginRight: 8,
      "@media (max-width:639px)": {
        marginRight: 0,
        marginBottom: 6,
      },
    },
    "& .MuiButtonBase-root.MuiTab-root svg path": {
      fill: "#4D6E7D",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected svg path": {
      fill: "#0B486B",
    },
  },
  tabPanelInfo: {
    padding: "42px 0px 0px 0px ",
  },
  formContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 924,
    width: "100%",
    "@media (max-width:1000px)": {
      flexDirection: "column",
    },
  },
  w100: {
    width: "100%",
  },
  dFlex: {
    display: "flex",
    "@media (max-width:639px)": {
      flexDirection: "column",
    },
    "& .MuiButton-startIcon": {
      marginRight: 11,
      marginLeft: 0,
    },
  },
  datePickerWrapper: {
    "&.MuiFormControl-root.MuiTextField-root": {
      width: "100%",
      maxWidth: 382,
      marginBottom: 16,
      "@media (max-width:639px)": {
        maxWidth: "unset",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      maxWidth: 382,
      minWidth: 166,
      width: "100% ",
      border: "none",
      borderBottom: "1px solid #0000001A",
      borderRadius: 0,
      "@media (max-width:639px)": {
        maxWidth: "unset",
      },
    },
    "& .MuiButtonBase-root.MuiIconButton-root": {
      padding: 0,
      margin: 0,
      width: 20,
      height: 20,
    },
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
      fill: "#0B486B",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      padding: 0,
    },
    "& .MuiInputBase-input.MuiOutlinedInput-input, & .MuiInputBase-input.MuiOutlinedInput-input::placeholder":
      {
        height: "unset",
        padding: "0px 0px 18px 0px",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: 0,
        color: "#5C5C5C",
        opacity: 1,
      },
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#0000001A",
      },
    "& .MuiInputAdornment-root": {
      paddingBottom: "18px",
    },
  },
  labelBirthday: {
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: 0,
    color: "#00000066",
    marginBottom: 10,
  },
  autocompleteWrapper: {
    "&.MuiFormControl-root.MuiTextField-root": {
      maxWidth: 382,
      width: "100%",
      "@media (max-width:639px)": {
        maxWidth: "unset",
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
    "& .MuiInputBase-root.MuiInput-root:before": {
      borderColor: "#0000001A",
    },
    "& .MuiInput-root .MuiInput-input": {
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: 0,
      color: "#303030",
      height: "unset",
      padding: 0,
    },
    "& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #0000001A",
    },
    "& .MuiInput-root": {
      paddingRight: "0px !important",
      paddingTop: 8,
      paddingBottom: "16px !important",
    },
    "& img": {
      width: 48,
      height: 48,
    },
  },
  autocompeteOptions: {
    "&.MuiAutocomplete-option": {
      justifyContent: "space-between !important",
    },
  },
  addBtn: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: 0,
    color: "#303030",

    "& svg": {
      width: 20,
      height: 20,
      marginRight: 9,
    },
  },
  autocomplete: {
    "&.MuiAutocomplete-root.Mui-focused ": {
      "& .hide-add-btn": {
        display: "none",
      },
    },
  },
  saveBtn: {
    "@media (max-width:639px)": {
      display: "flex",
      justifyContent: "center",
    },

    "& .MuiButton-startIcon": {
      marginRight: 11,
      marginLeft: 0,
    },
    "& .MuiButtonBase-root.MuiButton-root": {
      marginTop: 24,
      marginBottom: 56,
      border: "1px solid #0B486B",
      "@media (max-width:639px)": {
        marginTop: 20,
        maxWidth: 174,
        width: "100%",
      },
    },
  },
  passwordBtn: {
    "@media (max-width:639px)": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiButtonBase-root.MuiButton-root": {
      "@media (max-width:639px)": {
        maxWidth: 174,
        width: "100%",
      },
    },
  },
  dNone: {
    display: "none",
  },

  tabPanelBook: {
    padding: "40px 0px 96px 0px ",
  },
  btnWrapper: {
    "& .MuiButtonBase-root.MuiButton-root": {
      marginBottom: 16,
    },
  },
  allBooksGap: {
    gap: "64px 91px",
    marginTop: 64,
    "@media (max-width:639px)": {
      gap: "24px 0px",
      marginTop: 0,
    },
  },
  paginationWrapper: {
    marginTop: 64,
  },
}));

export default useStyles;
