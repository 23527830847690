import React, { useState, useMemo } from "react";
import Copyright from "../components/copyright";
import Footer from "../components/footer";
import Header from "../components/header";
import { UserLoginContext } from "../contexts";
import * as style from "./style.module.css";

const Layout = ({ children }) => {
  const [isUserLoggedIn, setUserLoggedIn] = useState(
    !!localStorage.getItem("token_catalog") ||
      !!sessionStorage.getItem("token_catalog") ||
      false
  );

  const providerValue = useMemo(() => {
    return {
      isLoggedIn: isUserLoggedIn,
      setLogIn: setUserLoggedIn,
    };
  }, [isUserLoggedIn, setUserLoggedIn]);

  return (
    <UserLoginContext.Provider value={providerValue}>
      <div className={style.mainWrap}>
        <Header />
        <main className={style.main}>{children}</main>
        <Footer />
        <Copyright />
      </div>
    </UserLoginContext.Provider>
  );
};

export default Layout;
