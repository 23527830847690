import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import useBreakpoint from "use-breakpoint";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "../../assets/icons/CloseIcon";
import { BREAKPOINTS } from "../../constants";
import * as style from "./style.module.css";
import useStyles from "./styles";
import Search from "../search";
import Notification from "../notification";
import User from "../user";
import Logo from "../../assets/icons/Logo";
import BurgerMenu from "../../assets/icons/BurgerMenu";
import IcomoonFreeBooks from "../../assets/icons/IcomoonFreeBooks";
import MainMenu from "../main-menu";
import LogoSidebar from "../../assets/icons/LogoSidebar";
import Contacts from "../contacts";
import LoginForm from "../forms/LoginForm";
import RegistrationForm from "../forms/RegistrationForm";

const Header = () => {
  const [state, setState] = React.useState({
    left: false,
  });
  const navigate = useNavigate();

  const { state: routerState, pathname } = useLocation();

  const classes = useStyles();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const showSearchBelowHeader = ["smallMobile", "mobile"].includes(breakpoint);
  const showSearchInHeader = ["bigMobile", "tablet", "desktop"].includes(
    breakpoint
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  /* LOGIC for modals */
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);

  useEffect(() => {
    if (routerState?.openLogin) {
      navigate(pathname, { state: {} });
      setOpenLogin(true);
    }
  }, [pathname, routerState?.openLogin]);

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleOpenRegistration = () => {
    setOpenRegistration(true);
  };
  const handleCloseRegistration = () => {
    setOpenRegistration(false);
  };
  /* End of LOGIC for modals */

  const handleAccountClick = () => {
    const token =
      sessionStorage.getItem("token_catalog") ||
      localStorage.getItem("token_catalog");
    if (!token) {
      navigate("/");
      handleClickOpenLogin();
    } else {
      navigate("/account?myBooks");
    }
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <div className={classes.headerWrapper}>
        <Toolbar className={classes.toolbarRoot}>
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button
                className={style.menuToggler}
                onClick={toggleDrawer(anchor, true)}
              >
                {anchor}
                <BurgerMenu />
              </Button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                className={classes.drawerPaper}
              >
                <div>
                  <div
                    className={style.list}
                    role="presentation"
                    onClick={toggleDrawer(anchor, false)}
                    onKeyDown={toggleDrawer(anchor, false)}
                  >
                    <Button className={style.buttonClose}>
                      <CloseIcon />
                    </Button>
                    <Link to="/" className={style.logoSidebar}>
                      <LogoSidebar />
                    </Link>
                  </div>
                </div>
                <MainMenu
                  listContainer={classes.listContainer}
                  listItemButton={classes.listItemButton}
                  listItemLink={style.listItemLink}
                  colorIcon="#4D6E7D"
                />
                <Contacts
                  contactsPosition={style.contactsPosition}
                  socialContainer={style.socialContainer}
                  socialContent={style.socialContent}
                  infoContainer={style.infoContainer}
                  colorIcon="#4D6E7D"
                />
              </Drawer>
            </React.Fragment>
          ))}
          <div className={style.logoImg}>
            <Link to="/">
              <Logo />
            </Link>
          </div>

          <div className={style.menuContainer}>
            <Link to="/novelty">Новинки</Link>
            <Link to="/all-books">Все книги</Link>
            <Link to="/news">Новости</Link>
          </div>
          {showSearchInHeader && <Search />}
          <div className={style.menuRightIcon}>
            <Notification />
            <div
              role="button"
              tabIndex="0"
              onClick={handleAccountClick}
              className={style.shelfHight}
            >
              <IcomoonFreeBooks width="22" height="18" />
            </div>
            <User
              setOpenLogin={setOpenLogin}
              setOpenRegistration={setOpenRegistration}
            />
          </div>
        </Toolbar>
      </div>
      {showSearchBelowHeader && (
        <div className={style.searchContainer}>
          <Search />
        </div>
      )}
      <div style={{ display: "none" }}>
        <LoginForm
          handleClose={handleCloseLogin}
          open={openLogin}
          handleOpenRegistration={handleOpenRegistration}
          handleClickOpenLogin={handleClickOpenLogin}
        />
        <RegistrationForm
          handleClose={handleCloseRegistration}
          open={openRegistration}
          handleClickOpenLogin={handleClickOpenLogin}
        />
      </div>
    </AppBar>
  );
};

export default Header;
