import React from "react";

const BookmarkIcon = ({ color = "#0B486B", width = 12, height = 13 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 2H6C4.794 2 3 2.799 3 5V19C3 21.201 4.794 22 6 22H21V20H6.012C5.55 19.988 5 19.806 5 19C5 18.899 5.009 18.809 5.024 18.727C5.136 18.151 5.608 18.01 6.012 18H21V4C21 3.46957 20.7893 2.96086 20.4142 2.58579C20.0391 2.21071 19.5304 2 19 2ZM19 11L17 10L15 11V4H19V11Z"
        fill={color}
      />
    </svg>
  );
};

export default BookmarkIcon;
