import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  headerWrapper: {
    margin: "0px auto",
    display: "block",
    width: "100%",
    maxWidth: 1600,
  },
  toolbarRoot: {
    "&.MuiToolbar-root": {
      minHeight: 56,
      display: "flex",
      justifyContent: "space-between",
      padding: 0,
      "@media (min-width:1920px)": {
        padding: 0,
      },
      "@media (max-width:1655px)": {
        padding: "0px 24px",
      },
      "@media (max-width:999.9px)": {
        padding: "0px 16px",
      },
    },
  },
  appBar: {
    "&.MuiAppBar-root": {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: " #0B486B",
      boxShadow: "none",
      zIndex: 100,
      height: 56,
      "@media (max-width:639px)": {
        height: "unset",
      },
    },
  },
  drawerPaper: {
    "& .MuiDrawer-paper": {
      width: 280,
      padding: "32px 24px 28px 24px",
    },
  },
  listContainer: {
    "&.MuiList-root": {
      padding: "20px 0px 60px 0px",
    },
    "& .MuiListItem-root": {
      padding: 0,
      borderBottom: "1px solid #0000001a",
    },
    "& .MuiListItem-root:last-child": {
      borderBottom: "none",
    },
  },
  listItemButton: {
    "&.MuiButtonBase-root.MuiListItemButton-root": {
      padding: "0px 24px",
      margin: "0px -24px",
    },
  },
}));

export default useStyles;
